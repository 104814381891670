import { API, endpoints } from "../api";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";

const ReportCheating = () => {
  const { showToast, showGlobalLoader: setLoading } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {};
    formData.involved_name = e?.target?.elements?.involved_name?.value;
    formData.substances = e?.target?.elements?.substances?.value;
    formData.description = e?.target?.elements?.description?.value;
    formData.when = e?.target?.elements?.when?.value;
    formData.how = e?.target?.elements?.how?.value;

    formData.other = e?.target?.elements?.other?.value;
    formData.name = e?.target?.elements?.name?.value;
    formData.email_address = e?.target?.elements?.email_address?.value;
    formData.phone = e?.target?.elements?.phone?.value;

    setLoading(true);
    var data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) data.append(key, formData[key]);
    });

    API.post({ route: endpoints.REPORT_DOPING, data })
      .then(() => {
        showToast({ text: strings.reportDoping });
        document.getElementById("deportCheating").reset();
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-4">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
            Report Doping Hotline
          </h2>
        </div>
      </div>
      <form name="deportCheating" id="deportCheating" onSubmit={handleSubmit}>
        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
          <div className="card-body p-lg-5 p-4 w-100 border-0 ">
            <div className="row">
              <p>
                All information is important and the more detail you provide the
                better.
              </p>
              <div className="col-lg-8">
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    Who was involved in the doping activity?
                  </label>
                  <input
                    required
                    name="involved_name"
                    type="text"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    What substance/s are involved?
                  </label>
                  <textarea
                    name="substances"
                    className="form-control mb-0 p-3 h100 lh-16"
                    rows={5}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    Please describe what you know is happening
                  </label>
                  <textarea
                    required
                    name="description"
                    className="form-control mb-0 p-3 h100 lh-16"
                    rows={5}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    When is it happening (date/s, pre-season, in-season, etc.)?
                  </label>
                  <textarea
                    name="when"
                    className="form-control mb-0 p-3 h100 lh-16"
                    rows={5}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    How did you find out (for example, you saw it yourself)?
                  </label>
                  <textarea
                    name="how"
                    className="form-control mb-0 p-3 h100 lh-16"
                    rows={5}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    Is there anything else you wish to add that might assist
                    iCompete Natural in assessing your information?
                  </label>
                  <textarea
                    name="other"
                    className="form-control mb-0 p-3 h100 lh-16"
                    rows={5}
                  />
                </div>
                <p>
                  ICN may need to follow up on information you have provided. If
                  you are okay with being contacted, please provide contact
                  details here. If you do not provide these details, you will
                  remain completely anonymous.
                </p>
                <p>
                  <strong>Personal details (optional):</strong>
                </p>
                <p>
                  ICN treat the information you provide, and your personal
                  details, with the strictest confidence.
                </p>
                <p>
                  If you want ICN to be able to contact you, please leave
                  contact details.
                </p>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    Your Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                    placeholder="Optional"
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    Your Email Address
                  </label>
                  <input
                    name="email_address"
                    type="text"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                    placeholder="Optional"
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="mont-font fw-600 font-xsss">
                    Your Phone Number
                  </label>
                  <input
                    name="phone"
                    type="text"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                    placeholder="Optional"
                  />
                </div>
              </div>
            </div>

            <div className="form-group mt-5 mb-1 w200">
              <button
                type="submit"
                className="form-control float-right text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0 "
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ReportCheating;
