import { useEffect, useMemo, useState } from "react";
import { useEnterEvent } from "../../../context/EnterEvent";

export const DivisionsTable = ({ showRules }) => {
  const { selectedEvent, currency, enrollData } = useEnterEvent();

  const hasPhotos = useMemo(
    () =>
      selectedEvent?.photoFee &&
      selectedEvent?.photoAvailable === "yes" &&
      selectedEvent?.photoInclude === "no"
  );


  let eventDivisionsWithFee = selectedEvent?.divisions?.filter(({ selected }) => selected)?.map((d) => ({
    ...enrollData?.division?.find(({ eventDivisionId: id }) => id === d.id),
    ...d,
  }))?.sort((a, b) => b.entryFee - a.entryFee)

  return (
    <div className="card shadow-xss w-100 border-0 p-0 mb-3 mt-2">
      <div className="card-body">
        <div>
          <div className="row">
            <div className="col">
              <strong>Division</strong>
            </div>
            {showRules ? (
              <div className="col hidden-sm">
                <strong>Rules</strong>
              </div>
            ) : null}
            {hasPhotos ? (
              <div className="col">
                <strong>Purchase Photos</strong>
              </div>
            ) : null}
            <div className="col hidden-sm">
              <strong>Entry Fee</strong>
            </div>
            <div className="col text-right hidden-sm">
              <strong>Amount</strong>
            </div>
            {showRules ? null : <div className="col hidden-sm" />}
          </div>
          {eventDivisionsWithFee?.map((division, i) => (
            <Row
              key={`division-table-${i}`}
              division={division}
              hasPhotos={hasPhotos}
              showRules={showRules}
              enrolledDivision={enrollData?.division?.find(
                ({ eventDivisionId: id }) => id === division?.id
              )}
            />
          ))}
        </div>
        {showRules ? null : (
          <div className="row event-select-th">
            <div className="col" />
            {hasPhotos ? <div className="col" /> : null}
            <div className="col" />
            <div className="col sm-no-col text-right">
              <strong>
                Sub Total:
                <span className="notranslate">
                  {currency}
                  {parseFloat(parseFloat(enrollData?.divisionsTotalFee || 0).toFixed(2)).toLocaleString("en-US")}
                </span>
              </strong>
            </div>
            <div className="col" />
          </div>
        )}
      </div>
    </div>
  );
};

const Row = ({ division, enrolledDivision, hasPhotos, showRules }) => {
  const {
    selectedEvent,
    withdrawDivision,
    enrollData,
    setEnrollData,
    currency,
  } = useEnterEvent();

  const handlePhotoChange = (e) => {
    const isChecked = e.target.checked;
    const i = enrollData?.division?.findIndex(
      ({ eventDivisionId: id }) => id === division?.id
    );
    if (i >= 0) {
      if (isChecked) {
        enrollData.division[i].photoFee = selectedEvent?.photoFee;
      } else {
        delete enrollData.division[i].photoFee;
      }

      setEnrollData({
        ...enrollData,
      });
    }
  };

  const { divisionDesc, divisionName, id, name } = division;

  return (
    <div key={`division-row-${id}`} className="row mb-3 mt-2 mobile--selected-division">
      <div className="col">{name || divisionDesc}</div>
      {showRules ? (
        <div className="col hidden-sm">{divisionName || divisionDesc}</div>
      ) : null}{" "}
      {/*Will add rules here*/}
      {hasPhotos ? (
        <div className="col">
          {showRules ? (
            <div className="col">
              {currency}
              {(enrolledDivision?.photoFee ? selectedEvent?.photoFee : 0)?.toLocaleString("en-US")}
            </div>
          ) : (
            <label class="toggle toggle-menu-color  d-flex left-line-toggle">
              <span className="mr-1">
                <input
                  // defaultChecked={!!enrolledDivision?.photoFee}
                  checked={!!enrolledDivision?.photoFee}
                  onChange={handlePhotoChange}
                  type="checkbox"
                />
                <span class="toggle-icon"></span>
              </span>
              <span class=" font-xssss mont-font fw-700">
                {currency}
                {selectedEvent?.photoFee?.toLocaleString("en-US")}
              </span>
            </label>
          )}
        </div>
      ) : null}
      <ShowPrice isChecked={!!enrolledDivision?.photoFee} divisionId={id} />
      {showRules ? null : (
        <div className="col">
          <button
            onClick={() => withdrawDivision(id)}
            className="form-control text-center small-btn text-white bg-red-gradiant border-0 p-0 font-xsssss"
          >
            <i className="fa-solid fa-circle-xmark"></i> Withdraw
          </button>
        </div>
      )}
    </div>
  );
};

const ShowPrice = ({ divisionId }) => {
  const { enrollData, currency } = useEnterEvent();

  const [selectedDivision, setSelectedDivision] = useState();

  useEffect(() => {
    setSelectedDivision(
      enrollData?.division?.find(({ eventDivisionId: id }) => id === divisionId)
    );
  }, [enrollData, divisionId]);

  const { entryFee = 0, photoFee = 0, lateFee = 0 } = selectedDivision || {};

  return (
    <>
      <div className="col sm-no-col sm-pt-1">
        <b className="show-sm">Entry Fee : </b>{currency}{(lateFee || entryFee)?.toLocaleString("en-US")}
      </div>
      <div className="col text-right sm-pb-1 sm-no-col sm-text-left">
        <b className="show-sm">Amount : </b> {currency}
        {(photoFee + (lateFee || entryFee))?.toLocaleString("en-US")}
      </div>
    </>
  );
};
