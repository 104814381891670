import { useEffect, useState } from "react";
import Moment from "react-moment";
import { API, endpoints } from "../api";
import { Link } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { ImageModal } from "./image-modal";

const RightEvents = () => {
  const [events, setEvents] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage, setEventsPerPage] = useState('all');
  const [allEventsCount, setAllEventsCount] = useState(0);

  const { setNavOpen } = useAuth()

  useEffect(() => {
    API.get({ route: `${endpoints.EVENT}?all=1` }).then((data) => {
      const allEvents = data?.result;
      if (eventsPerPage === 'all') {
        setEvents(allEvents);
        setAllEventsCount(allEvents ? allEvents.length : 0);
      } else {
        const indexOfLastEvent = currentPage * eventsPerPage;
        const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
        const currentEvents = allEvents?.slice(indexOfFirstEvent, indexOfLastEvent);
        setEvents(currentEvents);
      }
    });
  }, [currentPage, eventsPerPage]);

  if (!events?.length) {
    return;
  }
  return (
    <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
      <div class="card-body d-flex align-items-center p-2" style={{ marginTop: 5 }}>
        <h4 class="fw-700 mb-0 font-xsss text-current"><strong>LIVE EVENTS</strong></h4>
        <select
          className={`form-select form-select-sm ms-auto hand-cursor`}
          style={{ width: '70px' }}
          onChange={(e) => setEventsPerPage(parseInt(e.target.value))}
          defaultValue={allEventsCount}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value={allEventsCount}>All</option>
        </select>
      </div>

      <div class="card-body align-items-center p-2 font-xsss bg-primary">
        <p style={{ color: 'white' }}>Go to <Link to="/enter-new-event" style={{ color: 'white' }}><b>Enter New Event</b></Link> to compete
          or click on Contest to view poster.</p>
      </div>


      <div class="card-body d-flex align-items-center p-2">
        <h4 class="fw-700 mb-0 font-xssss text-grey-900 f-14">Events</h4>
        <Link onClick={() => setNavOpen(false)} to="/events" class="fw-600 ms-auto font-xssss text-primary f-14">
          More Information
        </Link>
      </div>


      {events.map(({ posterImage, ...rest }) => posterImage ? (
        <ImageModal
          img={posterImage}
          btn={
            <Item {...rest} />
          }
        />
      ) : <Item {...rest} />
      )}
    </div>
  );

};


const Item = ({ id, eventTitle, location, startTime }) => <div
  key={`right-events-${id}`}
  class="card-body d-flex pt-0 ps-2 pe-2 pb-1 overflow-hidden align-items-center"
>
  <div class=" me-2 p-2 rounded-xxxl event-date-round-box" style={{ backgroundColor: '#00af4f' }}>
    <h4 class="fw-700 font-xsssss ls-3 lh-1 text-white mb-0">
      <Moment
        className="no-break-line"
        style={{ width: 35 }}
        format="DD MMM"
      >
        {startTime}
      </Moment>
      <span class="ls-1 d-block font-xsssss text-white fw-600 mt-1">
        <Moment format="YYYY">{startTime}</Moment>
      </span>
    </h4>
  </div>
  <h4 class="fw-700 text-grey-900 font-xssss mt-2">
    <span className="no-break-line" style={{ width: 185, maxWidth: '100%', display: 'inline-block' }}>
      {eventTitle}
    </span>
    <span class="d-block font-xssss fw-500 mt-1 lh-4 location-color">
      {location}
    </span>
  </h4>
</div>

export default RightEvents;
