import React, { useEffect, useState } from "react";
import { Comments } from "./comments";
import { Content } from "./content";
import { DeletePost } from "./delete-post";
import { HidePost } from "./hide-post";
import { Likes } from "./likes";
import { Share } from "./share";
import { FeedSlider } from "./slider";
import { User } from "./user";
import strings, { toastType } from "../../utils/strings";
import { useFeed } from "../../context/Feed";
import { useAuth } from "../../context/Auth";

export const Post = ({
  content,
  feed_id,
  images,
  time,
  user = {},
  likes_count,
  likes = {},
  comments = [],
  comments_count = 0,
  can_delete,
  can_update,
}) => {
  const [isEditMode, setEditMode] = useState(false)
  const [originalText, setOriginalText] = useState(content)
  const { updatePost } = useFeed()
  const { showToast, user: currentUser } = useAuth()

  useEffect(() => {
    setOriginalText(content)
  }, [content])

  const isAdmin = currentUser?.user?.role?.some(role => role?.role_id == 1);

  return (
    <>
      <div
        key={`feed-item-post-${feed_id}`}
        class="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
      >
        <div class="card-body p-0 d-flex">
          <User time={time} {...user} />
          <a
            class="ms-auto cursor-pointer"
            id="dropdownMenu6"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg"
            aria-labelledby="dropdownMenu6"
          >
            {isAdmin || can_update ? (
              <div class="card-body p-0 d-flex cursor-pointer"
                onClick={() => {
                  setEditMode(true)
                  // setOriginalText('')
                  // setTimeout(() => setOriginalText(content));
                }}
              >
                <i class="feather-edit text-grey-500 me-3 font-lg"></i>
                <h4 class="fw-600 text-grey-900 font-xssss mt-0 me-4">
                  Edit Post
                  <span class="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Edit description of the post
                  </span>
                </h4>
              </div>
            ) : null}
            {isAdmin || can_delete ? (
              <DeletePost feed_id={feed_id} />
            ) : (
              <HidePost feed_id={feed_id} />
            )}
          </div>
        </div>
        <FeedSlider feed_id={feed_id} images={images} />
        {isEditMode ? <div className="ms-auto">
          <a onClick={() => {
            setEditMode(false)
            setOriginalText('')
            setTimeout(() => setOriginalText(content));
          }} className="cursor-pointer mr-1">
            <i
              style={{ paddingRight: 3 }}
              className="feather-delete text-white btn-round-sm bg-mini-gradiant font-xss"
            />
          </a>
          <a
            onClick={() => {
              const txt = document.getElementById(`post_text_${feed_id}`)
              const val = txt?.innerText
              if (val?.trim())
                updatePost({ id: feed_id, content: txt.innerText }).then((data) => {
                  setEditMode(false)
                })
              else
                showToast({ text: strings.updatedPosError, type: toastType.ERROR });
            }}
            className="cursor-pointer">
            <i
              style={{ paddingLeft: 2 }}
              className="feather-check text-white btn-round-sm bg-green-gradiant font-xss"
            />
          </a>
        </div> : null}
        <Content type="post" content={originalText} isEditMode={isEditMode} id={feed_id} />
        <div class="card-body d-flex p-0 v-center like-wrapper">
          <Likes id={feed_id} likes_count={likes_count} likes={likes} />
          {comments_count > 0 ? (
            <a class="d-flex align-items-center fw-600 text-grey-900 text-dark lh-20 font-xssss">
              <i class="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
              {comments_count} Comment{comments_count > 1 ? "s" : ""}
            </a>
          ) : null}
          <Share
            id={feed_id}
            content={content}
            image={images?.[0]?.image}
          />
        </div>
        <Comments comments={comments} id={feed_id} count={comments_count} />
      </div>
    </>
  );
};
