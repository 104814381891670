import { useEffect } from "react";
import { useEnterEvent } from "../../context/EnterEvent";

const AdditionalOptions = ({ enrolId }) => {
  const { currency, enrollData, selectedEvent, setEnrollData } =
    useEnterEvent();

  const videoSelect = (e) => {
    setEnrollData({ ...enrollData, doWantDvd: e.target.value });
  };

  useEffect(() => {
    if (selectedEvent?.id && !enrollData?.additionalServiceId) {
      const additionalServiceId = selectedEvent?.eventsAdditionalServices.map(
        ({ id, serviceFeeNo, serviceFeeYes, purchaseCompulsory }) => {
          if (purchaseCompulsory === "yes") {
            return {
              id,
              serviceFeeYes,
            };
          } else {
            return {
              id,
              serviceFeeNo,
            };
          }
        }
      );

      setEnrollData({ ...enrollData, additionalServiceId });
    }
  }, [enrollData, selectedEvent?.id]);

  const handleSelectChange = (e, { id, ...rest }) => {
    const val = e.target.value;

    const index = enrollData?.additionalServiceId?.findIndex(
      ({ id: serviceId }) => serviceId === id
    );

    if (index >= 0) {
      enrollData.additionalServiceId[index] = {
        id,
        [val]: rest[val],
      };

      setEnrollData({ ...enrollData });
    }
  };

  return (
    <div class="card shadow-xss w-100 border-0 p-4 mb-3">
      <div class="card-body align-items-center p-0">
        {enrolId ? null : <p className="text-current m-0">
          <strong>Step 4 of 6</strong>
        </p>}
        <p className="m-0">
          <strong>Additional Services</strong> (if any available)
        </p>

        <div className="row mt-3">
          <div className="col-lg-6">
            {selectedEvent && selectedEvent?.videoAvailable === "yes" ? (
              <div className="row m-3">
                <div className="col-lg-6">Event Video</div>
                <div className="col-lg-6">
                  {selectedEvent?.videoAvailable === "yes" ? (
                    selectedEvent?.videoInclude === "yes" ? (
                      <b>Included</b>
                    ) : (
                      <select
                        defaultValue={enrollData?.doWantDvd || "0"}
                        onChange={videoSelect}
                        className="no-custom"
                      >
                        <option value="0">No</option>
                        {selectedEvent?.dvdFee ? <option value="1">
                          Reel {currency + selectedEvent?.dvdFee}
                        </option> : null}

                        {selectedEvent?.downloadFee ? <option value="2">
                          Download {currency + selectedEvent?.downloadFee}
                        </option> : null}
                      </select>
                    )
                  ) : (
                    <div>Not Available</div>
                  )}
                </div>
              </div>
            ) : null}

            {selectedEvent?.eventsAdditionalServices?.map((service, index) => {
              const {
                description,
                serviceFeeNo,
                serviceFeeYes,
                purchaseCompulsory,
                id,
              } = service;

              const data = enrollData?.additionalServiceId?.find(
                ({ id: id1 }) => id1 === id
              );

              return (
                <div key={`service-${index}`} className="row m-3">
                  <div className="col-lg-6">{description}</div>
                  <div className="col-lg-6">
                    <select
                      className="no-custom"
                      value={
                        data?.serviceFeeYes ? "serviceFeeYes" : "serviceFeeNo"
                      }
                      onChange={(e) => handleSelectChange(e, service)}
                    >
                      {purchaseCompulsory === "yes" ? null : (
                        <option value="serviceFeeNo">
                          No
                          {serviceFeeNo ? `, ${currency}${serviceFeeNo}` : ''}
                        </option>
                      )}
                      <option value="serviceFeeYes">
                        Yes, {currency}
                        {serviceFeeYes}
                      </option>
                    </select>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-lg-6 text-right">
            <strong className="font-sm text-current p-2">
              Total:
              <span className="notranslate">
                {selectedEvent?.eventCurrency?.currency} {currency}
                {parseFloat(parseFloat(enrollData?.amount || 0).toFixed(2)).toLocaleString("en-US")}
              </span>
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalOptions;
