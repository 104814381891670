import React, { useEffect, useState } from "react";
import { API, endpoints } from "../../../api";

import { useParams } from "react-router-dom";
import { useAuth as useAuthMain } from "../../../context/Auth";
import { useAuth } from "../auth";
import Payment from "./payment";
import UpdateEvent from "./update-event";
import { debounce } from "lodash";
import Toggle from "./toggle";
import FilterBtn from "./filter-btn";


export default () => {

    const { user, loading } = useAuth()
    const [data, setData] = useState([])
    const [searchedData, setSearchData] = useState([])
    const params = useParams()
    const { showGlobalLoader: setLoading } = useAuthMain()


    const [search, setSearch] = useState("");
    const [payFilter, setPayFilter] = useState('')
    const [foodFilter, setFoodFilter] = useState('')
    const [goodiesFilter, setGoodiesFilter] = useState('')

    const updateData = (obj, index) => {
        data[index] = {
            ...data[index],
            ...obj
        }
        setData([...data])
        getData(false)
    }


    useEffect(() => {
        if (user) {
            getData()
        }
    }, [user])

    const getData = (showLoader = true) => {
        if (showLoader)
            setLoading(true);
        API.post({ route: endpoints.OFFLINE_COMPETITORS, params: { eventId: params?.eventId } })
            .then((data) => {
                setData([...data?.result])
            })
            .finally(() => setLoading(false));

    };

    const delayedHandleChange = debounce((str) => setSearch(str), 500);

    const handleChange = (e) => {
        setSearch(e.target.value);
        delayedHandleChange(e.target.value);
    };

    useEffect(() => {
        const s = search?.toLowerCase()
        setSearchData((data)?.filter(({ id, users, actions, eventDivision, foodCouponDelivered, goodiesDelivered }) => {

            return (
                (
                    !payFilter ||
                    (actions?.workflowStatus?.toLowerCase()?.includes(payFilter))
                )
                &&
                (
                    !foodFilter ||
                    (foodCouponDelivered?.toLowerCase() === foodFilter?.toLowerCase())
                )
                &&
                (
                    !goodiesFilter ||
                    (goodiesDelivered?.toLowerCase() === goodiesFilter?.toLowerCase())
                )
                &&
                (search ? (
                    users?.firstName?.toLowerCase()?.includes(s) ||
                    users?.lastName?.toLowerCase()?.includes(s) ||
                    users?.email?.toLowerCase()?.includes(s) ||
                    users?.mobileNumber?.toLowerCase()?.includes(s) ||
                    eventDivision?.map((d) => d?.division?.name)?.join(', ')?.toLowerCase()?.includes(s)
                ) : true
                )
            )
        }))
    }, [payFilter, foodFilter, goodiesFilter, search, data])


    return <div>
        <div className="mb-2">
            <FilterBtn value={payFilter} valueToSet="paid" setValue={setPayFilter} text={"Paid"} />
            <FilterBtn value={payFilter} valueToSet='awaiting payment' setValue={setPayFilter} text={"Awaiting Payment"} />

            <FilterBtn value={foodFilter} valueToSet='yes' setValue={setFoodFilter} text={"Food - Yes"} />
            <FilterBtn value={foodFilter} valueToSet='no' setValue={setFoodFilter} text={"Food - No"} />

            <FilterBtn value={goodiesFilter} valueToSet='yes' setValue={setGoodiesFilter} text={"Goodies - Yes"} />
            <FilterBtn value={goodiesFilter} valueToSet='no' setValue={setGoodiesFilter} text={"Goodies - No"} />

            <button
                onClick={() => {
                    setPayFilter('')
                    setSearch('')
                    setFoodFilter('')
                    setGoodiesFilter('')
                }}
                className={`bg-danger text-center text-white font-xsss fw-600 rounded-3 border-0 btn-sm ml-1`}
            >
                clear
            </button>
        </div>
        <form action="#">
            <div className="form-group mb-0 icon-input">
                <i className="feather-search font-sm text-grey-400"
                    style={{ top: 13 }}
                ></i>
                <input
                    type="text"
                    onChange={handleChange}
                    placeholder="Search User(s)"
                    className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xss fw-500  w-100 theme-dark-bg"
                />
            </div>
        </form>
        <div className="overflow-auto">
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Status</th>
                        <th>Cost</th>
                        <th>Owing</th>
                        <th>Entries</th>
                        <th>Divisions</th>
                        <th>Food Coupon <br /> Delivered</th>
                        <th>Goodies <br />  Delivered</th>
                        <th>Last Updated By</th>
                    </tr>
                </thead>
                <tbody>
                    {searchedData?.map((event, index) => {

                        const { id, users, actions, eventDivision, foodCouponDelivered, goodiesDelivered } = event

                        const isPaid = actions?.workflowStatus == 'Paid'

                        return <tr key={`tr1-${index}`}>
                            <td>
                                {`${users?.firstName} ${users?.lastName}`}
                                <br />
                                {users?.email}
                                <br />
                                {users?.mobileNumber}
                            </td>
                            <td>
                                {actions?.workflowStatus}
                                {!isPaid ? <>
                                    <br />
                                    <Payment event={event} updateData={getData} />
                                </> : null}
                            </td>
                            <td>₹{parseFloat(actions?.amount)?.toLocaleString("en-US")}</td>
                            <td>₹{!isPaid ? parseFloat(actions?.amount)?.toLocaleString("en-US") : 0}</td>
                            <td>{eventDivision?.length}</td>
                            <td>
                                {eventDivision?.map((d) => d?.division?.name)?.join(', ')}
                                {!isPaid ? <>
                                    <br />
                                    <UpdateEvent event={event} updateData={getData} />
                                </> : null}
                            </td>
                            <td>
                                <Toggle
                                    updateData={() => updateData({
                                        enrollId: event?.id,
                                        userId: event?.users?.id,
                                        foodCouponDelivered: foodCouponDelivered?.toLowerCase() === 'yes' ? 'no' : 'yes'
                                    }, index)}
                                    data={{
                                        enrollId: event?.id,
                                        userId: event?.users?.id,
                                        foodCouponDelivered: foodCouponDelivered?.toLowerCase() === 'yes' ? 'no' : 'yes'
                                    }}
                                    text="coupon"
                                    name={`${users?.firstName} ${users?.lastName}`}
                                    value={foodCouponDelivered?.toLowerCase() === 'yes'}
                                />
                            </td>
                            <td>
                                <Toggle
                                    // updateData={getData}
                                    updateData={() => updateData({
                                        enrollId: event?.id,
                                        userId: event?.users?.id,
                                        goodiesDelivered: goodiesDelivered?.toLowerCase() === 'yes' ? 'no' : 'yes'
                                    }, index)}
                                    data={{
                                        enrollId: event?.id,
                                        userId: event?.users?.id,
                                        goodiesDelivered: goodiesDelivered?.toLowerCase() === 'yes' ? 'no' : 'yes'
                                    }}
                                    text="goodies"
                                    name={`${users?.firstName} ${users?.lastName}`}
                                    value={goodiesDelivered?.toLowerCase() === 'yes'}
                                />
                            </td>
                            <td>
                                {
                                    actions?.lastModifiedBy
                                }
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>
}

