import React, { useState } from "react";
import { SubNav } from "./sub-nav";
import { Link } from "react-router-dom";

export const Nav = ({ title, info, data }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="nav-wrap rounded-md text-white mb-2 mt-2">
      <div
        className="nav-caption fw-600 font-xssss pt-3 pb-3 text-white cursor-pointer bg-blue-img"
        onClick={() => setOpen(!open)}
      >
        {title}

        {!open ? (
          <i className="me-3  text-white fa-solid fa-caret-down font-xs float-right" />
        ) : (
          <i className="me-3  text-white fa-solid fa-caret-up font-xs float-right" />
        )}
        {info ? (
          <Link
            to={info}
            class="fw-600 ms-auto font-xssss text-white float-right mr-11"
          >
            <i
              className={`btn-round-xs mt--2 bg-gray-gradiant me-3 fa-solid fa-info text-white`}
            />
          </Link>
        ) : null}
      </div>
      {open ? (
        <ul className="mb-1 top-content">
          {data?.map((item, index) => (item ? <SubNav key={`sidebar-inner-${index}`} {...item} /> : null))}
        </ul>
      ) : null}
    </div>
  );
};
