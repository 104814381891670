import { ForgotPassword as ForgotPasswordComp } from "../components/authorization/forgotPassword";
import Content from "../components/content";

const ForgotPassword = () => {
  return <div className="small-content">
    <div>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
            <i class="text-white font-md me-2 btn-round-sm bg-blue-gradiant fa-solid fa-key" />
            Forgot Password
          </h2>
        </div>
      </div>
      <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
        <ForgotPasswordComp isPage />
      </div>
    </div>
  </div>
};

export default ForgotPassword;
