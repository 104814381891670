import { Feed, SidebarRight } from "../components";

const Home = () => {
  return (
    <div className="row">
      <div className="col-xl-9 col-xxl-10 col-lg-8">
        <Feed showFilter />
      </div>
      <div className="col-xl-3 col-xxl-2 col-lg-4 pe-0 ps-0">
        <SidebarRight />
      </div>
    </div>
  );
};

export default Home;
