import React, { useState } from "react";
import { SearchResult } from "./search-result";
import { FeedLoadMore } from "../feed/load-more";
import { useAuth } from "../../context/Auth";
import { Error } from "../error";
import { API, endpoints } from "../../api";
import { errorToString } from "../../utils/utils";
import { debounce } from "lodash";

export const Search = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [searchData, setData] = useState([]);

  const { setSearching, searching, user } = useAuth();

  const delayedHandleChange = debounce((str) => getSearchData(str), 500);

  const handleChange = (e) => {
    setSearch(e.target.value);
    delayedHandleChange(e.target.value);
  };

  const getSearchData = (str) => {
    setLoading(true);
    setError("");
    API.get({ route: `${endpoints.SEARCH}?query=${str}` })
      .then((data) => {
        setData(data?.data);
      })
      .catch((data) => {
        setError(errorToString(data?.response));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {user?.user ? <a onClick={() => setSearching(true)} className="ms-auto me-2 menu-search-icon mob-menu cursor-pointer show-lg">
        <i className="feather-search text-white font-xl btn-round-md"></i>
      </a> : null}
      <div className={`header-search shadow-md ${searching ? 'show' : ''}`} style={{ visibility: user?.user ? 'visible' : 'hidden', }}>
        <form action="#" className="float-left">
          <div className="form-group mb-0 icon-input">
            <i className="feather-search font-sm text-grey-400"></i>
            <input
              type="text"
              onFocus={() => setSearching(true)}
              value={search}
              onChange={handleChange}
              placeholder="Search & Message Anyone in ICN!"
              className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 search-input w500 theme-dark-bg rounded-xxl"
            />
          </div>
        </form>

        <div className="header-search-results">
          <i
            onClick={() => { setSearching(false); setSearch(''); setData([]) }}
            className="feather-x-circle text-grey-900 btn-round-md bg-greylight search-close-btn custom-icon-size"
          />
          <div class="card w-100 d-block d-flex border-0 p-4 mb-3 custom-search-border">
            <div class="card-body p-0 custom-search-card">
              {loading ? <FeedLoadMore /> : null}
              {searchData?.length > 0 ? (
                searchData.map((user) => (
                  <SearchResult key={user.id} {...user} />
                ))
              ) : (
                <div className="no-data text-center p-4">
                  {search ? "No record found." : "Start typing to see the search ..."}
                </div>
              )}
              <Error error={error} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
