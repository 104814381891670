import React, { useState } from "react";

import { Modal } from "../../../components";
import { EnterEventProvider } from "../../../context/EnterEvent";
import { EnterEvent } from "../../EnterEvent/enter-event";

export default ({ event, updateData }) => {
    const [open, setOpen] = useState(false);

    return <>
        <button
            onClick={() => setOpen(true)}
            className="bg-current text-center text-white font-xsss fw-600 rounded-3 border-0 btn-sm"
        >
            Update
        </button>
        <Modal
            style={{ minWidth: Math.min(800, window.screen.width - 200) }}
            show={open}
            close={setOpen}
            customClass="add-division-modal"
        >
            <div className="d-block">
                <h1 className="mb-4 text-capitalize"><strong>{`${event?.users?.firstName} ${event?.users?.lastName}`}</strong></h1>
                <div>
                    {open ? <EnterEventProvider enrolId={event?.id} userId={event?.users?.id} updateData={() => {
                        updateData?.()
                        setOpen(false)
                    }}>
                        <EnterEvent enrolId={event?.id} userId={event?.users?.id} />
                    </EnterEventProvider>
                        :
                        null
                    }
                </div>
            </div>
        </Modal>
    </>
}

