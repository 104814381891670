export default ({ title, icon, children, loading, bottomBtn }) => {
    return <div className="content-page">
        <div class="card shadow-xss w-100 d-flex border-0 p-4 mb-3">
            <div class="card-body d-flex align-items-center p-0">
                <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
                    {icon ? <i class={`btn-round-sm bg-blue-gradiant me-2  ${icon} text-white`} /> : null}
                    {title}
                </h2>
            </div>
        </div>
        {loading ? (
            <div className="loader-div">
                <img src="./images/preloader.svg" />
            </div>
        ) : (
            <>
                <div class="card shadow-xss w-100 border-0 p-4 mb-3 overflow-auto">
                    {children}
                    {bottomBtn ? <div className="form-group mt-5 mb-1 btn-right">
                        {bottomBtn}
                    </div> : null}
                </div>
            </>
        )}
    </div>
}