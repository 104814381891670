import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";

export const SearchResult = ({ id, image, location, name, user_name }) => {
  const { setSearching } = useAuth();

  const navigate = useNavigate();

  const goToChat = () => {
    if (window.location.href?.includes(id)) {
      //if already on same link
      navigate("/messages");
      setTimeout(() => navigate(`/messages/${id}`));
    } else {
      navigate(`/messages/${id}`);
    }
    setSearching(false);
  };

  return (
    <div
      key={`user-${id}`}
      class="card w-100 shadow-xss d-flex rounded-xxl border-0 p-3 mb-3"
    >
      <div class="card-body d-flex align-items-center p-0">
        <Link
          to={`/user/${user_name || id}`}
          onClick={() => setSearching(false)}
          className="d-flex align-items-center "
        >
          <figure class="avatar me-3 mb-0">
            <img
              src={image || "https://via.placeholder.com/50x50.png"}
              alt="image"
              class="shadow-sm rounded-circle w45 h45"
            />
          </figure>
          <h4
            style={{ maxWidth: "75%" }}
            class="fw-700 text-grey-900 font-xssss mt-1"
          >
            {name}
            {location ? (
              <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                <i class="fa-solid fa-location-dot"></i> {location}
              </span>
            ) : (
              ""
            )}
          </h4>
        </Link>
        <a
          onClick={() => {
            goToChat(id);
          }}
          class="ms-auto cursor-pointer"
        >
          <i class="feather-message-circle text-grey-900 btn-round-md bg-greylight font-xss"></i>
        </a>
      </div>
    </div>
  );
};
