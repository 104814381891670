import React, { useState } from "react";
import { Error, Loader } from "../";
import { API, endpoints } from "../../api";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = ({ setForgotPass, isPage }) => {
  const [email, updateEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { showToast, hideLoginModal } = useAuth();

  const navigate = useNavigate();

  const doLogin = (event) => {
    event.preventDefault();
    if (email) {
      setLoading(true);

      var data = new FormData();
      data.append("email", email);
      API.post({ route: endpoints.FORGOT_PASSWORD, data })
        .then((data) => {
          if (data?.status) {
            showToast({ text: data?.message });
            if (isPage) {
              navigate('/', { replace: true });
            }
            setForgotPass(false);
            hideLoginModal(true);
          } else setError(data?.message);
        })
        .catch((data) => {
          setError(data?.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      setError("Please enter email");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form
      onSubmit={doLogin}
      className={`card shadow-none border-0  ${isPage ? '' : 'ms-auto me-auto'}`}
    >
      <div className="card-body rounded-0 text-left">
        {isPage ? <div className="pt-4" /> : <h3 className="fw-700 display1-size display2-md-size mb-4 text-center">
          Forgot Password
        </h3>}

        <Error error={error} />

        <div className="form-group icon-input mb-3">
          <i className="font-sm ti-email text-grey-500 pe-0" />
          <input
            value={email}
            onChange={(event) => updateEmail(event.target.value)}
            type="text"
            autocorrect="off" autocapitalize="none"
            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
            placeholder="Your Email Address"
          />
        </div>
        <div className="col-sm-12 p-0 text-left">
          <div className="form-group mb-1">
            <button className="w200 form-control text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0 m-auto mt-5">
              Forgot Password
            </button>
          </div>
        </div>
        {isPage ? null : <div className="text-left mt-3 mb-1">
          <a
            onClick={() => setForgotPass(false)}
            className="fw-600 font-xsss text-grey-700 cursor-pointer"
          >
            <i className="fa-solid fa-arrow-left"></i> Go to login
          </a>
        </div>}
      </div>
    </form>
  );
};
