import React, { useState } from "react";
import { Modal } from "./";

export const Confirmation = ({
  btn,
  title,
  yesText,
  noText,
  yesClick,
  noClick,
  style,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <div onClick={toggle}>{btn}</div>
      <Modal
        show={modal}
        style={{ minWidth: 0, minHeight: 0, ...style }}
        onClick={() => {
          toggle();
          noClick?.();
        }}
      >
        <div className="text-center">{title}</div>
        <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-0 mt-4">
          <a
            onClick={() => {
              toggle();
              yesClick?.();
            }}
            className="p-2 lh-20 w150 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl cursor-pointer"
          >
            {yesText}
          </a>
          <a
            onClick={toggle}
            className="p-2 lh-20 w150 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl cursor-pointer"
          >
            {noText}
          </a>
        </div>
      </Modal>
    </>
  );
};
