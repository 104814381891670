import React, { useState, useEffect } from "react";
import { User } from "../user";
import { Likes } from "../likes";
import { Content } from "../content";
import { DeleteComment } from "./delete-comment";
import { useComments } from "../../../context/Comments";
import { useAuth } from "../../../context/Auth";
import strings, { toastType } from "../../../utils/strings";

export const Comment = ({
  id,
  feed_id,
  comment,
  user,
  can_delete,
  can_update,
  time,
  likes,
}) => {

  const { updateComment } = useComments();

  const [isEditMode, setEditMode] = useState(false)
  const [originalText, setOriginalText] = useState(comment)
  const { showToast, user: currentUser } = useAuth();


  useEffect(() => {
    setOriginalText(comment)
  }, [comment])

  const isAdmin = currentUser?.user?.role?.some(role => role?.role_id == 1);


  return (
    <div
      key={`comment_id-${id}`}
      className="card w-100  border-0 mb-4 single-comment bg-none"
    >
      <div className="card-body p-0 d-flex">
        <User time={time} {...user} size={35} />
        {isEditMode ? <div className="ms-auto white-space-nowrap">
          <a onClick={() => {
            setEditMode(false)
            setOriginalText('')
            setTimeout(() => setOriginalText(comment));
          }} className=" cursor-pointer mr-1">
            <i
              style={{ paddingRight: 3 }}
              className="feather-delete text-white btn-round-sm bg-mini-gradiant font-xss"
            />
          </a>
          <a
            onClick={() => {
              const txt = document.getElementById(`comment_text_${id}`)
              const val = txt?.innerText
              if (val?.trim())
                updateComment({ id, feedId: feed_id, comment: txt.innerText }).then((data) => {
                  setEditMode(false)
                })
              else
                showToast({ text: strings.updatedCommentError, type: toastType.ERROR });
            }}
            className="cursor-pointer">
            <i
              style={{ paddingLeft: 2 }}
              className="feather-check text-white btn-round-sm bg-green-gradiant font-xss"
            />
          </a>
        </div> : <>
          {can_delete || can_update || isAdmin ? <>
            <a
              class="ms-auto cursor-pointer"
              id="dropdownMenu6"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ti-more-alt text-grey-900 btn-round-sm bg-greylight font-xsss"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg"
              aria-labelledby="dropdownMenu6"
            >
              {isAdmin || can_update ? (
                <div class="card-body p-0 d-flex cursor-pointer"
                  onClick={() => {
                    setEditMode(true)
                    // setOriginalText('')
                    // setTimeout(() => setOriginalText(content));
                  }}
                >
                  <i class="feather-edit text-grey-500 me-3 font-lg"></i>
                  <h4 class="fw-600 text-grey-900 font-xssss mt-0 me-4">
                    Edit comment
                    <span class="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                      Edit description of the comment
                    </span>
                  </h4>
                </div>
              ) : null}
              {isAdmin || can_delete ? (
                <DeleteComment id={id} can_delete={can_delete} feed_id={feed_id} />
              ) : <></>}
            </div>
          </> : null}
        </>
        }
      </div>
      <Content id={id} content={originalText} isEditMode={isEditMode} />

      <div className="card-body d-flex p-0 mt-1 mb-1">
        <Likes id={id} feedId={feed_id} likes={likes} isComment />
      </div>
    </div>
  );
};
