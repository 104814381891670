import "./App.css";
import "./scss/main.scss";
// import "react-tooltip/dist/react-tooltip.css";

import { AuthProvider } from "./context/Auth";
import Routes from "./Routes";
import { isEventManager } from "./utils/utils";
import Translate from "./components/translate";
import ErrorBoundary from "./components/ErrorBoundary";

export const App = () => {
  return (
    <ErrorBoundary>
      <div className={isEventManager ? 'event-manager' : ''}>

        <AuthProvider>
          <Routes />
        </AuthProvider>
      </div>
    </ErrorBoundary>
  );
};
