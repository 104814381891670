import React, { useState } from "react";
import { Modal } from "./";
import OwlCarousel from "react-owl-carousel";
import { isArray } from "lodash";

export const ImageModal = ({ btn, img, style }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="cursor" onClick={toggle}>{btn}</div>
      <Modal
        show={modal}
        style={{ minWidth: 0, minHeight: 0, maxWidth: 'none', maxHeight: 'none', padding: 10, ...style }}
        onClick={toggle}
        close={() => setModal(false)}
      >
        <div className="text-center">
          {isArray(img) ? <div className="modal-inner-image"> <OwlCarousel
            items={1}
            dots
            dotsEach
            // touchDrag
            // mouseDrag
            className="owl-carousel owl-theme"
            loop={false}
            margin={10}
            nav
          >
            {img.map((image, index) => {
              return (
                <div key={`image-${index}`} class="item">
                  <img
                    className="modal-inner-image"
                    // style={{ maxWidth: "calc(90vh - 250px)" }}
                    src={image} />
                </div>
              );
            })}
          </OwlCarousel></div> : <img
            className="modal-inner-image"
            // style={{ maxWidth: "calc(90vh - 250px)" }}
            src={img} />
          }
        </div>
      </Modal>
    </>
  );
};
