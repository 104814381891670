import { Tab } from "semantic-ui-react";
import { useCommunicator } from "../../context/Communicator";
import EmailTab from "./EmailTab";
import SmsTab from "./SmsTab";
import PortalMsgTab from "./PortalMsgTab";

const panes = [
  {
    menuItem: "Email",
    render: () => (
      <Tab.Pane>
        <EmailTab />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "SMS",
    render: () => (
      <Tab.Pane>
        <SmsTab />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Portal Message",
    render: () => (
      <Tab.Pane>
        <PortalMsgTab />
      </Tab.Pane>
    ),
  },
];

const CommunicatorPanel = () => {
  const { setCommunicatorState, activeTabIndex } = useCommunicator();
  const handleTabChange = (e, data) => {
    const { activeIndex } = data;
    setCommunicatorState({
      activeTabIndex: activeIndex,
    });
  };
  return (
    <Tab
      panes={panes}
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
    />
  );
};

export default CommunicatorPanel;
