import { useEffect, useState } from "react";
import Moment from "react-moment";
import { API, baseURL, endpoints } from "../api";
import { Error } from "../components";
import { useAuth } from "../context/Auth";
import { ASYNC_STORAGE_KEYS, retrieveData } from "../utils/storage";
import { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";
import Content from "../components/content";

const Invoices = () => {
  const { showToast } = useAuth();
  const [invoices, setEvents] = useState([]);
  const [pageLoad, setPageLoad] = useState([]);

  const getData = () => {
    setPageLoad(true);
    API.get({ route: endpoints.INVOICES })
      .then((data) => {
        setEvents(data?.result);
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setPageLoad(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const generateInvoice = async (actionId) => {
    const token = (await retrieveData(ASYNC_STORAGE_KEYS.LOGIN_DATA, true))
      ?.access_token;

    window.open(
      `${baseURL}/api/v1/${endpoints.GENERATE_INVOICE}${actionId}&token=${token}`
    );
  };

  return <Content
    title="Invoices"
    icon="fa-solid fa-dollar-sign"
    loading={pageLoad}>
    {invoices?.length ? <table className="custom-table">
      <tr>
        <th>Name</th>
        <th>Invoice#</th>
        <th>Date</th>
        <th>Status</th>
        <th>Cost</th>
        <th>Download</th>
      </tr>
      {invoices?.map(
        ({
          id,
          description,
          createdAt,
          amount,
          actionCurrency,
          actionId,
          invoiceId,
          updatedAt,
        }) => (
          <tr key={`id-${id}`}>
            <td>{description}</td>
            <td>{invoiceId}</td>
            <td>
              <Moment format="DD MMM YYYY">{createdAt || updatedAt}</Moment>
            </td>
            <td>Paid</td>
            <td>
              {actionCurrency?.currency}{" "}
              {parseFloat(amount)?.toLocaleString("en-US")}
            </td>
            <td
              className="text-center cursor-pointer"
              onClick={() => generateInvoice(actionId)}
            >
              <i class="fa-solid fa-download text-current"></i>
            </td>
          </tr>
        )
      )}
    </table> : null}
    {!pageLoad && !invoices?.length ? <p className="font-xs">No Data to show</p> : null}
  </Content>
};

export default Invoices;
