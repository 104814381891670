import {
  Button,
  Dropdown,
  Form,
  Input,
  Label,
  Search,
} from "semantic-ui-react";
import { useEffect, useState } from "react";
import { Loader } from "../../components";
import { useCommunicator } from "../../context/Communicator";
import { isEmpty } from "lodash";

const initialFormData = {
  filterText: "",
  memberType: "",
  membershipStatus: "",
  gender: "",
  membershipNumber: "",
  emailAddress: "",
  promoterId: "",
  eventId: "",
  divisionId: "",
  countryId: "",
  stateId: "",
};

const Filter = () => {
  const [loading, setLoading] = useState(false);
  const [saveFilterLoading, setSaveFilterLoading] = useState(false);
  const [deletFilterLoading, setDeleteFilterLoading] = useState(false);
  const [updateFilterLoading, setUpdateFilterLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [divisionLoading, setDivisionLoading] = useState(false);

  const [oldSearch, setOldSearch] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    const { filterText, ...rest } = formData
    const old = JSON.stringify(rest)
    if (old != oldSearch) {
      const hasVal = Object?.keys(rest)?.filter((key) => rest[key])?.length
      if (hasVal) {
        setCanUpdate(true)
      }
      setOldSearch(old)
    }

  }, [formData])


  const {
    savedSearchOptions,
    memberTypesOptions,
    membershipStatusOptions,
    promotersOptions,
    memberGendersOptions,
    eventsOptions,
    divisionsOptions,
    countriesOptions,
    statesOptions,
    recepientCount,

    activeTabIndex,

    savedSearch,
    addFilter,
    deleteFilter,
    updateFilter,
    fetchEvents,
    fetchStates,
    fetchUserCount,
    fetchDivisions,
    setCommunicatorState,
  } = useCommunicator();

  console.log("memberTypesOptions => ", memberTypesOptions)

  useEffect(() => {
    if (savedSearch) {
      setLoading(false);
    }
  }, [savedSearch]);

  useEffect(() => {
    if (isEmpty(selectedFilter)) {
      setFormData((prev) => ({
        ...initialFormData,
        filterText: prev.filterText,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      filterText: selectedFilter?.name,
      memberType: selectedFilter?.membership_type,
      membershipStatus: selectedFilter?.membership_status,
      gender: selectedFilter?.gender,
      membershipNumber: selectedFilter?.membership_number,
      emailAddress: selectedFilter?.email_address,
      promoterId: selectedFilter?.promoterId,
      eventId: selectedFilter?.eventId,
      divisionId: selectedFilter?.eventId?.length ? selectedFilter?.divisionId : '',
    }));
  }, [selectedFilter]);

  const handleSavedSearchChange = (e, d) => {
    const selectedSearch = savedSearch.find(
      (item) => item.id === d.result.value
    );

    if (selectedSearch) {
      fetchEvents(selectedSearch.promoterId);
      setSelectedFilter(selectedSearch);
    }
  };

  const handleChange = (e, d) => {
    setFormData((prev) => ({
      ...prev,
      [d.name]: d.value,
    }));

    if (d.name === "filterText") {
      setSelectedFilter(null);
    }
  };

  const handleBlur = (e, d) => {
    if (d.name === "promoterId" && d.value) {
      setEventsLoading(true);
      fetchEvents(d.value).then(() => setEventsLoading(false));

      if (formData.countryId)
        fetchStates({
          countryId: formData.countryId,
          promoterId: d.value || formData.promoterId
        }).then(() => setStatesLoading(false));
    }

    if (d.name === "countryId" && d.value) {
      setStatesLoading(true);

      if (d.value || formData.countryId)
        fetchStates({
          countryId: d.value || formData.countryId,
          promoterId: formData.promoterId
        }).then(() => setStatesLoading(false));
    }

    if (d.name === "eventId") {
      setDivisionLoading(true);
      if (d?.value?.length)
        fetchDivisions({ eventId: d.value }).then(() => setDivisionLoading(false));
    }
  };

  const onSaveFilter = async (e) => {
    try {
      const {
        filterText: name,
        promoterId,
        memberType: membershipType,
        membershipNumber,
        membershipStatus,
        gender,
        emailAddress,
        countryId,
        stateId,
        eventId,
        divisionId
      } = formData;

      const data = {
        name,
        promoterId,
        membershipType,
        membershipStatus,
        countryId,
        stateId,
        gender,
        membershipNumber,
        emailAddress,
        eventId,
        divisionId
      };

      Object.keys(data).forEach((value) => {
        if (!data[value]) delete data[value];
      });

      setSaveFilterLoading(true);

      if (isEmpty(selectedFilter)) {
        await addFilter(data);
      } else {
        await updateFilter(selectedFilter.id, data);
      }
      setSaveFilterLoading(false);
    } catch (error) {
      setSaveFilterLoading(false);
    }
  };

  const onDeleteFilter = (e) => {
    setDeleteFilterLoading(true);
    deleteFilter(selectedFilter.id).then(() => {
      setFormData({ ...initialFormData });
      document.getElementById('filterTextInput').value = ''
      setDeleteFilterLoading(false)
      setSelectedFilter(null);
      setTimeout(() => {
        document.getElementById('filterTextInput').value = ''
      }, 1000)
    });
  };

  const onSubmit = async (e, d) => {
    setUpdateFilterLoading(true);

    try {
      await fetchUserCount(formData, activeTabIndex).then(() => {
        const { filterText, ...rest } = formData
        const old = JSON.stringify(rest)
        setOldSearch(old)
        setCanUpdate(false)
      })
      setCommunicatorState({
        filteredFormData: formData,
      });
      setUpdateFilterLoading(false);
    } catch (err) {
      setUpdateFilterLoading(false);
    }
  };

  return (
    <div className="communicator__filter">
      {loading && <Loader />}

      <Form onSubmit={onSubmit}>
        <h3>Select Recipient</h3>

        <div className="filter-form">
          <div className="form-field filterTextInputMain">
            <Search
              fluid
              value={formData?.filterText}
              minCharacters={0}
              placeholder="Use preset recipient filter"
              onResultSelect={handleSavedSearchChange}
              name={"filterText"}
              onSearchChange={handleChange}
              results={!formData?.filterText ? savedSearchOptions : savedSearchOptions?.filter(({ text }) => text?.includes(formData?.filterText))}
              id="filterTextInput"
            />
          </div>

          <div className="filter-form__actions overflow-hidden">
            <Button
              color="red"
              type="button"
              onClick={onSaveFilter}
              disabled={!formData.filterText || saveFilterLoading}
              loading={saveFilterLoading}
            >
              Save Filter
            </Button>
            <Button
              color="grey"
              type="button"
              onClick={onDeleteFilter}
              disabled={!selectedFilter}
              loading={deletFilterLoading}
            >
              Delete Filter
            </Button>
          </div>

          <div className="form-field">
            <Dropdown
              placeholder="Promoter"
              fluid
              selection
              options={promotersOptions}
              clearable
              name="promoterId"
              value={formData.promoterId}
              onChange={handleChange}
              onBlur={handleBlur}
              search
            />
          </div>

          <div className="form-field">
            <Dropdown
              placeholder="Event"
              fluid
              search
              selection
              multiple
              options={eventsOptions}
              clearable
              name="eventId"
              value={formData.eventId}
              onChange={handleChange}
              loading={eventsLoading}
              onBlur={handleBlur}
            />
          </div>


          {
            formData?.eventId?.length ?
              <div className="form-field">
                <Dropdown
                  placeholder="Division"
                  fluid
                  search
                  selection
                  multiple
                  options={divisionsOptions}
                  clearable
                  name="divisionId"
                  value={formData.divisionId}
                  onChange={handleChange}
                  loading={divisionLoading}
                />
              </div>
              : null
          }

          <div className="form-field">
            <Dropdown
              placeholder="Member Type"
              fluid
              selection
              options={memberTypesOptions}
              clearable
              name={"memberType"}
              value={formData.memberType}
              onChange={handleChange}
              search
            />
          </div>

          <div className="form-field">
            <Dropdown
              placeholder="Membership Status"
              fluid
              search
              selection
              multiple
              options={membershipStatusOptions}
              clearable
              name={"membershipStatus"}
              value={formData.membershipStatus}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <Dropdown
              placeholder="Country"
              fluid
              search
              selection
              multiple
              options={countriesOptions}
              clearable
              name="countryId"
              value={formData.countryId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="form-field">
            <Dropdown
              placeholder="State"
              fluid
              selection
              search
              options={statesOptions}
              clearable
              name="stateId"
              value={formData.stateId}
              onChange={handleChange}
              loading={statesLoading}
            />
          </div>
          <div className="form-field">
            <Dropdown
              placeholder="Gender"
              fluid
              selection
              options={memberGendersOptions}
              clearable
              name={"gender"}
              value={formData.gender}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <Input
              fluid
              placeholder="Membership number"
              name={"membershipNumber"}
              value={formData.membershipNumber}
              onChange={handleChange}
            />
            <Label>(Use comma to separate)</Label>
          </div>

          <div className="form-field">
            <Input
              fluid
              placeholder="Email address"
              name={"emailAddress"}
              value={formData.emailAddress}
              onChange={handleChange}
            />
            <Label>(Use comma to separate)</Label>
          </div>

          <div className="filter-form__footer">
            <Button
              type="submit"
              className="btn-submit"
              fluid
              color="red"
              loading={updateFilterLoading}
              disabled={!canUpdate || updateFilterLoading}
            >
              Update
            </Button>
            <p className="text-center text-danger">
              Changes have been made <br /> click <strong>Update</strong> to
              refresh list
            </p>

            <p className="text-center">
              Total selected recipient = <span className="notranslate">{recepientCount}</span>
            </p>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Filter;
