import React, { useState } from "react";
import { GoogleBtn } from "./google-btn";
import { Password } from "./password";
import { API, endpoints } from "../../api";
import { Error, Loader } from "../";
import { useAuth } from "../../context/Auth";

const setVal = (key) => {
  const val = localStorage.getItem(key);
  if (val && val !== 'null' && val != 'undefined') {
    return val
  }
  return ''
}

export const Login = ({ changeForm, setForgotPass }) => {
  const [email, updateEmail] = useState(setVal("email"));
  const [password, updatePassword] = useState(setVal("password"));
  const [remember, setRemember] = useState(setVal("remember"));

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { updateUser } = useAuth();

  const doLogin = (event) => {
    event.preventDefault();
    if (email && password) {
      setLoading(true);

      var data = new FormData();
      data.append("email", email);
      data.append("password", password);
      API.post({ route: endpoints.LOGIN, data })
        .then((data) => {

          if (event?.target?.remember?.checked) {
            localStorage.setItem("email", email || '');
            localStorage.setItem("password", password || '');
            localStorage.setItem("remember", 'true');
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("remember");
          }

          updateUser(data);
        })
        .catch((data) => {
          setError(data?.response?.data?.message || "Invalid credentials");
          updatePassword('');
        })
        .finally(() => setLoading(false));
    } else {
      setError("Please enter email and password");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form
      onSubmit={doLogin}
      className="card shadow-none border-0 ms-auto me-auto"
    >
      <div className="card-body rounded-0 text-left">
        <h3 className="fw-700 display1-size display2-md-size mb-4 text-center">
          LOGIN
        </h3>

        <Error error={error} />

        <div className="form-group icon-input mb-3">
          <i className="font-sm ti-user text-grey-500 pe-0" />
          <input
            value={email}
            onChange={(event) => updateEmail(event.target.value)}
            type="text"
            autocorrect="off" autocapitalize="none"
            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
            placeholder="Email / Member #"
          />
        </div>
        <Password value={password} update={updatePassword} autoComplete="on" />

        <div className="flex items-center justify-between mb-1 p-1">
          <div className="flex justify-center cursor-pointer remember-me">
            <input
              id="remember"
              aria-describedby="remember"
              type="checkbox"
              defaultValue={remember}
              checked={remember}
              onChange={(e) => {
                setRemember(!remember)
                console.log("rememberwqww => ", e.target.checked)
              }}
              className="w-4 h-4 mr-1 cursor-pointer border bg-white border-gray-300 rounded focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
            />
            <label
              htmlFor="remember"
              className="fw-600 text-grey-700 cursor-pointer"
            >
              Remember me
            </label>
          </div>
          <a
            onClick={() => setForgotPass(true)}
            className="forgot-password fw-600 font-xsss text-grey-700 float-right cursor-pointer"
          >
            Forgot your Password?
          </a>
        </div>
        <div className="col-sm-12 p-0 text-left">
          <div className="form-group mb-1">
            <button className="form-control text-center style2-input text-white fw-600 bg-primary-gradiant border-0 p-0 ">
              LOGIN
            </button>
          </div>
          <div className="form-group mt-4 mb-2">
            <button
              onClick={() => changeForm(false)}
              className="form-control text-center style2-input text-white fw-600 bg-red-gradiant border-0 p-0 signup-btn"
            >
              <i className="font-sm ti-email text-white pe-0 email-icon" />
              EMAIL SIGN UP &nbsp;{" "}
              <i className="hidden-sm" style={{ position: "static" }}>
                Don't worry - Its free and painless!
              </i>
            </button>
          </div>
        </div>
        <GoogleBtn />
      </div>
    </form>
  );
};
