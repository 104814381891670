import React, { useState } from "react";

export const Password = ({
  value,
  update,
  placeholder = "Password",
  autoComplete = "off",
}) => {
  const [show, toggle] = useState(false);

  return (
    <div className="form-group icon-input mb-3">
      <input
        value={value}
        onChange={(event) => update(event.target.value)}
        type={show ? "text" : "password"}
        className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
        placeholder={placeholder}
        required
        autoComplete={autoComplete}
        minLength={6}
      />
      <i className="font-sm ti-lock text-grey-500 pe-0" />

      {show ? (
        <i
          className="font-sm feather-eye text-grey-500 pe-0 right-icon"
          onClick={() => toggle(false)}
        />
      ) : (
        <i
          className="font-sm feather-eye-off text-grey-500 pe-0 right-icon"
          onClick={() => toggle(true)}
        />
      )}
    </div>
  );
};
