import { useMemo } from "react";
import { useEnterEvent } from "../../../context/EnterEvent";
import { DivisionsModal } from "./divisions-modal";
import { DivisionsTable } from "./divisions-table";

export const SelectDivisions = ({ enrolId }) => {
  const { selectedEvent } = useEnterEvent();

  const max = useMemo(
    () => selectedEvent?.maxDivisionsEnterable,
    [selectedEvent?.maxDivisionsEnterable]
  );

  return (
    <div class="card shadow-xss w-100 border-0 p-4 mb-3">
      <div class="card-body align-items-center p-0">
        {enrolId ? null : <p className="text-current m-0">
          <strong>Step 3 of 6</strong>
        </p>
        }
        {max ? (
          <p className="m-0 mb-1">
            <strong>Select Divisions (max of {max})</strong>
          </p>
        ) : null}
        <DivisionsModal />
        <DivisionsTable />
      </div>
    </div>
  );
};
