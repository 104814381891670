import React from "react";
import { Link } from "react-router-dom";
import { Badges } from "./badges";

export const User = ({
  id,
  image,
  name,
  time,
  size = 35,
  user_name,

  ...rest
}) => {
  return (
    <Link to={`/user/${user_name || id}`} className="card-body p-0 d-flex align-items-center">
      <figure class="avatar me-2">
        <img
          src={image}
          alt="user-image"
          class={`shadow-sm rounded-circle w${size} h${size} user-post-image`}
        />
      </figure>
      <h4 class="fw-700 text-grey-900 font-xssss mt-1">
        <span className="d-block">{name} <span style={{ marginLeft: 10 }} /> <Badges {...rest} /></span>
        {time ? (
          <span className="d-block font-xssss fw-500 lh-3 text-grey-500 time-text">
            {time}
          </span>
        ) : null}
      </h4>
    </Link>
  );
};
