import React from "react";

export default ({ value, setValue, text, valueToSet }) => {

    return <button
        onClick={() => value === valueToSet ? setValue('') : setValue(valueToSet)}
        className={`${value == valueToSet ? 'bg-current' : 'bg-gray'} text-center text-white font-xsss fw-600 rounded-3 border-0 btn-sm ml-1`}
    >
        {text}
    </button>
}

