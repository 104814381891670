import { EnterEventProvider } from "../../context/EnterEvent";
import { EnterEvent } from "./enter-event";

const EnterEventMain = ({ isViewEntry }) => {
  return (
    <EnterEventProvider>
      <EnterEvent isViewEntry={isViewEntry} />
    </EnterEventProvider>
  );
};

export default EnterEventMain;
