const MyMembership = () => {
  return (
    <>
      <div className="content-page">
        <div className="mb-4">
          <i class="fa-sharp fa-solid fa-file font-xl mr-11" />
          <span className="page-title ng-scope">FAQ: My Membership</span>
        </div>
        <div className="widget-main">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="mt-4">
                <b>Q.</b> How long is Membership for?
              </h3>
              <p>
                A. There are two type of Membership types and periods. Your Free
                Social Membership lasts indefinitely. When you upgrade to a
                Premium Membership to compete and/or advertise (in the Locality
                Guide) your membership is for the following period:
              </p>
              <p>
                Join between January 1 and June 30 your premium membership is
                renewable on December 31, or Join between July 1 and December 31
                your premium membership is renewable on June 30 the following
                year.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> What's the difference between Membership Fees and
                Event Fees, do I have to pay twice?
              </h3>
              <p>
                A. The iCompete Premium Membership Fee is the amount you pay to
                become a member who competes in any contest promoted in
                iCompete. It also means you are willing to be subject to drug
                testing anytime during your membership. Event Fees differ from
                contest to contest and are set by the individual promoters.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> Can I call and pay for my premium membership over the
                phone?
              </h3>
              <p>
                A. No, it must be done online because when you check the terms
                of agreement it forms part of a contract between you and
                ASADA/WADA that you agree to let them drug test you if you are
                selected. Therefore, the only way any person can become a
                Premium Member is via going through the online process.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> I get to the checkout page, but can't select my credit
                card?
              </h3>
              <p>
                A. iCompete uses PayPal as a third party financial gateway, you
                don't have to be a PayPal member to use PayPal, simply select
                'Pay as Guest' once inside PayPal and then select the credit
                card of your choice. Of course if you are a PayPal member then
                just pay in the usual method. After you successfully pay, you
                will be presented with an option to return back to the iCompete
                website.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> When I click the button on my renewal to pay it just
                sits there, what can it be?
              </h3>
              <p>
                A: It could be your bank card! You may have it set at a maximum
                of $100 for online transactions for security reasons, this is
                quite common. Raise your limit, do the transaction then you can
                decrease your limit again afterwards.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> I'm on a Mac and I can't seem to pay for my
                membership, what can I do?
              </h3>
              <p>
                A. Try Google Chrome, this works very well. We haven't had any
                problem with the browser for either Mac or Windows, it's a good
                idea to make sure you apply all O/S Updates when they are
                released.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> I can't find my member number.
              </h3>
              <p>
                A. You can gain access into your account via your original
                e-mail address and password, once you successfully login go to
                your Profile from the dashboard, your number is listed at the
                top of the page.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> Can I get a refund on my Premium Membership?
              </h3>
              <p>
                A. Membership refunds are rarely given and only apply to those
                people who have not competed yet. Once you compete there is no
                refund. We can only consider a refund if you join and cancel
                prior to competing less a $35 administration fee. You must make
                a written application to ron.ziemiecki@icompetenatural.com
                stating the reasons why you should get a refund, reasons such as
                you changed your mind will not be considered. The refund clause
                is only for genuine hardships that can be documented.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> Can I transfer my membership from another Federation
                not part of iCompete?
              </h3>
              <p>
                A. No, there are no agreements in place with other Federations.
                To compete with iCompete you need to become an iCompete Premium
                Member. Once you become an iCompete Premium Member you can
                compete in any event listed in iCompete whilst your membership
                is current. Membership lasts depending on the following
                schedule: Join between January 1 and June 30 your premium
                membership is renewable on December 31 or Join between July 1
                and December 31 your Premium Membership is renewable on June 30
                the following year. Social Membership to iCompete is free and
                can be upgraded to a Premium Membership at any time.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> I can't seem to find my Premium Membership number etc.
                I competed almost 2 years ago and need to get things organised.
                Do I renew or just join afresh?
              </h3>
              <p>
                A. Once you become an iCompete Member your member portal exists
                forever. In your case your Premium Membership is simply
                un-financial, so you revert back to a Social Member and all you
                need to do is renew/upgrade once you log in. The problem for
                most people is they have forgotten their password.
              </p>
              <p>
                <span>
                  Use the actual password you selected when you first joined or
                  whatever it is if you changed it in your profile section at
                  any time. If you just can't remember your password, then you
                  will have to reset it via this link:{" "}
                </span>
                <a
                  target="_blank"
                  href="https://apitest.icompetenatural.com/account/forgotpassword"
                >
                  https://apitest.icompetenatural.com/account/forgotpassword
                </a>
              </p>
              <p>
                Many people who reset their password claim they never get the
                e-mail which carries the new password. The reason in the
                overwhelming majority of cases is that the automatically sent
                notification simply went into their Junk Folder or was consumed
                by a spam filter. It's a very good idea to make sure the domain
                icompetenatural.com is included on your 'white list' or your
                'permitted list' in your e-mail software program, so that you
                never miss a notification from us again.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> I have tried going into password reset &amp; it won't
                send me my member number.
              </h3>
              <p>
                A. The password reset just changes your password, it does not
                issue your membership number. You can still login into iCompete
                by using your email address or Username instead of your
                membership number. You can find your membership number located
                in your Profile once you have successfully logged in. If you
                cannot remember any details please contact us using the HELP
                below the Sign In box.
              </p>
              <p>
                You can see your membership number located in your Profile once
                you have successfully logged in and then use your membership
                number to log in, instead of your original e-mail address.
              </p>
            </div>
          </div>
        </div>
        {/* added My Resources FAQ to this section */}
        <div className="widget-topbar">
          <ul>
            <li>
              <img src="/images/ico-document.fae19b78.png" alt="" />
              <span className="page-title ng-scope">FAQ: My Resources</span>
            </li>
          </ul>
        </div>
        <div className="widget-main">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="mt-4">
                <b>Q.</b> Where can I get late breaking news from?
              </h3>
              <p>
                A. The iCompete 'Fit Wall' is the leading global site for news
                in the world of fitness. We suggest you bookmark it or make it
                your homepage, so you can visit every day. We also have the
                iCompete companion website which is updated daily with news
                items{" "}
                <a target="_blank" href="http://www.icompeteaustralia.com.au/">
                  http://www.icompeteaustralia.com.au/
                </a>{" "}
                plus you can read past news which is archived at the bottom of
                the home page or by using the search facility. We also have an
                iCompete Facebook profile which deals with different aspects of
                the fitness industry at{" "}
                <a
                  target="_blank"
                  href="https://www.facebook.com/iCompete.Australia/"
                >
                  https://www.facebook.com/iCompete.Australia/
                </a>
              </p>
              <h3 className="mt-4">
                <b>Q.</b> How do I find out about posing classes?
              </h3>
              <p>
                A. Each country has their own arrangements for posing classes,
                for Australia please go here:{" "}
                <a
                  target="_blank"
                  href="http://www.icompeteaustralia.com.au/index.php?option=com_content&view=article&id=16&Itemid=40"
                >
                  http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=16&amp;Itemid=40
                </a>{" "}
                select your state and then contact your State President and ask
                about posing classes, they will let you know what's scheduled
                close to your location.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> What type of tan is required for Fitness Models?
              </h3>
              <p>
                A. Fitness Models do not need to be extremely dark like you see
                in the bodybuilding divisions. A dark spray tan is sufficient.
                However, some competitors in this division will use a top coat
                like the bodybuilder's tan's such as Body Shimmer or Tan Master.
                If you do use a bodybuilding top coat product we suggest you
                spread it thinly so as not to be too dark.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> What is the stage format for Male Fitness Model?
              </h3>
              <p>
                A. In the fitness wear round competitor's wear fitness or cargo
                styled shorts (sometimes supplied). The way the Male Fitness
                Model division is presented will vary at each show depending
                upon the stage configuration and number of competitors.
                Competitors may be required to do either an individual or group
                walk across the stage, walking to the front and centre of the
                stage to perform four quarter turns in your own time before
                continuing to the other side of the stage. Following the
                individual presentation, the whole group is brought on stage for
                comparisons of the quarter turns. The criteria can change
                internationally so please check with your country President
                first.
              </p>
              <h3 className="mt-4">
                <b>Q.</b> What is the best Stage Presence requirements in Figure
                International?
              </h3>
              <p>
                A. To attain the best Stage Presence, concentrate on the
                following:
              </p>
              <ul>
                <li>Confidence</li>
                <li>Execution of quarter turns and figure walk</li>
                <li>Skin tone, make-up, suit selection</li>
                <li>Overall presentation</li>
              </ul>
              <h3 className="mt-4">
                <b>Q.</b> Can a fitness model compete as a bikini model?
              </h3>
              <p>
                A. No this is a restriction in iCompete. There are no crossovers
                allowed between:
              </p>
              <ul>
                <li>Female: Bikini Model and Fitness Model</li>
                <li>Male: Mr. Physique and Bodybuilding</li>
              </ul>
              <h3 className="mt-4">
                <b>Q.</b> How can I get the Contest Results?
              </h3>
              <p>
                A. All iCompete Australia Contest Results since 2005 are
                archived here:{" "}
                <a
                  target="_blank"
                  href="http://www.icompeteaustralia.com.au/index.php?option=com_content&view=article&id=30&Itemid=57"
                >
                  http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=30&amp;Itemid=57
                </a>
              </p>
              <h3 className="mt-4">
                <b>Q.</b> Once the competition has concluded, when can I expect
                the Results posted on icompeteaustralia.com.au?
              </h3>
              <p>
                A. Generally within 24 hours, however sometimes results can take
                longer if the event promoter requires more time to finalize
                details.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyMembership;
