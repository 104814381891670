import moment from "moment";
import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API, endpoints } from "../api";
import { Confirmation } from "../components";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";

const SubscriptionStatus = () => {
  const { showToast, showGlobalLoader, checkSubscription, subscriptionData, user } =
    useAuth();

  const cancelSubscription = () => {
    showGlobalLoader(true);
    API.post({
      route: endpoints.CANCEL_SUBSCRIPTION,
    })
      .then((data) => {
        showToast({
          text: data?.message,
        });
        checkSubscription().finally(() => showGlobalLoader(false));
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
        showGlobalLoader(false);
      });
  };


  useEffect(() => {
    checkSubscription();
  }, []);

  const navigate = useNavigate();

  const purchase = () => {
    if (
      user?.user?.first_name &&
      user?.user?.date_of_birth &&
      user?.user?.occupation &&
      user?.user?.country_code &&
      user?.user?.country
    ) {
      navigate("/subscription");
    } else {
      navigate("/edit-profile/continue");
      showToast({
        text: strings.completeProfile,
        type: toastType.ERROR,
      });
    }
  }

  const canCancel = useMemo(() => {
    if (subscriptionData?.subscription?.status) {
      return true;
    }

    return false;
  }, [subscriptionData]);

  const getText = useMemo(() => {
    if (subscriptionData?.subscriptionMsg) {
      return subscriptionData?.subscriptionMsg
    }
    return "Get access to all Premium Features..."
    // else if (moment(subscriptionData?.expiryDate).isSameOrAfter(new Date())) {
    //   if (subscriptionData?.subscription?.status) {
    //     //has auto renew
    //     return strings.subscription.autoRenew;
    //   } else {
    //     //subscription cancelled
    //     return strings.subscription.validTill;
    //   }
    // } else {
    //   return strings.subscription.expired;
    // }
  }, [subscriptionData]);

  return (
    <>
      <div className="content-page">
        <div class="card shadow-xss w-100 d-flex border-0 p-4 mb-3">
          <div class="card-body d-flex align-items-center p-0">
            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
              <i class="text-white fa-solid fa-money-bill font-md me-2 btn-round-sm bg-blue-gradiant" />
              Membership
            </h2>
          </div>
        </div>
        <>
          <div class="card shadow-xss w-100 border-0 p-4">
            {/* <p>
              <strong>
                Your subscription purchased on{" "}
                {moment(
                  new Date(
                    parseInt(
                      subscriptionData?.subscription?.current_period_start?.toString() +
                        "000"
                    )
                  )
                ).format("DD MMM yyyy")}
              </strong>
            </p> */}
            <div>
              {/* Your subscription is valid till{" "} */}
              {getText}{" "}
              {/* {subscriptionData?.subscriptionMsg ? '' : moment(subscriptionData?.expiryDate).format("DD MMM yyyy")} */}
              {canCancel ? subscriptionData?.userSubscribed ? (
                <div className="form-group mt-5 mb-1">
                  <Confirmation
                    btn={
                      <button className="w200 form-control text-center style2-input text-white fw-600 bg-mini-gradiant border-0 p-0">
                        Cancel Membership
                      </button>
                    }
                    title="Are you sure you want cancel subscription?"
                    yesText="Yes"
                    noText="no"
                    yesClick={cancelSubscription}
                  />
                </div>
              ) : <></> : (
                subscriptionData?.plan?.subscription_payment ? <div className="form-group mt-5 mb-1">
                  <button onClick={purchase} className="w200 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0">
                    Purchase Membership
                  </button>
                </div> : null
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default SubscriptionStatus;
