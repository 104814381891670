import React, { useMemo } from "react";

export const Avatar = ({ user, className }) => {
  const firstLetter = useMemo(() => {
    return user?.name?.[0];
  }, [user?.name]);

  const userImage = useMemo(() => {
    return user?.image;
  }, [user?.image]);

  return userImage ? (
    <img
      src={userImage || "https://via.placeholder.com/50x50.png"}
      alt="image"
      className={`shadow-sm rounded-circle  ${className || "w30 h30"}`}
    />
  ) : (
    <div
      className={`shadow-sm rounded-circle bg-blue-gradiant text-white user-avatar ${className || "w30 h30"
        }`}
    >
      {firstLetter || ""}
    </div>
  );
};
