import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";

const FirstSection = () => {
  const navigate = useNavigate();
  const { userCheck } = useAuth();

  const goToMyProfile = () => {
    userCheck(() => {
      navigate(`/edit-profile`);
    });
  };

  const goToCommunication = () => {
    userCheck(() => {
      navigate(`/communication`);
    });
  };

  return (
    <div class="card shadow-xss w-100 border-0 p-4 mb-3">
      <div class="card-body align-items-center p-0">
        <p className="text-current m-0">
          <strong>Step 1 of 6</strong>
        </p>
        <p className="m-0 bold">
          Events are for financial Members. Free Members can Upgrade when they
          select a contest (step 2)
        </p>
        <p className="m-0 bold">
          Please ensure your personal details in{" "}
          <a className="text-current cursor-pointer" onClick={goToMyProfile}>
            My Profile
          </a>{" "}
          are up to date before entering this contest
        </p>
        {/* <p className="m-0">
          Please ensure your{" "}
          <a className="text-current cursor-pointer" onClick={goToCommunication}>Communication Preferences</a> are set to
          receive event updates from iCompete
        </p> */}
      </div>
    </div>
  );
};

export default FirstSection;
