import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API, endpoints } from "../api";
import { useAuth } from "./Auth";
import { errorToString } from "../utils/utils";
import { toastType } from "../utils/strings";

export const MessagesContext = createContext({
  chatListing: [],
  listLoading: false,

  chat: null,
  setChat: (_data) => { },
  setReadCount: (_groupId, _count) => { },

  getUserProfile: () => { },
});

export const MessagesProvider = (props) => {
  const { showToast, setMessagesCount } = useAuth();
  const [chat, setChat] = useState();

  const [chatListing, setChatListing] = useState();
  const [listLoading, setListLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    if (params?.id && chat?.userId != params?.id) {
      setChat({ userId: params?.id });
    }
  }, [params?.id]);

  useEffect(() => {
    setMessagesCount(0);
  }, []);

  const getData = () => {
    API.get({ route: endpoints.MESSAGE_LIST })
      .then((data) => {
        setChatListing(data?.data);
        if (!params?.id && !chat) {
          setChat({
            groupId: data?.data?.[0]?.groupId,
            member: data?.data?.[0]?.member,
          });
        } else if (chat?.userId && !chat?.groupId) {
          const findChat = data?.data?.find(
            ({ member }) => member?.id == chat?.userId
          );

          if (findChat) {
            //existing user
            setChat({
              groupId: findChat?.groupId,
              member: findChat?.member,
            });
          } else {
            //new user
            getUserProfile();
          }
        }
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setListLoading(false));
  };

  const getUserProfile = () => {
    return new Promise((resolve, reject) => {
      API.get({ route: `${endpoints.PUBLIC_PROFILE}${params?.id}` })
        .then((data) => {
          setChat({
            userId: params?.id,
            member: data?.data,
          });
          resolve();
        })
        .catch((data) => {
          showToast({
            text: errorToString(data?.response),
            type: toastType.ERROR,
          });
          reject();
        })
        .finally(() => setListLoading(false));
    });
  };

  const setReadCount = (chatItem, count = 0) => {
    if (!chatItem?.groupId && chatItem?.member?.id) {
      const item = chatListing?.find(
        ({ member }) => member?.id === chatItem?.member?.id
      );

      if (!item) {
        chatListing.unshift(chatItem);

        setChatListing([...chatListing]);
      }

      return;
    }

    const list = chatListing?.map((data) => {
      if (chatItem?.groupId === data?.groupId) {
        return { ...data, unseen: count };
      }
      return data;
    });
    setChatListing([...list.sort((a, b) => b.lastMessage - a.lastMessage)]);
  };

  useEffect(() => {
    getData();
  }, [params?.id]);

  return (
    <MessagesContext.Provider
      value={{
        chatListing,
        listLoading,
        chat,
        setChat,
        setReadCount,
        getUserProfile,
      }}
    >
      {props.children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
