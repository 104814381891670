import React from "react";
import { Confirmation } from "..";
import { useFeed } from "../../context/Feed";

export const DeletePost = ({ feed_id }) => {
  // const [loading, setLoading] = useState(false);

  const { deletePost } = useFeed();

  return (
    <>
      <div>
        <Confirmation
          btn={
            <div class="card-body p-0 d-flex mt-2 cursor-pointer">
              <i class="feather-delete text-grey-500 me-3 font-lg"></i>
              <h4 class="fw-600 text-grey-900 font-xssss mt-0 me-4">
                Delete Post
                <span class="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                  Delete your post
                </span>
              </h4>
            </div>
          }
          title="Are you sure you want to delete this post?"
          yesText="Yes Delete"
          noText="No"
          yesClick={() => deletePost(feed_id)}
        />
      </div>

      {/* {loading ? <Loader /> : null} */}
    </>
  );
};
