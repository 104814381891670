import React from "react";
import { useAuth } from "../../context/Auth";

export * from "./auth-modal";
export * from "./logoutModal";

export const Authorization = () => {
  const { toggleLoginModal } = useAuth();

  return (
    <>
      <a
        onClick={() => toggleLoginModal()}
        className="p-2 text-center ms-3 cursor-pointer hidden-sm hidden-lg"
      >
        <i className="feather-log-in font-xl text-white"></i>
      </a>
      <div onClick={() => toggleLoginModal()} className="show-lg mt-2 pt-2 text-right" style={{ margin: '0 10px 19px' }}>
        <button className="bg-primary-gradiant text-white font-bold border-0 px-4 py-2 rounded-3">Login / Join</button>
      </div>
    </>
  );
};
