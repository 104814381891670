import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Authorization, LogoutModal } from "../";
import { useAuth } from "../../context/Auth";
import adminIcon from '../../images/admin_image.png';
import communicatorIcon from '../../images/communicator_image.png';
import logo from "../../images/logo.png";
import memberIcon from '../../images/member_image.png';
import { HeaderCounts } from "../header-counts";
import { Notifications } from "./notifications";
import { Search } from "./search";
import Translate from "../translate";


export const Header = ({ isCommunicator }) => {
  const { user, setNavOpen, messagesCount, setMessagesCount, userCheck } =
    useAuth();

  const [isGridOptionsVisible, setGridOptionsVisible] = useState(false);
  const gridMenuRef = useRef(null);

  const navigate = useNavigate();

  const targetRoleId = [1, 5];
  const hasTargetRole = user?.user?.role?.some(role => targetRoleId?.includes(role?.role_id));

  const toggleGridOptions = () => {
    setGridOptionsVisible(!isGridOptionsVisible);
  };

  const closeGridOptions = () => {
    setGridOptionsVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (gridMenuRef.current && !gridMenuRef.current.contains(event.target)) {
        closeGridOptions();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {

      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [gridMenuRef]);

  const goToAdmin = () => {
    return `${process.env.REACT_APP_ADMIN_URL}/redirect-admin/${user?.access_token}`
  }

  return (
    <>
      <div className={`nav-header bg-white shadow-xs border-0 ${isCommunicator ? 'communicator-nav' : ''} ${user ? ' ' : 'logout-user'}`}>

        <div id="translator-drop-down">
          <Translate />
        </div>
        <div className="nav-top">
          {user?.user?.id ? (
            <>
              <Link
                to="/"
                onClick={() => document.body.scrollTop = document.documentElement.scrollTop = 0}
                class="p-2 text-center menu-icon center-menu-icon nav-small-a"
              >
                <i class="nav-home-btn font-lg theme-dark-bg text-white fa fa-solid fa-house-chimney"></i>
              </Link>
            </>
          ) : (
            <Link
              to="/"
              onClick={() => document.body.scrollTop = document.documentElement.scrollTop = 0}
              class="p-2 text-center ms-3 menu-icon center-menu-icon nav-small-a"
            >
              <i class="nav-home-btn font-lg theme-dark-bg text-white fa fa-solid fa-house-chimney"></i>
            </Link>
          )}

          <div className="show-lg" style={{ flex: 1, flexGrow: 1 }}>
            <>{user ? null : <Authorization />}</>
          </div>

          {
            isCommunicator ?
              <img className="communicator-logo" src={logo} alt="Communicator Portal" /> :
              <>
                {/* <Link to="/" className="icn-website-a">
                  <img className="icn-website" src="./images/icn-logo.png" />
                </Link> */}

                {/* <a
                  onClick={() =>
                    userCheck(() => {
                      setMessagesCount(0);
                      navigate(`/messages`);
                    })
                  }
                  className="mob-menu ms-auto me-2 chat-active-btn cursor-pointer position-relative"
                >
                  <i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i>
                  {messagesCount > 0 ? (
                    <span class="badge badge-primary text-white badge-pill fw-500 mt-0 badge-count">
                      {messagesCount}
                    </span>
                  ) : (
                    ""
                  )}
                </a>
                <Link to="/communicator" className="communicator-icon me-2 menu-search-icon mob-menu">
                  <i className="feather-mail text-grey-900 font-sm btn-round-md bg-greylight"></i>
                </Link>
                <Link to="/" className="me-2 menu-search-icon mob-menu">
                  <i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i>
                </Link> */}
                {/* <button
                  className="nav-menu me-0 ms-2"
                  onClick={() => setNavOpen(true)}
                /> */}
              </>
          }
        </div>

        {
          isCommunicator ? null :
            <>
              <Search />
              <HeaderCounts />
              <Notifications />
              <a
                onClick={() =>
                  userCheck(() => {
                    setMessagesCount(0);
                    navigate(`/messages`);
                  })
                }
                class={
                  `${(hasTargetRole && !isCommunicator) ? 'p-1' : 'p-4'}
                    text-center ms-3 menu-icon chat-active-btn cursor-pointer position-relative`
                }
              >
                <i class="feather-message-square font-xl text-white"></i>
                {messagesCount > 0 ? (
                  <span class="badge badge-primary text-white badge-pill fw-500 mt-0 badge-count">
                    {messagesCount}
                  </span>
                ) : (
                  ""
                )}
              </a>
              {/* <button
                className="nav-menu me-0 ms-2"
                onClick={() => setNavOpen(true)}
              /> */}
            </>
        }

        {(hasTargetRole && !isCommunicator) && (
          <div className="grid-menu" ref={gridMenuRef}>
            {/* Grid Icon */}
            <div
              className="p-2 p-4 menu-icon cursor-pointer"
              id="dropdownMenu3"
              onClick={toggleGridOptions}
            >
              <i className="feather-grid font-xl text-white"></i>
            </div>

            {/* Grid Options */}
            {isGridOptionsVisible && (
              <div className="grid-options-popup">
                {/* Communicator Option */}

                <a href={goToAdmin()} target="_blank" className="image-with-text">
                  <img src={adminIcon} className=" font-xl" />
                  <div className="text-below-image">Admin Portal</div>
                </a>

                {/* Add Link for admin portal */}

                <Link to="/communicator" target="_blank" className="image-with-text">
                  <img src={communicatorIcon} className=" font-xl" />
                  <div className="text-below-image">Communicator Portal</div>
                </Link>


              </div>
            )}
          </div>
        )}

        {/* Grid Inside Communicator Portal */}

        {(hasTargetRole && isCommunicator) && (
          <div className="grid-menu communicator-grid" ref={gridMenuRef}>
            {/* Grid Icon */}
            <div
              className="p-2 p-4 menu-icon cursor-pointer"
              id="dropdownMenu3"
              onClick={toggleGridOptions}
            >
              <i className="feather-grid font-xl text-white"></i>
            </div>

            {/* Grid Options */}
            {isGridOptionsVisible && (
              <div className="grid-options-popup">
                {/* Communicator Option */}

                <a
                  href={goToAdmin()}
                  target="_blank" className="image-with-text">
                  <img src={adminIcon} className=" font-xl" />
                  <div className="text-below-image">Admin Portal</div>
                </a>

                {/* Add Link for member portal */}

                <a href="/" target="_blank" className="image-with-text">
                  <img src={memberIcon} className=" font-xl" />
                  <div className="text-below-image">Member Portal</div>
                </a>


              </div>
            )}
          </div>
        )}

        {
          isCommunicator ? null :
            <button
              className="nav-menu me-0 ms-2 pr-1 fa fa-solid fa-bars"
              onClick={() => setNavOpen(true)}
            />
        }

        <div className="hidden-lg">
          {
            isCommunicator ? <div className="communicator-logout">
              <>{user ? <LogoutModal /> : <Authorization />}</>
            </div> :
              <>{user ? <LogoutModal /> : <Authorization />}</>
          }
        </div>



      </div>
    </>
  );
};
