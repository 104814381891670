import React from "react";
import { useFeed } from "../../context/Feed";
import { CreatePost } from "./create-post";
import { Post } from "./post";
import { FeedLoadMore } from "./load-more";
import { FeedLoader } from "./loader";
import { useAuth } from "../../context/Auth";

export const FeedContent = ({ showFilter }) => {
  const { feedData, loading, loadingMore, setFilter, filter } = useFeed();
  const { user } = useAuth()

  return (
    <>
      {showFilter ? (
        <div class="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div class="card-body p-0">
            {/* {user?.user?.id ? null : <div className="flex justify-center" >
              <img style={{ maxWidth: 100, width: '100%', marginBottom: 15 }} src="../images/ICN-World-Wide.png" />
            </div>} */}
            <div className="home-filter">
              <div className="flex justify-center" >
                <img style={{ maxWidth: 150, width: '100%', marginBottom: 15 }} src="../images/ICN-World-Wide.png" />
              </div>
              <div
                className="form-group mb-0 w-50"
                style={{ margin: "0 36px" }}
              >
                <select
                  value={filter}
                  onChange={(event) => setFilter(event?.target?.value)}
                  className="style2-select ps-3 form-control text-grey-900 font-xsss fw-600"
                >
                  <option value="">Worldwide</option>
                  <option value="Country">Country</option>
                  <option value="State">State</option>
                  <option value="City">City (+ 100km/60mi radius)</option>
                </select>
              </div>
              <div className="filter-content-wrapper">
                Use our Filter to connect with the Community most important to
                you!
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <CreatePost />

      {loading ? <FeedLoader /> : null}

      {feedData?.map((item) => (
        <Post key={`feed-item-${item.feed_id}`} {...item} />
      ))}
      {loadingMore ? <FeedLoadMore /> : null}
    </>
  );
};
