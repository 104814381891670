export const baseURL = process.env.REACT_APP_API_ENDPOINT;

export const endpoints = {
  FEED: "feed",
  LOGIN: "login",
  REGISTER: "register",

  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",

  FEED_DELETE: "feed/delete",
  FEED_HIDE: "feed/hide",
  FEED_UPDATE: "feed/update",

  LIKE: "feed/likes",
  LIKE_DELETE: "feed/likes/delete",

  COMMENT: "feed/comments",
  COMMENT_DELETE: "feed/comments/delete",
  COMMENT_UPDATE: "feed/comments/update",

  LIKE_COMMENT: "feed/comments/likes",
  LIKE_COMMENT_DELETE: "feed/comments/likes/delete",

  SUBSCRIPTION: "subscription",

  PROFILE: "profile",
  UPDATE_PROFILE: "update-profile",

  UPDATE_LOCATION: "update-location",

  COUNTRY_MEMBER_COUNT: "coutry-member-count",
  COUNTRIES: "countries",

  CALENDER: "calender",
  BUSINESS: "business",
  BUSINESS_TYPE: "business-type",

  SEARCH: "search",
  CONTACT: "contact",
  CHANGE_PASSWORD: "change-password",
  REPORT_DOPING: "report-doping",

  EVENT: "event",
  EVENT_DETAIL: "event/detail?eventId=",
  EVENT_ENROL: "event/enrol",

  // SUBSCRIPTION: "subscription/is-subscribed",

  //Communicator API
  COMMUNICATOR_SAVED_SEARCH: "communicator/search",
  COMMUNICATOR_MEMBER_TYPES: "communicator/member/types",
  COMMUNICATOR_MEMBERSHIP_STATUS: "communicator/member/status",
  COMMUNICATOR_MEMBER_GENDERS: "communicator/member/genders",
  COMMUNICATOR_PROMOTER: "communicator/promoter",
  COMMUNICATOR_SEARCH_ADD: "communicator/search/add",
  COMMUNICATOR_SEARCH_DELETE: "communicator/search",
  COMMUNICATOR_EVENT: "communicator/event",
  COMMUNICATOR_DIVISION: "communicator/event/division",
  COMMUNICATOR_COUNTRIES: "communicator/country",
  COMMUNICATOR_USER_SMS_TEMPLATE: "communicator/user/sms-template",
  COMMUNICATOR_USER_COUNT_EMAIL: "communicator/user/count/email",
  COMMUNICATOR_USER_COUNT_SMS: "communicator/user/count/sms",
  COMMUNICATOR_USER_COUNT_PORTAL_MESSAGE:
    "communicator/user/count/portal-message",
  COMMUNICATOR_FROM_EMAIL_ADDRESS: "communicator/from-email-address",
  COMMUNICATOR_USER_SEND_EMAIL: "communicator/user/send-email",
  COMMUNICATOR_USER_SEND_SMS: "communicator/user/send-sms",
  COMMUNICATOR_TEST_SMS: "communicator/test-sms",
  COMMUNICATOR_USER_SEND_PORTAL_MESSAGE:
    "communicator/user/send-portal-message",
  COMMUNICATOR_PREVIOUS_SMS_COMMUNICATIONS:
    "communicator/user/previous-sms-communications",

  COMMUNICATOR_CURRENT_JOB_SUMMARY: "communicator/user/current-job-summary",
  COMMUNICATOR_UPLOAD_ATTACHMENTS: "communicator/upload-attachments",
  EVENT_PAY_STRIPE: "event/order/stripe/payment",
  EVENT_PAY_RAZORPAY_ORDER: "event/order/razorpay/details?actionId=",
  EVENT_PAY_RAZORPAY: "event/order/razorpay/payment",

  NOTIFICATIONS: "notifications",
  WITHDRAW: "event/enrol/withdraw",

  INVOICES: "event/order/invoices",

  GENERATE_INVOICE: "event/order/generate-invoice?actionId=",

  CHECK_RESET_PASSWORD_TOKEN: "check-reset-password-token",

  MESSAGE_LIST: "message/list",

  MESSAGE_SEND: "message/send",
  MESSAGE_DETAIL: "message/detail",

  PUBLIC_PROFILE: "public-profile?id=",

  PAYPAL: "event/order/paypal/details?actionId=",

  EMAIL_PREFERENCES: "get-email-preferences",
  UPDATE_EMAIL_PREFERENCES: "update-email-preferences",

  CANCEL_SUBSCRIPTION: "cancel-subscription",


  OFFLINE_LOGIN: "offlineregistration/login",
  OFFLINE_EVENTS: "offlineregistration/event/list",
  OFFLINE_COMPETITORS: "offlineregistration/event/competitor",
  OFFLINE_PAYMENT: "offlineregistration/event/payment",

  OFFLINE_ACCESSORIES: "offlineregistration/event/accessories",

  RESEND_EMAIL: "resend",


};
