import { useEffect } from "react";
import ReactDom from "react-dom";

export const Modal = ({
  show,
  close,
  children,
  id = "modal",
  style,
  canCloseOnBackdrop = true,
  customClass,
}) => {
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        // const boxes = document.querySelectorAll(".modalContainer");

        // boxes.forEach((box) => {
        //   box.classList.remove("show");
        // });

        close?.();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [close]);

  return ReactDom.createPortal(
    <>
      <div
        className={`modalContainer ${show ? "show" : ""} `}
        onClick={() => canCloseOnBackdrop && close?.()}
      >
        <div
          className={`custom-modal shadow-xss rounded-xxl border-20 ${customClass || ""
            }`}
          onClick={(e) => e.stopPropagation()}
          style={style}
        >
          {close ? (
            <i
              onClick={() => close?.()}
              className="feather-x-circle text-grey-900 font-sm btn-round-md bg-greylight modal-close-btn"
            />
          ) : null}

          {children}
        </div>
      </div>
    </>,
    document.getElementById(id)
  );
};

export default Modal;
