import React from "react";
import { Modal } from "./";

export const ConfirmationNoBtn = ({
  title,
  subTitle,
  yesText,
  noText,
  yesClick,
  noClick,
  style,
  open,
  singleBtn = false,
}) => {
  const createMarkup = (val) => {
    return {
      __html: val,
    };
  };

  return (
    <>
      <Modal
        show={open}
        style={{ minWidth: 0, minHeight: 0, ...style }}
        onClick={() => noClick?.()}
      >
        {title ? (
          <div className="text-center mt-4">
            <strong dangerouslySetInnerHTML={createMarkup(title)} />
          </div>
        ) : null}
        {subTitle ? (
          <div
            className="text-center mt-3"
            dangerouslySetInnerHTML={createMarkup(subTitle)}
          />
        ) : null}
        <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4 mt-4">
          {singleBtn ? (
            <a
              style={{ visibility: "hidden" }}
              className="p-2 lh-20 w75 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl cursor-pointer"
            >
              {noText}
            </a>
          ) : null}
          <a
            onClick={() => yesClick?.()}
            className="p-2 lh-20 w150 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl cursor-pointer"
          >
            {yesText}
          </a>
          {singleBtn ? null : (
            <a
              onClick={() => noClick?.()}
              className="p-2 lh-20 w150 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl cursor-pointer"
            >
              {noText}
            </a>
          )}
        </div>
      </Modal>
    </>
  );
};
