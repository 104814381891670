import { useAuth } from "../../context/Auth";
import { MessagesProvider } from "../../context/Messages";
import { Error } from "../error";
import ChatSingle from "./chat";
import ChatListing from "./listing";

export const Messages = () => {
  const { user } = useAuth();

  if (!user?.user?.id) {
    return (
      <div className="small-content">
        <div>
          <Error error="Please login first" />
        </div>
      </div>
    );
  }

  return (
    <MessagesProvider>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
        <div class="card-body d-flex align-items-center p-0">
          <i class="btn-round-sm bg-blue-gradiant me-2 fa-solid fa-comment text-white" />
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">Messages</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <ChatListing />
        </div>
        <div className="col-md-8">
          <ChatSingle />
        </div>
      </div>
    </MessagesProvider>
  );
};
