import React from "react";
import { API, endpoints } from "../../../api";

import { Confirmation } from "../../../components";
import { useAuth as useAuthMain } from "../../../context/Auth";
import { toastType } from "../../../utils/strings";
import { errorToString } from "../../../utils/utils";


export default ({ text, value, name, data, updateData }) => {

    const { showGlobalLoader: setLoading, showToast } = useAuthMain()

    const updateStatus = () => {
        setLoading(true);
        API.post({ route: endpoints.OFFLINE_ACCESSORIES, params: data })
            .then((data) => {
                updateData?.()
            })
            .catch((error) => {
                showToast({
                    text: errorToString(error?.response),
                    type: toastType.ERROR,
                });

            })
            .finally(() => setLoading(false));
    }


    return <Confirmation
        btn={
            <div
                class="border-0 d-block mt-3"
            >
                <label class="toggle toggle-menu-color row left-line-toggle">
                    <div>
                        <input
                            readOnly
                            disabled
                            // defaultChecked={value}
                            type="checkbox"
                            checked={value}
                        />
                        <span class="toggle-icon"></span>
                    </div>
                    <div class="font-xssss mont-font fw-700 text-capitalize">
                        {value ? 'Yes' : 'No'}
                    </div>
                </label>
            </div>
        }
        // title={`Are you sure you want to set ${text} delivered as "${value ? 'No' : 'Yes'}" for ${name}`}
        // title={`Have you delivered the ${text} to ${name}?`}
        title={`Are you sure?`}
        yesText="Yes, Update"
        noText="No"
        yesClick={updateStatus}
    />
}

