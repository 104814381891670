import React, { useEffect, useMemo, useState } from "react";
import { Error, Modal } from "../../../components";
import { useEnterEvent } from "../../../context/EnterEvent";
import strings from "../../../utils/strings";
import { useParams } from "react-router-dom";

export const DivisionsModal = () => {
  const [open, setOpen] = useState(false);
  const [selectedId, seSelectedId] = useState();

  useEffect(() => {
    seSelectedId(undefined);
  }, [open]);

  const {
    selectedEvent,
    setSelectedEvent,
    enrollData,
    setEnrollData,
    lateFee: eventLateFee,
    editId,
    isLateFeeAppliedOnEditEvent
  } = useEnterEvent();

  const max = useMemo(
    () => selectedEvent?.maxDivisionsEnterable,
    [selectedEvent?.maxDivisionsEnterable]
  );

  const toggleDivisionSelect = (id) => {
    if (selectedId === id) {
      seSelectedId(undefined);
    } else {
      seSelectedId(id);
    }
  };

  const unavailableDivisions = useMemo(() => {
    const arr = [];
    enrollData?.division?.forEach(({ divisionTypeId }) => {
      const division = selectedEvent?.divisions?.find(
        ({ divisionTypeId: id }) => id === divisionTypeId
      );
      division?.divisionType?.unavailableDivisions?.forEach(
        ({ unavailableDivisionTypeId, divisionTypeId }) => {
          if (!arr.includes(divisionTypeId))
            arr.push(unavailableDivisionTypeId);
        }
      );
    });
    return arr;
  }, [enrollData, selectedEvent, open]);

  const params = useParams();

  const addSelected = () => {
    const newData = selectedEvent;
    const findIndex = newData.divisions?.findIndex(
      ({ id }) => id === selectedId
    );
    newData.divisions[findIndex].selected =
      !newData.divisions[findIndex].selected;
    setSelectedEvent({ ...newData });

    const ids = [];
    const allSelectedDivisions = newData.divisions
      ?.filter(({ selected }) => selected)
      .map(({ id, divisionTypeId }) => {
        let entryFee = 0;
        let lateFee = 0;


        if (!ids?.length) {
          //first division
          entryFee = selectedEvent?.divisionEntryFee;
          lateFee = !isLateFeeAppliedOnEditEvent && editId ? 0 : (eventLateFee?.firstDivisionSurcharge || 0);
        } else {
          entryFee = selectedEvent?.additionalDivisionEntryFee;
          lateFee = !isLateFeeAppliedOnEditEvent && editId ? 0 : (eventLateFee?.additionalDivisionSurcharge || 0);
        }
        ids.push(id);

        const oldData = enrollData?.division?.find(
          ({ eventDivisionId }) => id === eventDivisionId
        );

        return {
          ...oldData,
          eventDivisionId: id,
          divisionTypeId,
          entryFee,
          lateFee,
        };
      });

    setEnrollData({ ...enrollData, division: allSelectedDivisions });

    setOpen(false);
  };

  return (
    <>
      <div>
        <button
          type="submit"
          onClick={() => setOpen(true)}
          disabled={!selectedEvent || max <= enrollData?.division?.length}
          className="form-control text-center small-btn text-white bg-blue-gradiant border-0 p-0 "
        >
          <i className="fa-solid fa-circle-plus"></i> Add Division
        </button>{" "}
        {max <= enrollData?.division?.length ? (
          <strong className="text-red">{strings.event.division.max}</strong>
        ) : null}
      </div>
      <Modal
        style={{ minWidth: 900 }}
        show={open}
        close={setOpen}
        customClass="add-division-modal"
      >
        <div>
          <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 text-center mt-3 mb-4">
            Add Division
          </h2>
          {selectedEvent?.divisions?.length ? (
            <div>
              <div className="row ">
                <div className="col col-lg-4">
                  <strong>DIVISION</strong>
                </div>
                <div className="hidden-sm col col-lg-8">
                  <strong>DIVISION RULE</strong>
                </div>
              </div>
              {selectedEvent?.divisions?.map((division, i) =>
                division?.selected ? null : (
                  <Row
                    key={`division-modal-row-${i}`}
                    selectedId={selectedId}
                    division={division}
                    unavailableDivisions={unavailableDivisions}
                    toggleDivisionSelect={toggleDivisionSelect}
                  />
                )
              )}

              <div className="d-flex two-btns">
                <button
                  onClick={() => setOpen(false)}
                  className="form-control text-center small-btn black-white bg-gray-gradiant border-0 p-0 "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={addSelected}
                  disabled={!selectedId}
                  className="form-control text-center small-btn text-white bg-blue-gradiant border-0 p-0 "
                >
                  Add Selected
                </button>
              </div>
            </div>
          ) : (
            <div>
              <Error error="No Division Added" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const Row = ({
  selectedId,
  division,
  unavailableDivisions,
  toggleDivisionSelect,
}) => {
  const { enrollData } = useEnterEvent();
  const selectedIds = enrollData?.division?.map(
    ({ divisionTypeId }) => divisionTypeId
  );

  let isAvailable = false;


  if (!selectedIds?.length || division?.pivot?.type != "yes") {
    isAvailable = true;
  } else if (!unavailableDivisions?.includes(division.divisionTypeId)) {

    const blockedBy = division?.divisionType?.unavailableDivisions?.findIndex(
      ({ unavailableDivisionTypeId }) =>
        selectedIds?.includes(unavailableDivisionTypeId)
    )

    if (blockedBy < 0 || blockedBy == undefined) {
      isAvailable = true;
    }
  }


  return (
    <div
      key={`division-modal-${division?.id}`}
      className={`row custom-select-row division-select-row ${isAvailable ? "click-to-select" : "disabled-division"
        } ${selectedId === division?.id ? "selected-division" : ""}`}
      onClick={() => isAvailable && toggleDivisionSelect(division?.id)}
    >
      <div key={`${division?.id}`} className={`col-lg-4`}>
        {selectedId === division?.id ? (
          <i className="fa-regular fa-square-check mr-1 text-current"></i>
        ) : (
          <i className="fa-regular fa-square mr-1"></i>
        )}
        {division?.name || division?.divisionDesc}
      </div>
      <div className="col col-lg-8 sm-no-right-padding" style={{ paddingRight: 200 }}>
        {division?.divisionName || division?.divisionDesc}
      </div>
    </div>
  );
};
