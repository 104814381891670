import React from "react";

export const Error = ({
  error,
  clear,
  bgClass = "bg-mini-gradiant",
  textClass = "text-white",
}) => {
  const createMarkup = () => {
    return {
      __html: error,
    };
  };

  return error ? (
    <div
      className={`card w-100 shadow-xss rounded-xxl border-0 p-3 mb-3 error-msg ${bgClass}`}
    >
      <div className="card-body p-0 d-flex">
        {clear ? (
          <i
            onClick={() => clear?.()}
            className="feather-x-circle text-grey-900 font-sm btn-round-md bg-greylight clear-btn cursor-pointer"
          />
        ) : null}
        <span
          className={`fw-600 font-xsss ${textClass}`}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
