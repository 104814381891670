import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Button } from "semantic-ui-react";
import { createTagDropdown } from "./hooks";
import { useCommunicator } from "../../context/Communicator";
import { useAuth } from "../../context/Auth";
import { Confirmation } from "../../components";

const initialFormData = {
  platform: 3,
  message: "",
};
const PortalMsgTab = () => {
  const editorRef = useRef();
  const [formData, setFormData] = useState(initialFormData);
  const [sendPortalMsgLoading, setSendPortalMsgLoading] = useState(false);
  const { sendPortalMessage, filteredFormData, recepientCount } =
    useCommunicator();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
        handlers: {
          tags: tagsHandler,
        },
      },
    }),
    []
  );

  useEffect(() => createTagDropdown(editorRef.current), [editorRef]);

  function tagsHandler(value) {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, value);
    this.quill.setSelection(cursorPosition + value.length);
  }

  const handleQuillChange = (message) => {
    setFormData((prev) => ({
      ...prev,
      message,
    }));
  };

  const { showToast } = useAuth();

  const handleSubmit = async () => {
    if (!formData?.message || formData?.message == '<p><br></p>') {

      return showToast({
        text: 'Please add message body',
        type: "error",
      });
    }

    formData.message = formData?.message?.split('<p><br></p>').join('');

    setSendPortalMsgLoading(true);
    try {
      const payload = { ...formData, ...filteredFormData };
      await sendPortalMessage(payload);
      setFormData((prev) => ({
        ...prev,
        message: '',
      }));
      setSendPortalMsgLoading(false);
    } catch (err) {
      setSendPortalMsgLoading(false);
    }
  };

  return (
    <div className="communicator__portal-tab">
      <div className="portal-editor">
        <div className="form-field">
          <div id="toolbar">
            <select class="ql-tags">
              <option value="{firstName}">First Name</option>
              <option value="{lastName}">Last Name</option>
              <option value="{membershipNumber}">Membership Number</option>
            </select>
          </div>
          <div className="notranslate">
            <ReactQuill
              value={formData.message}
              onChange={handleQuillChange}
              ref={editorRef}
              modules={modules}
              theme="snow"
            /></div>
        </div>
        <div className="form-field text-right">

          {sendPortalMsgLoading || !recepientCount || !formData.message || formData?.message == '<p><br></p>' ? (
            <Button
              type="submit"
              className="btn-submit"
              color="red"
              loading={sendPortalMsgLoading}
              disabled
            >
              Send Group Messages
            </Button>
          ) : (
            <Confirmation
              btn={
                <Button
                  type="submit"
                  className="btn-submit"
                  color="red"
                  loading={sendPortalMsgLoading}

                >
                  Send Group Messages
                </Button>
              }
              title={`Are you sure you want to send portal message  to ${recepientCount} user(s)`}
              yesText="Yes Send"
              noText="No"
              yesClick={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PortalMsgTab;
