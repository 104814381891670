import React from "react";
import { Confirmation } from "../../confirmation";
import { useComments } from "../../../context/Comments";

export const DeleteComment = ({ id, feed_id, can_delete }) => {
  const { deleteComment } = useComments();

  return can_delete ? (
    <div className="d-inline-block">
      <Confirmation
        btn={
          <div class="card-body p-0 d-flex mt-2 cursor-pointer">
            <i class="feather-delete text-grey-500 me-3 font-lg"></i>
            <h4 class="fw-600 text-grey-900 font-xssss mt-0 me-4">
              Delete Comment
              <span class="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                Delete your comment
              </span>
            </h4>
          </div>
        }
        title="Are you sure you want to delete this comment?"
        yesText="Yes Delete"
        noText="No"
        yesClick={() => deleteComment({ id, feedId: feed_id })}
      />
    </div>
  ) : null;
};
