import React, { useMemo, useState } from "react";
import { Comment } from "./comment";
import { AddComment } from "./add-comment";
import { CommentsProvider } from "../../../context/Comments";

export const Comments = ({ comments = [], id, count }) => {
  const canShowMore = useMemo(() => count > 3, [count]);
  const [full, setFull] = useState(false);

  const showingComments = useMemo(() => {
    if (canShowMore && !full) {
      return [...comments.slice(-3)];
    }
    return comments;
  }, [canShowMore, comments, full]);

  const toggle = () => setFull(!full);

  return (
    <CommentsProvider>
      <div
        className="card w-100 border-0 p-3 pt-0 comment-wrapper"
      // style={{ background: "rgba(0,0,0,.05)" }}
      >
        <div className="card-body p-0">
          {canShowMore ? (
            <a onClick={toggle} class="fw-600 text-primary ms-2 cursor-pointer">
              {full ? "Show only last 3 comments" : "View previous comments"}
            </a>
          ) : null}
          {showingComments?.map((comment) => {
            return <Comment {...comment} />;
          })}

          <AddComment id={id} />
        </div>
      </div>
    </CommentsProvider>
  );
};
