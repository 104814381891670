import { useState } from "react";
import { API, endpoints } from "../api";
import { Error } from "../components";
import { useAuth } from "../context/Auth";
import strings from "../utils/strings";
import { errorToString } from "../utils/utils";
import { Link } from "react-router-dom";

const Contact = () => {
  const [error, setError] = useState("");
  const { user = {}, showGlobalLoader: setLoading, showToast } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {};
    postData.name = e?.target?.elements?.name?.value;
    postData.email_address = e?.target?.elements?.email_address?.value;
    postData.membership_number = e?.target?.elements?.membership_number?.value;
    postData.issue = e?.target?.elements?.issue?.value;
    postData.description = e?.target?.elements?.description?.value;

    setError("");
    setLoading(true);
    var data = new FormData();
    Object.keys(postData).forEach((key) => {
      data.append(key, postData[key]);
    });

    API.post({ route: endpoints.CONTACT, data })
      .then(() => {
        showToast({ text: strings.contactFormSubmitted });
        window.scrollTo(0, 0);
        document.getElementById("contactForm")?.reset()
      })
      .catch((data) => {
        setError(errorToString(data?.response));
        window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
        <div class="card-body d-flex align-items-center p-0">
          <i className="btn-round-sm bg-blue-gradiant me-2 fa-solid fa-question text-white" />
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">Help Request</h2>
        </div>
      </div>
      <Error error={error} />

      <form id="contactForm" onSubmit={handleSubmit}>
        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
          <div className="card-body p-lg-5 p-4 w-100 border-0 ">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label className="mont-font fw-600 font-xsss">Name</label>
                  <input
                    required
                    name="name"
                    defaultValue={user?.user?.first_name ? `${user?.user?.first_name} ${user?.user?.last_name}`.trim() : ''}
                    type="text"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                  />
                </div>

                <div className="form-group mb-2">
                  <label className="mont-font fw-600 font-xsss">
                    Membership No
                  </label>
                  <input
                    required
                    name="membership_number"
                    defaultValue={user?.user?.MembershipNumber}
                    type="text"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                  />
                </div>
                <div className="form-group mb-2">
                  <label className="mont-font fw-600 font-xsss">Email</label>
                  <input
                    required
                    defaultValue={user?.user?.email}
                    name="email_address"
                    type="email"
                    className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                  />
                </div>
                <div className="form-group mb-2">
                  <label className="mont-font fw-600 font-xsss">
                    Where is the issue?
                  </label>
                  <select
                    required
                    name="issue"
                    defaultValue={"Other"}
                    className="style2-select ps-3 form-control text-grey-900 font-xsss fw-600"
                  >
                    <option value="Membership">Membership</option>
                    <option value="Entry">Entry</option>
                    <option value="Paying">Paying</option>
                    <option value="Social Media Wall">Social Media Wall</option>
                    <option value="Locality Guide">Locality Guide</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">
                    Description
                  </label>
                  <textarea
                    required
                    name="description"
                    className="form-control mb-0 p-3 h200 lh-16"
                    rows={15}
                    placeholder="Required* - Please describe your problem"
                  />
                </div>
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">
                    Please describe your problem, and we will respond as soon as
                    possible. Before sending, have you looked in the Help Videos section under CONTACT US?
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group mt-5 mb-1 w200">
              <button
                type="submit"
                className="form-control float-right text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0 "
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
        <div class="card-body d-flex align-items-center p-0">
          <div className="col-xs-12">
            <div class="mb-4">
              <i class="fa-sharp fa-solid fa-file font-xl mr-11"></i>
              <span class="page-title ng-scope">FAQ: Troubleshooting</span>
            </div>
            <h2 translate="FAQ_TRB_Q1">
              <b>Q.</b> I have tried resetting my password and it will not send
              me an email.
            </h2>
            <p>
              <span translate="FAQ_TRB_A1_P1">
                A. If you just can't remember your password, then you will have
                to reset it via
              </span>{" "}
              <Link
                to="/forgot-password"
              >
                Forgot Password
              </Link>
            </p>
            <p translate="FAQ_TRB_A1_P2">
              Many people who reset their password claim they never get the
              e-mail which carries the new password. The reason in the
              overwhelming majority of cases is that the automatically sent
              notification simply went into their Junk Folder or was consumed by
              a spam filter. It's a very good idea to make sure the domain
              icompetenatural.com is included on your 'white list' or your
              'permitted list' in your e-mail software program, so that you
              never miss a notification from us again.
            </p>
            <h2 translate="FAQ_TRB_Q2">
              <b>Q.</b> I'm on a Mac and I can't seem to pay for my membership,
              what can I do?
            </h2>
            <p translate="FAQ_TRB_A2">
              A. Try Google Chrome, this works very well. We haven't had any
              problem with the browser for either Mac or Windows, it's a good
              idea to make sure you apply all operating system (O/S) updates
              when they are released.
            </p>
            <h2 translate="FAQ_TRB_Q3">
              <b>Q.</b> I tried to pay last night and hit my submit button three
              times, will I be charged three lots of money?
            </h2>
            <p translate="FAQ_TRB_A3">
              A. That is not possible on iCompete which is hard coded to refuse
              multiple payments, therefore if you did keep hitting the submit
              button, the third party financial institution like PayPal for
              example would take preventative action, it would never get to us
              in the first place.
            </p>
            <h2 translate="FAQ_TRB_Q4">
              <b>Q.</b> I keep going around in a cycle, what's wrong?
            </h2>
            <p translate="FAQ_TRB_A4_P1">
              A. It sounds like you're in a 'cache trap' and the best way to
              resolve that is via a 'Hard Refresh' which varies according to the
              system you're using, here is how to do the most popular systems:
            </p>
            <p translate="FAQ_TRB_A4_P2">
              Internet Explorer:
              <br /> Hold the Ctrl key and press the F5 key. Or, hold the Ctrl
              key and click the Refresh button.
            </p>
            <p translate="FAQ_TRB_A4_P3">
              Safari:
              <br /> Hold down ⇧ Shift and click the Reload button on the
              toolbar.
            </p>
            <p translate="FAQ_TRB_A4_P4">
              Windows/Linux:
              <br /> Hold the Ctrl key and press the F5 key. Or, hold down Ctrl
              and ⇧ Shift and then press R.
            </p>
            <p translate="FAQ_TRB_A4_P5">
              Mac:
              <br /> Hold down the ⇧ Shift and click the Reload button. Or, hold
              down ⌘ Cmd and ⇧ Shift and then press R.
            </p>
            <h2 translate="FAQ_TRB_Q5">
              <b>Q.</b> I just can't pay, it crashed the first time and I'm
              going around in circles?
            </h2>
            <p translate="FAQ_TRB_A5">
              A. Okay, this happens to a few people and basically PayPal require
              a very fast response and if your system sends your data but it's
              not quick enough, the page simply crashes. Once you've crashed a
              copy of the crashed page (with any error messages) is stored
              inside your computer in an area called cache. There are ways to
              clear your cache and there is also a 'Hard Refresh' However
              sometimes that still doesn't help, so one of the best fixes for
              almost everything, is a simple reboot of your system, just turn it
              off, wait a few seconds and turn it back on and then try again. If
              that doesn't help, as much as you won't like it, try another
              computer because each computer is set up differently with drivers,
              apps, programs and so on. Almost universally switching to a
              different computer or device will work instantly.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
