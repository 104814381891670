const BusinessTypes = ({ businessTypes, selected, setSelected }) => {

  const toggle = () => {
    if (selected?.length) {
      setSelected([])
    } else {
      setSelected(businessTypes.map(({ id }) => id))
    }
  }

  return (
    <div class="row ps-2 pe-2 custom-box">
      <div className="mt-3 font-xss bold">
        <i className="fa-solid fa-bars mr-1"></i>
        <span className="text-black">Choose a category or </span>
        <span className="text-blue cursor-pointer"
          onClick={toggle}
        >
          {selected?.length ? 'Unselect' : 'Select'} All
        </span>
      </div>
      {businessTypes?.map(({ id, icon, description }) => {
        const isSelected = selected.includes(id);
        return (
          <div
            key={`business-types-${id}`}
            onClick={() =>
              isSelected
                ? setSelected([...selected.filter((e) => e !== id)])
                : setSelected([...selected, id])
            }
            className={`col mb-4 mt-4 cursor-pointer text-center menu-item ${isSelected ? "selected" : ""}`}
          >
            <img
              className={`business-types-img`}
              src={icon}
              title={description}
            />
            <div class="font-xsss">{description}</div>
            <div class="checkbox"></div>
          </div>
        );
      })}
    </div>
  );
};

export default BusinessTypes;
