const PrivacyPolicy = () => {
  return (
    <div className="content-page">
      <div className="mb-4">
        <i class="fa-sharp fa-solid fa-file font-xl mr-11" />
        <span className="page-title ng-scope">Privacy policy</span>
      </div>
      <p>
        Your privacy is important to us. To the best of our ability, we are
        committed to providing you with a safe, secure environment.
      </p>
      <h3>Information collected</h3>
      <p>
        This is only collected if you choose to provide it. Your membership is
        not reliant on email verification. If you choose to provide an email
        address, you can update it any time.
      </p>
      <p>
        iCompete Natural Network does not pass on member email addresses to
        anyone.
      </p>
      <p>
        If you do provide your email address, it will be stored in our database.
        Your email address is used for sending; notification of new messages,
        your membership and password information if you request it, renewal
        reminders when your subscription/membership account is due to expire and
        subscription promotions and/or technical support. iCompete Natural
        Network may from time to time send critical updates to members by email
        address. As per our Terms, iCompete Natural may from time to time send
        marketing material to members by email address.
      </p>
      <h3>Profile Information</h3>
      <p>
        We collect the information you provide for your member profile in our
        database. These details may be viewed by others accessing this site or
        iCompete Natural Network, but are not passed on to anyone else. A
        marketing report may be provided to advertisers on request containing
        general demographic information. This report does not contain any
        specific information relating to individual members. Profiles are
        checked and removed if they are in violation of the Terms and Conditions
        or the Profile Guidelines.
      </p>
      <h3>Communications</h3>
      <p>
        Communications with other members are passed on to those members
        automatically. They are also stored for a limited period (3 months) on
        our backup systems so you can view your conversation history, 'view
        history' with another member. Messages will still be visible in view
        history for a period of three months even if you have deleted them. An
        individual member may add you to her list of friends and saved and make
        notes to help her remember her communication with you. These notes are
        only ever visible to that individual member. We do not monitor or look
        at these notes or message communications in ordinary circumstances.
      </p>
      <h3>Publicly Viewable Content</h3>
      <p>
        Content you post on website may be viewable by non-members and may be
        returned in search engine results.
      </p>
      <p>
        If you upload a photo your photo and profile content may be viewable by
        non-members and may be returned in search engine results.
      </p>
      <p>
        Non-personally identifiable information, such as your IP address,
        browser type, pages visited etc. None of this information identifies you
        personally. This is aggregated and used to help us improve our service
        and infrastructure.
      </p>
      <p>
        Other personal information provided voluntarily by you. This could be in
        response to surveys, contests or other online activities and may be kept
        for a period of time within the database.
      </p>
      <p>
        Emailed comments and/or opinions of other members or site visitors about
        you. These may include testimonials, general information or complaints.
        Complaints are investigated in accordance with our complaints procedure.
        The record of the complaint may be stored. This information is not
        passed on to anyone unless required by law.
      </p>
      <h3>Credit Card Information</h3>
      <p>
        We collect credit card information using a secure payment page. Your
        credit card details are encrypted using 128 bit encryption and sent to
        the bank via the payment gateway. If you pay by credit card via phone or
        fax, we record your details, verify them, and then dispose of them.
        Records are not kept.
      </p>
      <h3>Refund and Cancellation Policy:</h3>
      <p>
        Membership refunds only apply to people who have not competed yet. Once
        you compete there is no refund. You must make a written application to
        admin@icompetenatural.com stating the reason why you will be unable to
        compete during your 12 month membership.
      </p>
      <h3>Cookies</h3>
      <p>
        A cookie is a small piece of data that is sent to your browser from the
        site you are visiting and stored on your computer's hard drive. Cookies
        are very common and completely normal throughout the Internet. Cookies
        do not harm your computer nor can they access information stored on your
        computer. The primary purpose of cookies is to maintain your customized
        settings on a site. The site login process and all member functionality
        requires that your browser accept cookies. We recommend closing browsers
        on shared computers after use.
      </p>
      <h3>Disclosure of Your Information</h3>
      <p>
        We will not disclose any of your information without your permission.
      </p>
      <p>We will not supply any member's details to any other member.</p>
      <p>
        We will only disclose your information if legally obligated to do so by
        a statutory authority with the appropriate jurisdiction.
      </p>
      <h3>Disclosure of Your Information</h3>
      <p>
        We do not rent, sell, or share personal information about you with any
        third party.
      </p>
      <h3>Member Communications</h3>
      <p>
        Communications between two members will be released to either member on
        request. They will not be released to third parties.
      </p>
      <h3>Deleting Your Information</h3>
      <p>
        You can delete your content by clicking "Delete" button on that
        individual post.
      </p>
      <h3>Complaints Process and Privacy</h3>
      <p>
        When a complaint about a member is received, a record of the complaint
        is made and included in the data held about that member. Depending on
        the severity of the complaint, a member may have their membership
        account placed on hold pending an investigation.
      </p>
      <p>
        We will advise the member that a complaint has been received and what
        the complaint is in relation to. However, we will not tell the member
        who made the complaint without permission. The member will receive a
        right of reply.
      </p>
      <p>
        Once the complaint is investigated, the member may receive a warning, be
        suspended for a period of time as decided by iCompete Natural, or be
        removed from the site. The Member will be informed via email as to our
        decision and our decision is final.
      </p>
      <h3>Harassment</h3>
      <p>
        We may provide the relevant authorities with evidence of harassment, or
        if a member continues to violate the Terms and Conditions of the site
        and disrupt this service.
      </p>
      <h3>Security</h3>
      <p>
        We do our best to maintain the security of the site and member
        information stored within the database. However no system can ever be
        considered 100% secure. If a security breach occurs we will endeavour to
        fix it as soon as possible. However we cannot be held responsible in the
        unlikely event of a security breach.
      </p>
      <p>
        iCompete Natural reserves the right to change this privacy policy from
        time to time.
      </p>
      <h3>Log Files</h3>
      <p>
        As with most other websites, we collect and use the data contained in
        log files. The information in the log files include your IP (internet
        protocol) address, your ISP (internet service provider, such as AOL or
        Shaw Cable), the browser you used to visit our site (such as Internet
        Explorer or Firefox), the time you visited our site and which pages you
        visited throughout our site.
      </p>
      <h3>Cookies and Web Beacons</h3>
      <p>
        We do use cookies to store information, such as your personal
        preferences when you visit our site. This could include only showing you
        a popup once in your visit, or the ability to login to some of our
        features, such as forums.
      </p>
      <p>
        We also use third party advertisements on INBA to support our site. Some
        of these advertisers may use technology such as cookies and web beacons
        when they advertise on our site, which will also send these advertisers
        (such as Google through the Google AdSense program) information
        including your IP address, your ISP , the browser you used to visit our
        site, and in some cases, whether you have Flash installed. This is
        generally used for geotargeting purposes (showing Adelaide real estate
        ads to someone in Adelaide, for example) or showing certain ads based on
        specific sites visited (such as showing cooking ads to someone who
        frequents cooking sites).
      </p>
      <h3>DoubleClick DART cookies</h3>
      <p>
        We also may use DART cookies for ad serving through Google's
        DoubleClick, which places a cookie on your computer when you are
        browsing the web and visit a site using DoubleClick advertising
        (including some Google AdSense advertisements). This cookie is used to
        serve ads specific to you and your interests ("interest based
        targeting").
      </p>
      <p>
        The ads served will be targeted based on your previous browsing history
        (For example, if you have been viewing sites about visiting Melbourne,
        you may see Melbourne hotel advertisements when viewing a non-related
        site, such as on a site about hockey). DART uses "non-personally
        identifiable information". It does NOT track personal information about
        you, such as your name, email address, physical address, telephone
        number, social security numbers, bank account numbers or credit card
        numbers.
      </p>
      <p>
        You can opt-out of this ad serving on all sites using this advertising
        by visiting&nbsp;http://www.doubleclick.com/privacy/dart_adserving.aspx
      </p>
      <p>
        You can choose to disable or selectively turn off our cookies or
        third-party cookies in your browser settings, or by managing preferences
        in programs such as Norton Internet Security. However, this can affect
        how you are able to interact with our site as well as other websites.
        This could include the inability to login to services or programs, such
        as logging into forums or accounts.
      </p>
      <p>
        Deleting cookies does not mean you are permanently opted out of any
        advertising program. Unless you have settings that disallow cookies, the
        next time you visit a site running the advertisements, a new cookie will
        be added.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
