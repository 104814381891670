import React from "react";
import { Confirmation } from "../";
import { useAuth } from "../../context/Auth";

export const LogoutModal = () => {
  const { removeUser } = useAuth();

  return (
    <Confirmation
      btn={
        <>
          <a className="p-2 text-center ms-3 cursor-pointer hidden-lg">
            <i className="fa-solid fa-power-off font-md text-red"></i>
          </a>
          <div className="show-lg mt-2 pt-2 mb-2" style={{ margin: '0 10px -19px' }}>
            <button className="bg-gray-gradiant text-black border-0 px-5 py-2 bold border" style={{ width: '100%', display: 'block', borderRadius: 10 }}>Logout</button>
          </div>
        </>
      }
      title="Are you sure you want to logout?"
      yesText="Yes"
      noText="No"
      yesClick={removeUser}
    />
  );
};
