import React, { useEffect, useRef } from 'react';

const Translate = () => {
    const translateRef = useRef(null);

    useEffect(() => {
        const googleTranslateElementInit = () => {
            if (window.google && window.google.translate) {
                new window.google.translate.TranslateElement({
                    pageLanguage: 'en',
                    includedLanguages: 'en,ar,zh-CN,cs,nl,fr,hi,hu,id,it,ja,ko,mn,pl,es,sk,tl,uk,vi',
                    layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                    autoDisplay: false
                }, translateRef.current);

                // Prevent default behavior for anchor tags within the translate element
                const anchors = translateRef.current.querySelectorAll('a');
                anchors.forEach(anchor => {
                    anchor.addEventListener('click', (e) => {
                        e.preventDefault();
                    });
                });
            }
        };

        window.googleTranslateElementInit = googleTranslateElementInit;

        if (!document.querySelector('script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
            const script = document.createElement('script');
            script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        } else {
            googleTranslateElementInit();
        }
    }, []);

    return <div ref={translateRef} id="google_translate_element"></div>;
};

export default Translate;
