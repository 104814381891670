import React, { useState } from "react";
import { Modal } from "..";
import { SignUp } from "./signup";
import { Login } from "./login";
import { useAuth } from "../../context/Auth";
import { ForgotPassword } from "./forgotPassword";

export const AuthModal = ({ show }) => {
  const { hideLoginModal } = useAuth();

  const [showingLogin, changeForm] = useState(true);
  const [forgotPass, setForgotPass] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const resetModalState = () => {
    changeForm(true);
    setForgotPass(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleLoginClose = () => {
    hideLoginModal();
    resetModalState();
  };

  const handleSignUpClose = () => {
    hideLoginModal();
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
    <Modal show={show} close={handleLoginClose}>
      {forgotPass ? (
        <ForgotPassword setForgotPass={setForgotPass} />
      ) : showingLogin ? (
        <Login key={resetKey} changeForm={changeForm} setForgotPass={setForgotPass} handleClose={handleLoginClose} />
      ) : (
        <SignUp key={resetKey} changeForm={changeForm} handleClose={handleSignUpClose} />
      )}
    </Modal>
  );
};
