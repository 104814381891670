import { isArray } from "lodash";
import React from "react";

export const Badges = ({
  premium,
  professional,
  represent_country,
  vip,
  pro_qualified,
  contest_total_display,
  proIn
}) => {
  return (
    <>
      {premium ? <span className="premium-badge">Premium</span> : null}
      {contest_total_display ? (
        <span className="badge-wrapper">
          <span className="contests-number-badge">{contest_total_display}</span>
        </span>
      ) : null}
      {represent_country === "yes" ? (
        <span className="badge-wrapper">
          <span className="representing-badge"></span>
        </span>
      ) : null}
      {pro_qualified === "yes" ? (
        <span class="badge-wrapper">
          <span class="pq-badge"></span>
        </span>
      ) : null}
      {vip === "yes" ? (
        <span className="badge-wrapper" style={{ width: 35 }}>
          <span className="vip-badge"></span>
        </span>
      ) : null}
      {professional === "yes" ? (
        <span className="badge-wrapper">
          <span className="professional-badge"></span>
        </span>
      ) : null}
      {proIn && isArray(proIn) && proIn?.length ? <> <b>in</b>{proIn?.map((t) => <small className="pro-in">{t}</small>)} </> : null}
    </>
  );
};
