const CodeOfEthics = () => {
  return (
    <>
      <div className="content-page">
        <div className="mb-4">
          <i class="fa-sharp fa-solid fa-file font-xl mr-11" />
          <span className="page-title ng-scope">Code of ethics</span>
        </div>
        <p>
          iCompete Natural has come up with a code of ethics for our members.
          Please agree to and abide by these to ensure you have a fantastic
          experience on iCompete Natural.
        </p>
        <br />
        <ol>
          <li>
            I will treat all iCompete Natural members with dignity and respect.
          </li>
          <li>
            I will keep confidential all information given to me in private and
            I will not give it to anyone else without the permission of the
            person who provided it to me.
          </li>
          <li>
            The information I provide about me will be true in all respects,
            including age and relationship status.
          </li>
          <li>My communications with all members will be honest.</li>
          <li>
            I will not include my personal contact details in any publicly
            viewable area of the site (with the exception of classifieds as per
            guidelines).
          </li>
          <li>
            I will not give anyone my password or allow them to log into my
            account.
          </li>
          <li>
            I will not use iCompete Natural for sending spam, advertising or
            commercial communications to members, or for any commercial
            purposes.
          </li>
          <li>
            I will not post images containing pornography, violence, abuse of
            any type, hate or material considered unsuitable for a person 15
            years of age to view.
          </li>
          <li>
            I will report breaches of the code of ethics or iCompete Naturals
            terms and conditions to iCompete Natural.
          </li>
        </ol>
        <br /> <h3>Please Note:</h3>
        <p>
          It is a condition of use that you agree to this code of ethics.
          Breaching either our code of ethics or our terms of use may result in
          a number of sanctions from warnings, loss of certain privileges, to
          removal of membership or even prosecution.
        </p>
      </div>
    </>
  );
};

export default CodeOfEthics;
