import { InfoWindow, Map, Marker } from "google-maps-react";
import { useState } from "react";
import { validateUrl } from "../../utils/utils";
import "./style.scss";

const google = window.google;

const MyMap = ({ businesses, address }) => {
  const [activeMarker, setActiveMarker] = useState({
    marker: null,
    data: null,
    showing: false,
  });

  const handleActiveMarker = (props, marker) => {
    setActiveMarker({
      marker,
      data: businesses?.find(({ user_id }) => user_id == props?.id),
      showing: true,
    });
  };

  let bounds = new google.maps.LatLngBounds();
  if (businesses.length) {
    for (let i = 0; i < businesses.length; i++) {
      bounds.extend({
        lat: parseFloat(businesses[i].latitude),
        lng: parseFloat(businesses[i].longitude),
      });
    }
  } else {
    bounds.extend({
      lat: address?.latitude || 28.466866,
      lng: address?.longitude || 77.06270950000001,
    });
  }

  return (
    <Map style={{ width: "100%" }} google={google} zoom={4} bounds={bounds}>
      {businesses?.map(({ user_id, latitude, longitude }) => {
        return (
          <Marker
            // onClick={onMarkerClick}
            id={user_id}
            key={`marker-${user_id}`}
            position={{
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            }}
            onClick={handleActiveMarker}
          />
        );
      })}

      <InfoWindow
        marker={activeMarker?.marker}
        // onClose={this.onInfoWindowClose}
        visible={activeMarker?.showing}
      >
        <div className="card-body d-block w-100 p-2 text-center">
          <div className="clearfix" />
          <h4 className="fw-700 font-xss mt-3 mb-0">
            {activeMarker?.data?.business_name}
          </h4>
          <p className="fw-500 font-xssss text-grey-500 mt-2 mb-3 map-info-window-address">
            {activeMarker?.data?.street_address_line1}
          </p>
          {activeMarker?.data?.email ? (
            <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
              {activeMarker?.data?.email}
            </p>
          ) : null}

          {activeMarker?.data?.website ? (
            <a
              href={validateUrl(activeMarker?.data?.website)}
              target="_blank"
              className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            >
              Website
            </a>
          ) : null}
        </div>
      </InfoWindow>
    </Map>
  );
};

export default MyMap;
