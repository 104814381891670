import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../context/Auth";

const EventPaymentSuccess = () => {
  const { eventName } = useParams();
  const { user } = useAuth();

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 ">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 align-item-center d-flex">
            <i class="fa-sharp fa-solid fa-circle-check font-xxl mr-11 text-share" />
            EVENT ENTRY CONFIRMATION
          </h2>
        </div>
      </div>

      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 membership-payment-success">
        <div class="card-body align-items-center p-0">
          <p>
            Dear <strong>{user?.user?.first_name}</strong>,
          </p>
          <p>
            Entry Fee payment confirmed{" "}
            {eventName ? `for ${eventName} contest` : ""}. You can view your
            Entry details any time in <strong>Events</strong> under{" "}
            <strong>
              <Link to="/view-events">View Events</Link>
            </strong>
          </p>
          <p className="text-red">PLEASE READ THESE 5 POINTS:</p>

          <ul style={{ margin: "20px 50px" }}>
            <li style={{ listStyle: "disc inside" }}>
              Your Entry is paid and now locked and made "Bullet proof" so it
              can not be lost or destroyed
            </li>
            <li style={{ listStyle: "disc inside" }}>
              This means it can not be changed or edited
            </li>
            <li style={{ listStyle: "disc inside" }}>
              Your Entry is now only Viewable in <strong>Events</strong> under{" "}
              <strong>
                <Link to="/view-events">View Events</Link>
              </strong>
            </li>
            <li style={{ listStyle: "disc inside" }}>
              Your entry has been removed from <strong>Edit Entry</strong>{" "}
              (because it can not be edited) and removed from{" "}
              <strong>Pay</strong> (because it has been paid)
            </li>
            <li style={{ listStyle: "disc inside" }}>
              The invoice for your paid Entry Fees can be downloaded in{" "}
              <strong>
                <Link to="/invoices">Invoices</Link>
              </strong>{" "}
              under <strong>Events</strong>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EventPaymentSuccess;
