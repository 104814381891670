import { useParams } from "react-router-dom";
import { FeedProvider } from "../../context/Feed";
import SingePostMain from "./post";

const SingePost = () => {
  const params = useParams()
  return (
    <>
      <FeedProvider singleFeedId={params?.postId}>
        <SingePostMain />
      </FeedProvider>
    </>
  );
};

export default SingePost;
