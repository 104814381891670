import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Input,
  TextArea,
} from "semantic-ui-react";
import { useCallback, useEffect, useState } from "react";
import { useCommunicator } from "../../context/Communicator";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useAuth } from "../../context/Auth";
import { Confirmation } from "../../components";

const initialFormData = {
  platform: 2,
  message: "",
  template: "",
  testMode: "",
  numbers: "",
};
const SmsTab = () => {
  const [count, setCount] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [messages, setMessages] = useState();
  const [jobSummary, setJobSummary] = useState();
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const {
    smsTemplatesOptions,
    sendSMS,
    sendTestSMS,
    filteredFormData,
    recepientCount,
    fetchPreviousSmsCommunications,
    fetchCurrentJobSummary,
  } = useCommunicator();
  const [displayContent, setDisplayContent] = useState("");

  const getPreviousSmsCommunications = useCallback(async () => {
    try {
      const result = await fetchPreviousSmsCommunications();
      setMessages(result.result.data);
    } catch (error) { }
  }, [fetchPreviousSmsCommunications]);

  const getCurrentJobSummary = useCallback(async () => {
    try {
      const result = await fetchCurrentJobSummary();
      setJobSummary(result.result.data);
    } catch (error) { }
  }, [fetchCurrentJobSummary]);

  useEffect(() => {
    getPreviousSmsCommunications();
    getCurrentJobSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisplayContent(
      formData.message
        .replaceAll("{recipient_name}", "John Doe")
        .replaceAll("{company}", "iComplete")
    );
  }, [formData.message]);

  const handleChange = (e, d) => {
    setFormData((prev) => ({
      ...prev,
      [d.name]: d.value,
    }));

    if (d.name === "message") {
      setCount(trim(d.value).length);
    }

    if (d.name === "template") {
      let message = "";

      switch (d.value) {
        case 2:
          message = `Hi, \nINBA`;
          break;
        case 3:
          message = `Hi {recipient_name}, \n{company}`;
          break;
        default:
          message = "";
          break;
      }
      setFormData((prev) => ({
        ...prev,
        message,
      }));
    }
  };
  const handleToggle = (e, d) => {
    setFormData((prev) => ({
      ...prev,
      [d.name]: d.checked ? "1" : "0",
    }));
  };

  const { showToast } = useAuth();

  const handleSubmit = async () => {
    if (!formData?.message) {
      return showToast({
        text: 'Please add message body',
        type: "error",
      });
    }
    setSendEmailLoading(true);
    try {
      const payload = { ...formData, ...filteredFormData };
      const apiMessage = formData.testMode ? sendTestSMS : sendSMS;
      await apiMessage(
        formData.testMode
          ? {
            numbers: [...formData.numbers.split(",")],
            message: formData.message,
          }
          : payload
      );
      setFormData((prev) => ({
        ...prev,
        message: "",
      }));
      setSendEmailLoading(false);
    } catch (err) {
      setSendEmailLoading(false);
    }
  };

  return (
    <div className="communicator__sms-tab">
      <Form>
        <Grid>
          <Grid.Column width={8} className="sms-tab__left">
            <h3>Compose</h3>
            <Form.Field>
              <Grid>
                <Grid.Column width={8}>
                  <Dropdown
                    placeholder="Empty Template"
                    fluid
                    search
                    selection
                    options={smsTemplatesOptions}
                    clearable
                    name={"template"}
                    value={formData.template}
                    onChange={handleChange}
                  />
                </Grid.Column>
              </Grid>
            </Form.Field>
            <Form.Field>
              <label>Compose Message</label>
              <TextArea
                className="notranslate"
                rows={7}
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <div className="text-right">
                <span>
                  {count} character(s)
                  {/* / 1 sms */}
                </span>
              </div>
            </Form.Field>

            <Form.Field>
              <label>Current Job Summary</label>
              <div className="communicator__list-block">
                {jobSummary && (
                  <ul>
                    {jobSummary.map((sumamry) => {
                      return (
                        <li key={`message-${sumamry.id}`}>
                          <span>Number of recepient:</span>
                          <span className="list-block__count">
                            {sumamry.id}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {isEmpty(jobSummary) && <em>No Job Summary available</em>}
              </div>
            </Form.Field>

            <Form.Field>
              <label>Previous SMS communications</label>
              <div className="communicator__list-block">
                {messages && (
                  <ul>
                    {messages.map((message) => {
                      return (
                        <li key={`message-${message.id}`}>
                          <div>
                            <p>{message.content}</p>
                            <span className="list-block__date">
                              {moment(message.created_at).format(
                                "DD MMM YYYY @ hh:mm"
                              )}
                            </span>
                          </div>
                          <span className="list-block__count">
                            {message.seen}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {isEmpty(messages) && <em>No messages available</em>}
              </div>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <h3>Preview and Send</h3>
            <div className="communicator__preview-block">
              <div className="mobile-preview">
                <div className="preview">
                  <div className="screen notranslate">
                    <div className="messages-area">
                      <div className="content-top">
                        <span>iCompete</span>
                      </div>
                      <div className="content-middle">
                        <div className="previous-message-part">
                          <span>"iCompete"</span>
                          <span className="link">1111 222 333</span>
                        </div>
                        <div className="to-grow">
                          <div className="top-border"></div>
                          <div className="side-borders">{displayContent}</div>
                          <div className="bottom-border"></div>
                        </div>
                      </div>
                      <div className="content-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-field">
                <Form.Field
                  control={Checkbox}
                  label="Email test mode"
                  name="testMode"
                  value={formData.testMode === "1"}
                  onChange={handleToggle}
                />
              </div>
              <div className="form-field">
                <Input
                  placeholder="Mobile number for test"
                  fluid
                  name="numbers"
                  value={formData.numbers}
                  onChange={handleChange}
                  disabled={!formData.testMode || (formData.testMode === "0" && formData.numbers)}
                />
              </div>

              {sendEmailLoading || !recepientCount || !formData?.message ? (
                <Button
                  type="submit"
                  className="btn-submit"
                  fluid
                  color="red"
                  loading={sendEmailLoading}
                  disabled
                >
                  Send Message
                </Button>
              ) : (
                <Confirmation
                  btn={
                    <Button
                      type="submit"
                      className="btn-submit"
                      fluid
                      color="red"
                      loading={sendEmailLoading}
                    >
                      Send Message
                    </Button>
                  }
                  title={`Are you sure you want to send SMS to ${recepientCount} user(s)`}
                  yesText="Yes Send"
                  noText="No"
                  yesClick={handleSubmit}
                />
              )}

            </div>
          </Grid.Column>
        </Grid>
      </Form>
    </div>
  );
};

export default SmsTab;
