import React from "react";
import Modal from "./modal";

export const Loader = () => {
  return (
    <Modal
      id="loading"
      show={true}
      style={{ minHeight: 0, minWidth: 0, padding: 10 }}
    >
      <img src="./images/preloader.svg" />
    </Modal>
  );
};
