import { useEffect, useState } from "react";
import Moment from "react-moment";
import { API, endpoints } from "../api";
import Content from "../components/content";
import { ImageModal } from "../components/image-modal";
import { ViewEntryModal } from "../components/view-entry";
import { useAuth } from "../context/Auth";
import { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";

const ViewEvents = () => {
  const { showToast } = useAuth();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState([]);

  const getData = () => {
    setLoading(true);
    API.get({ route: `${endpoints.EVENT_ENROL}?all=1` })
      .then((data) => {
        setEvents(data?.result);
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return <Content
    title="View Entry"
    loading={loading}
    icon="fa-solid fa-magnifying-glass"
  >
    {!events?.length && !loading ? <p className="font-xs">No Data to show</p> : null}
    {events?.length ? <table className="custom-table">
      <tr>
        <th>Events</th>
        <th className="hidden-sm">Contest Date</th>
        <th className="hidden-sm">Poster</th>
        <th>Info Pak</th>
      </tr>
      {events?.map(({ id, events }) => (
        <tr>
          <td>
            {events?.eventTitle} <br />
            <div className="show-sm">
              <strong>Contest Date:</strong> <Moment format="DD MMM YYYY">{events?.startTime}</Moment>
            </div>
            <ViewEntryModal id={id} />
            <div className="show-sm">
              {events?.posterImage ? (
                <ImageModal
                  img={events?.posterImage}
                  btn={
                    <div className="cursor-pointer">
                      <a className="d-block bg-gray rounded-xl text-center text-black px-2 py-1 font-xsss w100 mt-2">View Image</a>
                    </div>
                  }
                />
              ) : null}
            </div>
          </td>
          <td className="hidden-sm">
            <Moment format="DD MMM YYYY">{events?.startTime}</Moment>
          </td>
          <td className="hidden-sm">
            {events?.posterImage ? (
              <ImageModal
                img={events?.posterImage}
                btn={
                  <div className="cursor-pointer">
                    <a className="d-block bg-gray rounded-xl text-center text-black px-2 py-1 font-xsss w100">View Image</a>
                  </div>
                }
              />
            ) : null}
          </td>
          <td>
            {events?.posterImage ? (
              <a
                href={events?.posterImage}
                target="_blank"
              >
                <i className="fa fa-download" />
              </a>
            ) : null}
          </td>
        </tr>
      ))}
    </table> : null}
  </Content>
};

export default ViewEvents;
