import React, { useEffect, useState } from "react";
const google = window.google;
const LocationPicker = ({
  setAddress,
  isSubmitted,
  customIconStyle,
  canShowFullForm = true,
  showLabel = false,
  data = {
    latitude: null,
    longitude: null,
    postal_address1: "",
    postal_address2: "",
    suburb_town: "",
    state: "",
    state_code: "",
    country: "",
    country_code: "",
    postcode: "",
  },
}) => {
  const [state, setState] = useState(data);

  let autocomplete = "";
  useEffect(() => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    autocomplete.addListener("place_changed", handlePlaceSelect);
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    setAddress({ ...state, [event.target.name]: event.target.value });
  };

  const handlePlaceSelect = () => {
    let place = autocomplete?.getPlace();
    if (!place) return;
    const address = {
      latitude: place?.geometry?.location?.lat(),
      longitude: place?.geometry?.location?.lng(),
    };

    address.postal_address1 = place?.formatted_address;

    let homeAddress = "";

    if (!place?.address_components) return;
    for (const component of place?.address_components) {
      const componentType = component?.types[0];

      if (!componentType) return;
      switch (componentType) {
        case "street_number": {
          homeAddress = `${component.long_name}`;
          break;
        }
        case "route": {
          if (homeAddress) homeAddress += " ";
          homeAddress += component.long_name;
          break;
        }
        case "locality": {
          address.suburb_town = component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          address.state = component.long_name;
          address.state_code = component.short_name;
          break;
        }
        case "country": {
          address.country = component.long_name;
          address.country_code = component.short_name;
          break;
        }
        case "postal_code": {
          address.postcode = `${component.long_name}`;
          break;
        }
      }
    }

    address.postal_address2 = homeAddress;

    setState({ ...address });
    setAddress({ ...address });
  };

  return (
    <div className="pb-3">
      <div className="form-group icon-input mb-3">
        {showLabel ? (
          <label className="mont-font fw-600 font-xsss">Address</label>
        ) : null}
        <i className="font fa fa-map-marker text-grey-500 pe-0" style={customIconStyle} />
        <input
          id="autocomplete"
          defaultValue={state?.postal_address1}
          required
          type="text"
          className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600
          ${isSubmitted && !state?.postal_address1 ? 'red-input' : ''}`}
          placeholder="Find your address here ..."
          onChange={(e) => {
            setAddress({})
            setState({});
          }}
        />
      </div>

      {state?.country ? (
        <>
          {" "}
          {/* <div className="form-group mb-3">
            <label className="mont-font fw-600 font-xsss">
              Postal address line 1
            </label>
            <input
              name="postal_address1"
              value={state?.postal_address1}
              onChange={handleChange}
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
              placeholder="Find your address here ..."
            />
          </div> */}
          {/* <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Postal address</label>
            <input
              name="postal_address2"
              onChange={handleChange}
              value={state?.postal_address2}
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
              placeholder="Find your address here ..."
            />
          </div> */}
          {canShowFullForm ? (
            <>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss">Town</label>
                    <input
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      value={state?.suburb_town}
                      type="text"
                      className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Find your address here ..."
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss">State</label>
                    <input
                      value={`${state?.state}${state?.state_code && ` (${state?.state_code})`
                        }`}
                      type="text"
                      className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Find your address here ..."
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss">
                      Country
                    </label>
                    <input
                      value={`${state?.country}${state?.country_code && ` (${state?.country_code})`
                        }`}
                      type="text"
                      className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Find your address here ..."
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="mont-font fw-600 font-xsss">
                      Post Code
                    </label>
                    <input
                      value={state?.postcode}
                      type="text"
                      className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Find your address here ..."
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default LocationPicker;
