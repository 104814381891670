const MyEvent = () => {
  return (
    <>
      <div className="content-page">
        <div className="mb-4">
          <i class="fa-sharp fa-solid fa-file font-xl mr-11" />
          <span className="page-title ng-scope">FAQ: My Events</span>
        </div>
        <div class="widget-main">
          <div class="row">
            <div class="col-xs-12">
              <h3 translate="FAQ_EVENTS_Q01" class="ng-scope">
                <b>Q.</b> Can I compete in Australia? I live in another country.
              </h3>
              <p translate="FAQ_EVENTS_A01" class="ng-scope">
                A. Yes! You may enter any event you see in the International
                dropdown list.
              </p>
              <h3 translate="FAQ_EVENTS_Q02" class="ng-scope">
                <b>Q.</b> Are all event details the same?
              </h3>
              <p translate="FAQ_EVENTS_A02" class="ng-scope">
                No, each event is unique. Divisions offered, times and costs are
                all different for each competition. Each promoter produces an
                'Event Information PDF' which thoroughly itemizes every detail
                and is located in MY RESOURCES or on the national website here:{" "}
                <a
                  target="_blank"
                  href="http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291"
                >
                  http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291
                </a>
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q03" class="ng-scope">
                <b>Q.</b> Where do I find the calendar of events?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A03_P1" class="ng-scope">
                A: To see the calendar of events click on Enter New Event or go
                to:{" "}
                <a
                  target="_blank"
                  href="http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=17&amp;Itemid=293"
                >
                  http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=17&amp;Itemid=293
                </a>
              </p>{" "}
              <p translate="FAQ_EVENTS_A03_P2" class="ng-scope">
                If an event is listed as TBA it means either the exact date or
                venue is still to be advised by the event promoter. Most
                competitions fall at approximately the same time each year
                depending on venue availability. When the event date and details
                are confirmed you will see the event posted on iCompete.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q04" class="ng-scope">
                <b>Q.</b> How do I qualify for a domestic event?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A04" class="ng-scope">
                A: Most events require no additional qualification with the
                exception of National Events. Please see the Event Information
                PDF for all details, if there are any qualification criteria or
                special conditions, it will be clearly described therein. See
                here:{" "}
                <a
                  target="_blank"
                  href="http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291"
                >
                  http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291
                </a>
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q05" class="ng-scope">
                <b>Q.</b> How do I Qualify for the Australian City Nationals or
                Australian Titles?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A05_P1" class="ng-scope">
                A. All competitors must qualify to compete at the City Nationals
                and Australian titles by achieving one of the following:
              </p>{" "}
              <p translate="FAQ_EVENTS_A05_P2" class="ng-scope">
                During the current year:
              </p>{" "}
              <ul>
                {" "}
                <li translate="FAQ_EVENTS_A05_L1" class="ng-scope">
                  Top five in any iCompete contest
                </li>{" "}
                <li translate="FAQ_EVENTS_A05_L2" class="ng-scope">
                  Special invitation from an iCompete State President
                </li>{" "}
              </ul>{" "}
              <p translate="FAQ_EVENTS_A05_P3" class="ng-scope">
                <strong>Exceptions:</strong> All permanent NSW state residents
                are exempt from qualifying for the City National event. The
                qualification criteria for other countries varies widely and you
                must contact the President or Promoter in the country you are
                interested in to obtain the full qualification criteria.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q06" class="ng-scope">
                <b>Q.</b> I am trying to enter a season B event, but I couldn't
                find it on iCompete?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A06" class="ng-scope">
                A. The event promoter will activate the event in iCompete and
                then place an 'Event Information PDF' into the Entry &amp; Event
                Information webpage:{" "}
                <a
                  target="_blank"
                  href="http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291"
                >
                  http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291
                </a>{" "}
                and also in the My Resources section of your iCompete.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q07" class="ng-scope">
                <b>Q.</b> Is the September iCompete event the last competition
                for the year?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A07" class="ng-scope">
                A. No, you can check the contest Calendar{" "}
                <a
                  target="_blank"
                  href="http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=17&amp;Itemid=293"
                >
                  http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=17&amp;Itemid=293
                </a>{" "}
                we generally have events as late as October/November, however to
                be sure check the calendar. International events mean that it's
                now possible for events to be schedule virtually year round.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q08" class="ng-scope">
                <b>Q.</b> What is the Angels Division?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A08" class="ng-scope">
                A. Angels is a fun class unique to iCompete, the costume is
                based on a bikini, (no G Strings) the costume can resemble that
                of an Angel (wings), or a Showgirl Las Vegas type costume,
                feathers and frills, tulle, sequins, diamantes, anything that is
                feminine and over the top that the competitor has always dreamed
                of wearing, glitz, bling, glamour is the key. It is a 'Fun'
                event.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q09" class="ng-scope">
                <b>Q.</b> What does First Timer mean?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A09" class="ng-scope">
                A. First Timer means this is the first time you have ever
                competed onstage. It means this is your first contest in any
                federation and in any division. This applies to all events
                except for National events where you must pre-qualify to be able
                to compete in a National First Timer division.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q10" class="ng-scope">
                <b>Q.</b> What happened to the Ms Physique division?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A10" class="ng-scope">
                A. The Ms Physique division had a name change back to Ms Figure
                in 2013 to differentiate it from Figure (International) which is
                popular internationally. There were no guideline changes it was
                purely a name change.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q11" class="ng-scope">
                <b>Q.</b> What's the difference between Ms Figure and Figure
                (International)?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A11" class="ng-scope">
                A. Ms Figure is based more on muscularity and judged via posing.
                Figure International is based on symmetry and presentation and
                competitors do not pose, rather they perform a stage walk and
                quarter turns.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q12" class="ng-scope">
                <b>Q.</b> As a bikini competitor, what kind of costumes will be
                preferred?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A12" class="ng-scope">
                A. Bikini models required attire is a two-piece fashion bikini.
                There is no restriction on fabric or style. However, avoid the
                'posing costume' typical of Ms Physique and Bodybuilders. A
                Bikini Model will be better served wearing a bikini that
                displays a sense of fashion and style. Competitors also wear
                high heels during their round. A Bikini competitor will deliver
                a combination of these objectives: Beauty overall cosmetic
                appearance, photogenic, skin tone and physical appeal.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q13" class="ng-scope">
                <b>Q.</b> What are the female divisions?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A13" class="ng-scope">
                A. BikiniModel, FitnessModel, SportsModel, SwimwearModel,
                Angels, Figure International, Ms Figure, Bodybuilding.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q14" class="ng-scope">
                <b>Q.</b> What is different between Bikini Model and Fitness
                Model?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A14" class="ng-scope">
                A. Bikini Models have a softer, beach-body compared to the
                Fitness Model Class. Competitors should not have a 6 pack abs.
                Fitness Model should aim to have 6 pack abs.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q15" class="ng-scope">
                <b>Q.</b> What are the male divisions?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A15" class="ng-scope">
                A. Mr. Beachbody, Male Fitness Model, Mr Physique, Bodybuilding.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q16" class="ng-scope">
                <b>Q.</b> Am I a Bikini or Fitness Model?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A16" class="ng-scope">
                A. For female competitors the difference between Bikini and
                Fitness Model may be slight. Critically a female fitness model
                can be harder, best defined as allowed to have and display a six
                pack abdominals. In terms of a female competitor asking "Am I a
                Bikini or Fitness Model?" the answer is not a solely about the
                body. At the extreme of both divisions there is an obvious
                difference between a soft bikini competitor and a developed
                fitness competitor with chiselled abs. However, 80% of
                competitors will have a body that will fit either division, with
                a combination of some developed muscle groups yet softness
                elsewhere. Indeed, up to 2012 both types of competitor were in
                the one division (SportsModel). Therefore, apart from using the
                "six pack" guideline, a competitor can also choose a division
                based on which title, ideals and criteria suits their
                competitive ambitions.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q17" class="ng-scope">
                <b>Q.</b> I've registered as a Bikini Model and want to switch
                to Fitness Model but I can't see Fitness Model in the drop-down
                but I know it's in the contest because it's listed in the Event
                Information PDF, why can't I see it so I can switch?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A17" class="ng-scope">
                A. iCompete has a number of restrictions which are built into
                iCompete so you can't accidentally enter an incorrect division.
                In this case you cannot enter both Bikini and Fitness Model, you
                can only enter one. If you want to change you must withdraw from
                your incorrect division first, then enter your correct choice. A
                very important note, make sure you save your amendment by
                submitting it (Save) otherwise it won't work. So in 3 easy
                steps, follow this schema: 1. Withdraw division. 2. Add
                Division. 3. Save (Submit) your change.
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q18" class="ng-scope">
                <b>Q.</b> How long should my prep be before a competition for a
                bikini or fitness novice comp?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A18" class="ng-scope">
                A. You should contact a PT or Competition Coach in MY LOCALITY
                GUIDE, otherwise contact the national/state/territory
                president/promoter who can put you in touch with one, you will
                find the contact info here for Australia:{" "}
                <a
                  target="_blank"
                  href="http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=16&amp;Itemid=40"
                >
                  http://www.icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=16&amp;Itemid=40
                </a>
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q19" class="ng-scope">
                <b>Q.</b> How do I get tickets for an iCompete event?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A19" class="ng-scope">
                A. Each promoter has their own method of selling tickets, it is
                recommended that you read the full terms and conditions set out
                in the 'Event Information PDF' found here:{" "}
                <a
                  target="_blank"
                  href="http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291"
                >
                  http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291
                </a>
              </p>{" "}
              <h3 translate="FAQ_EVENTS_Q20" class="ng-scope">
                <b>Q.</b> Do you know which day men's bodybuilding will be on
                I'm just looking at flights?
              </h3>{" "}
              <p translate="FAQ_EVENTS_A20" class="ng-scope">
                A. You can usually find a schedule of events in the 'Event
                Information PDF' found here{" "}
                <a
                  target="_blank"
                  href="http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291"
                >
                  http://icompeteaustralia.com.au/index.php?option=com_content&amp;view=article&amp;id=21&amp;Itemid=291
                </a>
              </p>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default MyEvent;
