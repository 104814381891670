import React, { useMemo, useState } from "react";
import { convertMentions } from "../../utils/utils";

const maxContentLength = 200;
export const Content = ({ content, type = 'comment', isEditMode, id }) => {
  const [updatedContent, setUpdatedContent] = useState(content);
  const [full, setFull] = useState(false);
  // const [hasImage, setHasImage] = useState(false);

  const toggle = () => setFull(!full);

  const hasImage = useMemo(() => {
    if (content?.includes("{PremiumPicture}")) {
      setUpdatedContent(updatedContent.replace("{PremiumPicture}", ""));
      return true;
    }
    return false;
  }, [content]);

  const sortContent = useMemo(
    () =>
      content?.length > maxContentLength
        ? `${convertMentions(content).substr(0, maxContentLength)} ...`
        : "",
    [content]
  );
  if (!content) {
    return null;
  }

  return (
    <>
      {/* {type === "post" ? (
        <MetaTags>
          <title>{updatedContent || content}</title>
          <meta name="description" content={updatedContent || content} />
          <meta property="og:title" content={updatedContent || content} />
          {hasImage ? (
            <meta
              property="og:image"
              content="./images/premium-membership-success.jpg"
            />
          ) : null}
        </MetaTags>
      ) : null} */}
      <div class="card-body p-0 me-lg-5">
        <p class="fw-500 text-grey-800 lh-26 font-xssss w-100">
          <div className="relative">
            <div
              id={`${type}_text_${id}`}
              className={`${(isEditMode ? "edit-text" : '')} comment-text`}
              contentEditable={isEditMode}
              onBlur={e => {
                console.log(e.currentTarget.textContent);
              }}
              suppressContentEditableWarning={true}
              dangerouslySetInnerHTML={{ __html: makeUrlsClickable(convertMentions(full ? content : sortContent || updatedContent)) }} >
            </div>
          </div>
          {sortContent ? (
            <a onClick={toggle} class="fw-600 text-primary ms-2 cursor-pointer">
              See {full ? "less" : "more"}
            </a>
          ) : null}
        </p>
      </div>
      {hasImage ? (
        <div class="card-body p-0 mb-3 rounded-3 overflow-hidden">
          <img
            src={"./images/premium-membership-success.jpg"}
            alt="video"
            class="w-100 mt-10"
          />
        </div>
      ) : null}
    </>
  );
};


function makeUrlsClickable(text) {
  const urlRegex = /(http[s]?:\/\/[^\s<]+)/g;
  const transformedText = text.replace(urlRegex, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
  return transformedText;
}
