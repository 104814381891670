import React, { createContext, useContext, useEffect, useState } from "react";
import { API, endpoints } from "../api";
import { useAuth } from "./Auth";
import { useParams } from "react-router-dom";
import { getParams } from "../utils/utils";
import strings from "../utils/strings";

export const FeedContext = createContext({
  loading: true,
  loadingMore: false,
  singleFeedId: undefined,
  feedData: [],
  addNewlyCreatedPost: (_post) => { },
  like: (_id, _type, _delete) => { },
  setFeedData: (_data) => { },
  updatePostData: (_data) => { },
  updatePost: (_data) => { },
  deletePost: (_id) => { },
  hidePost: (_id) => { },

  filter: "",
  setFilter: (_filter) => { },
});

export const FeedProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [canLoadMore, setCanMore] = useState(true);
  const [feedData, setFeedData] = useState([]);
  const [filter, setFeedFilter] = useState("");

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const { userCheck, showGlobalLoader, showToast } = useAuth();

  const setFilter = (newFilter) => {
    if (filter !== newFilter) {
      setFeedData([]);
      setCanMore(true);
      setLoadingMore(false);
      setFeedFilter(newFilter);
      setLoading(true);
      getFeedData({ newFilter, useFilterParam: true });
    }
  };

  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      if (currentPosition > scrollTop) {
        // down scroll
        setScrolling(false);
      } else {
        // up scroll
        setScrolling(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    getFeedData();
  }, []);

  useEffect(() => {
    const scrollVal = window.innerHeight + scrollTop;
    const documentHeight = document.scrollingElement.scrollHeight;

    if (scrollVal >= documentHeight) {
      if (canLoadMore && !loadingMore && feedData?.length) {
        getFeedData({ loadMore: true });
      }
    }
  }, [scrollTop]);

  const addNewlyCreatedPost = (post) => {
    setFeedData([{ ...post, can_delete: true, can_update: true }, ...feedData]);
  };

  const updatePostData = (data) => {
    const id = data?.feed_id;
    const index = feedData?.findIndex((feed) => feed.feed_id === id);

    if (index >= 0) {
      feedData[index] = { ...feedData[index], ...data };
      setFeedData([...feedData]);
    }
    setFeedData([...feedData]);
  };

  const updatePost = ({ id, content }) => {
    return new Promise((resolve, reject) => {
      userCheck(() => {
        var data = new FormData();
        data.append("id", id);
        data.append("content", content);
        showToast({ text: strings.updatedPost });
        API.post({ route: endpoints.FEED_UPDATE, data }).then((data) => {
          updatePostData(data?.data);
          setTimeout(() => resolve(data?.data))
        }).catch(reject)
      }, reject)
    })
  };

  const params = useParams();

  const getFeedData = ({
    loadMore = false,
    newFilter,
    useFilterParam = false,
  } = {}) => {
    const feedParams = {};
    if (params?.userId) {
      feedParams.posted_by = params?.userId;
    }

    if (useFilterParam) {
      feedParams.fetch_filter = newFilter;
    } else {
      feedParams.fetch_filter = filter;
    }
    if (loadMore && !feedData?.length) {
      return;
    }
    if (loadMore) {
      setLoadingMore(true);
      feedParams.last_feed_id = feedData[feedData?.length - 1]?.feed_id;
    }

    const api = props.singleFeedId ? `${endpoints.FEED}?feed_id=${params?.postId}` : `${endpoints.FEED}?${getParams(feedParams)}`

    API.get({ route: api })
      .then((data) => {
        if (loadMore) {
          setFeedData([...feedData, ...data?.data]);
        } else {
          setFeedData(data?.data || []);
        }
        if (!data?.data?.length || data?.data?.length < 10) {
          setCanMore(false);
        }
      })
      .catch((data) => {
        console.log("data error => ", data);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  const likePost = ({ id, type = 1, isDelete = false }) => {
    return new Promise((reject, resolve) => {
      userCheck(
        () => {
          var data = new FormData();
          data.append("feed_id", id);
          if (type) data.append("like_type", type);

          let route = endpoints.LIKE;
          if (isDelete) {
            route = endpoints.LIKE_DELETE;
          }
          API.post({ route, data })
            .then((data) => {
              const index = feedData?.findIndex((feed) => feed.feed_id === id);

              if (index >= 0) {
                feedData[index] = { ...feedData[index], ...data?.data };
                setFeedData([...feedData]);
              }
              resolve();
            })
            .catch(() => reject());
        },
        () => reject()
      );
    });
  };

  const deletePost = (id) => {
    userCheck(() => {
      var data = new FormData();
      data.append("feed_id", id);
      showGlobalLoader(true);
      API.post({ route: endpoints.FEED_DELETE, data })
        .then(() => {
          showToast({ text: strings.deletePost });
          const index = feedData?.findIndex((feed) => feed.feed_id === id);

          if (index >= 0) {
            feedData.splice(index, 1);
            setFeedData([...feedData]);
          }
        })
        .finally(() => showGlobalLoader(false));
    });
  };

  const hidePost = (id) => {
    userCheck(() => {
      var data = new FormData();
      data.append("feed_id", id);
      showGlobalLoader(true);
      API.post({ route: endpoints.FEED_HIDE, data })
        .then(() => {
          showToast({ text: strings.hidePost });
          const index = feedData?.findIndex((feed) => feed.feed_id === id);

          if (index >= 0) {
            feedData.splice(index, 1);
            setFeedData([...feedData]);
          }
        })
        .finally(() => showGlobalLoader(false));
    });
  };

  return (
    <FeedContext.Provider
      value={{
        loading,
        feedData,
        loadingMore,
        addNewlyCreatedPost,
        likePost,
        setFeedData,
        updatePostData,
        updatePost,
        deletePost,
        filter,
        setFilter,
        hidePost,
        // singleFeedId
      }}
    >
      {props.children}
    </FeedContext.Provider>
  );
};

export const useFeed = () => useContext(FeedContext);
