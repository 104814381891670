import AdditionalOptions from "./additional-options";
import ConfirmDetails from "./confirm-details";
import { SelectDivisions } from "./divisions/select-divisions";
import Events from "./events";
import FirstSection from "./first-section";
import TcConditions from "./tc-conditions";
import { useEnterEvent } from "../../context/EnterEvent";

export const EnterEvent = ({ isViewEntry, enrolId }) => {
  const { editId } = useEnterEvent();

  if (isViewEntry) {
    return <ConfirmDetails isViewEntry={isViewEntry} enrolId={enrolId} />
  }

  return (
    <div className="enter-event">
      {enrolId ? null : <div className="card shadow-xss w-100 border-0 p-4 mb-3">
        <div className="card-body p-0">
          <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">
            <i className="btn-round-sm bg-blue-gradiant me-2 fa-solid fa-calendar-check text-white" />
            {editId ? "Edit Event" : "Enter New Event"}
          </h2>
        </div>
      </div>}

      {enrolId ? null : <FirstSection />}
      <div style={{ display: enrolId ? 'none' : 'block' }}>
        <Events />
      </div>

      <SelectDivisions enrolId={enrolId} />
      <AdditionalOptions enrolId={enrolId} />
      <div style={{ display: enrolId ? 'none' : 'block' }}>
        <TcConditions />
      </div>
      <ConfirmDetails enrolId={enrolId} />
    </div>
  );
};
