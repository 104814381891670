import React, { useState } from "react";
import { API, endpoints } from "../../api";

import { Password } from "../../components/authorization/password";
import { Error, Loader } from "../../components";
import { useAuth } from "./auth";

export default () => {
    const [email, updateEmail] = useState("");
    const [password, updatePassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const { updateUser } = useAuth();

    const doLogin = (event) => {
        event.preventDefault();
        if (email && password) {
            setLoading(true);

            var data = new FormData();
            data.append("email", email);
            data.append("password", password);
            API.post({ route: endpoints.OFFLINE_LOGIN, data })
                .then((data) => {
                    updateUser(data);
                })
                .catch((data) => {
                    setError(data?.response?.data?.message || "Invalid credentials");
                })
                .finally(() => setLoading(false));
        } else {
            setError("Please enter email and password");
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="offline-login">
            <form
                onSubmit={doLogin}
                className="card shadow-none border-0 ms-auto me-auto"
            >
                <div className="card-body rounded-0 text-left">
                    <h3 className="fw-700 display1-size display2-md-size mb-4 text-center">
                        LOGIN
                    </h3>

                    <Error error={error} />

                    <div className="form-group icon-input mb-3">
                        <i className="font-sm ti-email text-grey-500 pe-0" />
                        <input
                            value={email}
                            onChange={(event) => updateEmail(event.target.value)}
                            type="text"
                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                            placeholder="Your Email Address"
                        />
                    </div>
                    <Password value={password} update={updatePassword} autoComplete="on" />
                    <div className="col-sm-12 p-0 text-left">
                        <div className="form-group mb-1">
                            <button className="form-control text-center style2-input text-white fw-600 bg-primary-gradiant border-0 p-0 ">
                                LOGIN
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
