import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API, endpoints } from "../api";
import { Feed, LoaderSmall } from "../components";
import { useAuth } from "../context/Auth";
import { ASYNC_STORAGE_KEYS, retrieveData } from "../utils/storage";
import { Badges } from "../components/feed/badges";

const UserProfile = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [isSelfProfile, setSelfProfile] = useState(false);
  const [user, setUser] = useState({});

  const { userCheck, removeUser } = useAuth();

  useEffect(() => {
    getSelfProfile();
  }, [params?.userId]);

  const getSelfProfile = async () => {
    const userId = (await retrieveData(ASYNC_STORAGE_KEYS.LOGIN_DATA, true))
      ?.user?.id;

    setSelfProfile(userId == params?.userId);
  };

  useEffect(() => {
    if (params?.userId) {
      if (isSelfProfile)
        userCheck(
          () => {
            API.get({ route: `${endpoints.PROFILE}?id=${params?.userId}` })
              .then((data) => {
                setUser(data?.data);
              })
              .finally(() => setLoading(false));
          },
          () => {
            setLoading(false);
            removeUser();
          }
        );
      else
        API.get({ route: `${endpoints.PUBLIC_PROFILE}${params?.userId}` })
          .then((data) => {
            setUser(data?.data);
          })
          .finally(() => setLoading(false));
    }
  }, [params?.userId, isSelfProfile]);

  return (
    <>
      {loading ? (
        <LoaderSmall />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
              <div className="card-body h250 p-0 rounded-xxl overflow-hidden position-relative m-3">
                <img
                  src="../images/timeline-banner.png"
                  alt="image"
                  style={{ width: "100%" }}
                />
                <img
                  className="user-page-logo"
                  src="../images/icn.png"
                  alt="image"
                />
              </div>
              <div className="card-body p-0 position-relative d-flex align-items-center mb-4">
                <figure
                  className="avatar"

                >
                  <img
                    style={{ width: 100, height: 100, marginLeft: 30, marginBottom: 10 }}
                    src={user?.image || "https://via.placeholder.com/50x50.png"}
                    alt="image"
                    className="float-right p-1 bg-white rounded-circle user-post-image"
                  />
                </figure>

                <h4 className="fw-700 font-sm mt-0 mb-0" style={{ marginLeft: '28px' }}>
                  {user?.name} <Badges {...user} />
                  <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                    {user?.email}
                  </span>
                </h4>
                <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                  {isSelfProfile ? (
                    <Link
                      to="/edit-profile"
                      className="d-none d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                    >
                      Edit Profile
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0 mt-3">
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
              {!isSelfProfile ? (
                <Link
                  to={`/messages/${user?.id}`}
                  className="text-center d-none d-lg-block bg-primary p-3 z-index-1 rounded-xxl text-white font-xssss text-uppercase fw-700 ls-3 m-3"
                >
                  Send a message
                </Link>
              ) : null}
              {user?.about ? (
                <div className="card-body d-block p-4">
                  <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
                  <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                    {user?.about}
                  </p>
                </div>
              ) : null}
              <div className="card-body mt-3 d-flex pt-0">
                <i className="feather-eye text-grey-500 me-3 font-lg" />
                <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                  Visble{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    Anyone can find you
                  </span>
                </h4>
              </div>
              {user?.location || user?.country ? (
                <div className="card-body d-flex pt-0">
                  <i className="feather-map-pin text-grey-500 me-3 font-lg" />
                  {user?.location ? (
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                      {user?.location}
                    </h4>
                  ) : (
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                      {user?.state}, {user?.country} - {user?.postcode}
                    </h4>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
            <Feed />
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
