import moment from "moment";
import { useEnterEvent } from "../../../context/EnterEvent";
import strings from "../../../utils/strings";

const PaymentOptions = () => {
  const { selectedEvent } = useEnterEvent();

  return (
    <div style={{ maxWidth: 280 }}>
      <p className="text-current m-0 mt-4">
        <strong>Payment Options</strong>
      </p>

      {selectedEvent?.availablePayments?.map(
        ({ paymentType, inPerStartDate, inPerEndDate }, index) => {
          if (paymentType === "in_person") {
            return (
              <p key={`payment-option-${index}`}>
                <strong>In Person:</strong>
                <br />
                {strings.payments.inPerson}
                <br />
                from <u>
                  {moment(inPerStartDate).format("DD MMM yyyy")}
                </u> until <u>{moment(inPerEndDate).format("DD MMM yyyy")}</u>
              </p>
            );
          } else if (paymentType === "stripe") {
            return (
              <p key={`payment-option-${index}`}>
                <strong>Online:</strong>
                <br />
                {strings.payments.stripe}{" "}
                <u>
                  {moment(inPerStartDate).format(
                    "DD MMM yyyy"
                  )}
                </u>{" "}
                until{" "}
                <u>
                  {moment(inPerEndDate).format(
                    "DD MMM yyyy"
                  )}
                </u>
              </p>
            );
          } else if (paymentType === "rozar_pay") {
            return (
              <p key={`payment-option-${index}`}>
                <strong>Online:</strong>
                <br />
                {strings.payments.rozar}{" "}
                <u>
                  {moment(inPerStartDate).format(
                    "DD MMM yyyy"
                  )}
                </u>{" "}
                until{" "}
                <u>
                  {moment(inPerEndDate).format(
                    "DD MMM yyyy"
                  )}
                </u>
              </p>
            );
          } else if (paymentType === "paypal") {
            return (
              <p key={`payment-option-${index}`}>
                <strong>Online:</strong>
                <br />
                {strings.payments.paypal}{" "}
                <u>
                  {moment(inPerStartDate).format(
                    "DD MMM yyyy"
                  )}
                </u>{" "}
                until{" "}
                <u>
                  {moment(inPerEndDate).format(
                    "DD MMM yyyy"
                  )}
                </u>
              </p>
            );
          }
        }
      )}
    </div>
  );
};

export default PaymentOptions;
