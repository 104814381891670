import React, { useState } from "react";
import { Error, Loader } from "../";
import { API, endpoints } from "../../api";
import { errorToString } from "../../utils/utils";
import { GoogleBtn } from "./google-btn";
import { Password } from "./password";
import { useAuth } from "../../context/Auth";
import LocationPicker from "../location-picker";

export const SignUp = ({ changeForm }) => {
  const [firstName, updateFirstName] = useState("");
  const [lastName, updateLastName] = useState("");
  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState({});

  const { updateUser } = useAuth();

  const doSignUp = (event) => {
    event.preventDefault();
    if (firstName && lastName && email && password) {

      if (!address || (address && !Object.keys(address)?.length)) {
        setError("Please select address");
        return
      }

      setLoading(true);

      var data = new FormData();
      data.append("first_name", firstName);
      data.append("last_name", lastName);
      data.append("name", `${firstName} ${lastName}`);
      data.append("email", email);
      data.append("password", password);

      Object.keys(address).forEach((key) => {
        data.append(key, address[key]);
      });

      setError("");
      API.post({ route: endpoints.REGISTER, data })
        .then((data) => {
          updateUser(data);
        })
        .catch((data) => {
          setError(errorToString(data?.response));
        })
        .finally(() => setLoading(false));
    } else {
      setError("Please fill all fields");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form
      onSubmit={doSignUp}
      className="card shadow-none border-0 ms-auto me-auto"
    >
      <div className="card-body rounded-0 text-left">
        <h3 className="fw-700 display1-size display2-md-size mb-4 text-center">
          SIGN UP
        </h3>

        <Error error={error} />

        <div className="form-group icon-input mb-3">
          <i className="font-sm ti-user text-grey-500 pe-0" />
          <input
            type="text"
            value={firstName}
            autocorrect="off" autocapitalize="none"
            onChange={(event) => updateFirstName(event.target.value)}
            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
            placeholder="Your First Name"
            required
          />
        </div>
        <div className="form-group icon-input mb-3">
          <i className="font-sm ti-user text-grey-500 pe-0" />
          <input
            type="text"
            value={lastName}
            onChange={(event) => updateLastName(event.target.value)}
            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
            placeholder="Your Last Name"
            required
          />
        </div>
        <div className="form-group icon-input mb-3">
          <i className="font-sm ti-email text-grey-500 pe-0" />
          <input
            value={email}
            onChange={(event) => updateEmail(event.target.value)}
            type="text"
            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
            placeholder="Your Email Address"
          />
        </div>
        <Password value={password} update={updatePassword} />
        <LocationPicker data={address} canShowFullForm={false} setAddress={setAddress} customIconStyle={{ fontSize: 12, marginTop: 4 }} />
        <div className="col-sm-12 p-0 text-left">
          <div className="form-group mb-1">
            <button
              type="submit"
              className="form-control text-center style2-input text-white fw-600 bg-primary-gradiant border-0 p-0"
            >
              SIGN UP
            </button>
          </div>
          <h6 className="text-grey-500 font-xsss fw-500 mt-4 mb-2 lh-32 text-center cursor-pointer">
            <a
              onClick={() => changeForm(true)}
              className="fw-700 ms-1 text-center"
            >
              Already a Member? Login
            </a>
          </h6>
        </div>
        <GoogleBtn />
      </div>
    </form>
  );
};
