import React, { useState } from "react";
import { Modal } from "./";
import EnterEventMain from "../pages/EnterEvent";
import { Link } from "react-router-dom";

export const ViewEntryModal = ({ id }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Link
        to={`/view-events/${id}`}
        onClick={toggle} className="d-block bg-gray rounded-xl text-center text-black px-2 py-1 font-xsss w100 cursor-pointer">View Entry</Link>
      <Modal
        show={modal}
        close={() => setModal(false)}
        style={{ width: '90%', minHeight: 0, maxWidth: 1000 }}
        onClick={toggle}
      >
        {modal ? <EnterEventMain isViewEntry /> : null}
      </Modal>
    </>
  );
};
