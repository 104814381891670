import React, { useEffect, useState } from "react";
import { API, endpoints } from "../../api";

import { Link } from "react-router-dom";
import { useAuth as useAuthMain } from "../../context/Auth";
import { useAuth } from "./auth";


export default () => {

    const { user, loading } = useAuth()
    const { showGlobalLoader: setLoading } = useAuthMain()
    const [data, setData] = useState()

    useEffect(() => {
        if (user) {
            getData()
        }
    }, [user])

    const getData = () => {
        setLoading(true);
        console.log("user, loading => ", { user, loading })

        API.post({ route: endpoints.OFFLINE_EVENTS })
            .then((data) => {
                setData(data?.result)
            })
            .catch((data) => {
            })
            .finally(() => setLoading(false));

    };

    return <div className="overflow-auto">
        <table className="custom-table">
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Competitors</th>
                    <th>Entries</th>
                    <th>View</th>
                </tr>
            </thead>
            <tbody>
                {data?.map(({ id, eventTitle, year, venue, enrollmentCount, entries }) => <tr>
                    <td>
                        {eventTitle}
                        <br />
                        {venue} - {year}
                    </td>
                    <td>{enrollmentCount}</td>
                    <td>{entries}</td>
                    <td>
                        <Link
                            to={`/event-manager/competitors/${id}`}
                            className="bg-current text-center text-white font-xsss fw-600 rounded-3 border-0 btn-sm"
                        >
                            View
                        </Link>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>
}

