import React from "react";

import { AuthProvider } from "./auth";
import Events from "./events";
import { Route, Routes, useLocation, useNavigate, useParams, useRoutes } from "react-router-dom";
import Competitors from "./competitors";


export default () => {

    const { pathname } = useLocation()

    return <AuthProvider>
        {pathname?.includes('competitors') ?
            <Competitors />
            : <Events />
        }
    </AuthProvider>
}
