import { useEffect, useState } from "react";
import { API, endpoints } from "../api";
import { Error } from "../components";
import { Password } from "../components/authorization/password";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { deleteAllParams, errorToString } from "../utils/utils";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [invalidToken, setInvalidToken] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get("email"));
    setToken(urlParams.get("token"));

    checkToken(urlParams.get("email"), urlParams.get("token"));
  }, []);

  const checkToken = (emailParam, tokenParam) => {
    setLoading(true);
    API.get({
      route: `${endpoints.CHECK_RESET_PASSWORD_TOKEN}?email=${emailParam}&token=${tokenParam}`,
    })
      .then((data) => {
        if (!data?.status) {
          setInvalidToken(true);
        }
      })
      .catch(() => {
        deleteAllParams();
        setInvalidToken(true);
      })
      .finally(() => setLoading(false));
  };

  const {
    showToast,
    showGlobalLoader: setLoading,
    loginRedirectUrl,
  } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = new FormData();
    data.append("token", token);
    data.append("email", email);
    data.append("password", newPassword);
    data.append("password_confirmation", confirmPassword);

    API.post({ route: endpoints.RESET_PASSWORD, data })
      .then((data) => {
        if (data?.status) {
          showToast({ text: data?.message || strings.changePass });
          deleteAllParams();
          setTimeout(() => (window.location.href = loginRedirectUrl), 3000);
        } else showToast({ text: data?.message, type: toastType.ERROR });
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response?.data),
          type: toastType.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="small-content">
      <div>
        <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
          <div class="card-body d-flex align-items-center p-0">
            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
              Reset Password
            </h2>
          </div>
        </div>

        {invalidToken ? (
          <Error error={"Invalid reset password link"} />
        ) : (
          <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
            <form
              onSubmit={handleSubmit}
              className="card-body p-lg-5 p-4 w-100 border-0 "
            >
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pe-0" />
                <input
                  value={email}
                  type="text"
                  readOnly
                  autoComplete="off"
                  className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Your Email Address"
                />
              </div>
              <Password
                value={newPassword}
                update={setNewPassword}
                placeholder="New Password *"
              />
              <Password
                value={confirmPassword}
                update={setConfirmPassword}
                placeholder="Confirm Password *"
              />
              <div className="h-center">
                <button
                  type="submit"
                  className="form-control w-50 text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0 "
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
