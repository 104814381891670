import http from "axios";
import { retrieveData, ASYNC_STORAGE_KEYS } from "../utils/storage";
import { baseURL } from "../api/config";

export class API {
  static async baseHeaders() {
    const token = (await retrieveData(ASYNC_STORAGE_KEYS.LOGIN_DATA, true))
      ?.access_token;

    return {
      Authorization: `Bearer ${token}`,
    };
  }

  static getUrl(route) {
    return `${baseURL}/api/v1/${route}`;
  }

  //route, headers, params, timeout
  static get(requestData) {
    return this.api("get", requestData);
  }

  //route, headers, params, data, timeout
  static put(requestData) {
    return this.api("put", requestData);
  }

  //route, headers, params, data, timeout
  static post(requestData) {
    return this.api("post", requestData);
  }

  //route, headers, params, data, timeout
  static patch(requestData) {
    return this.api("patch", requestData);
  }

  //route, headers, params, data, timeout
  static delete(requestData) {
    return this.api("delete", requestData);
  }

  static async api(
    requestType,
    { route, headers, params, data, timeout = 60000, token: newToken }
  ) {
    try {
      const url = API.getUrl(route);
      const baseHeaders = await API.baseHeaders();

      const requestConfig = {
        headers: headers ? { ...baseHeaders, ...headers } : baseHeaders,
      };

      if (newToken) {
        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization: `Bearer ${newToken}`,
        };
      }

      http.create();
      http.defaults.timeout = timeout;

      if (data) {
        //if form data is there
        requestConfig.headers = {
          ...requestConfig.headers,
          "Content-Type": "multipart/form-data",
        };
      } else {
        //json request is there
      }

      return http({
        method: requestType,
        url,
        data: data || params,
        ...requestConfig,
      })
        .then((response) => {
          if (response.status === 200) {
            return checkValidJSON(response.data);
          } else {
            logApiError(response.data);
            return Promise.reject(response.data);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.clear();
            //  window.location.href = `${window.location.protocol}//${window.location.host}`;
          }
          logApiError(error);
          return Promise.reject(error);
        });
    } catch (e) {
      console.error("error => ", e);
    }
  }
}

function checkValidJSON(response) {
  if (response.data !== "string") return response;
  return Promise.reject(response);
}

async function logApiError(error) {
  const { url, method, data, headers } = error.config || {};
  console.log(`
    url => ${url}
    method => ${method}
    data => ${data}
    headers => ${headers}
  `);
  // console.error("error => ", error);
}
