import React, { useEffect, useState } from "react";
import { API, endpoints } from "../../api";
import { useAuth } from "../../context/Auth";
import { useFeed } from "../../context/Feed";
import strings, { toastType } from "../../utils/strings";
import { errorToString } from "../../utils/utils";
import { Avatar } from "../avatar";
import { Error } from "../error";
import { Mention, MentionsInput } from "react-mentions";
import debounce from "lodash/debounce";

export const CreatePost = () => {
  const [content, updateContent] = useState("");
  const [error, setError] = useState("");
  const [images, setImages] = useState([]);
  const [selectedFiles, setFiles] = useState([]);

  const { addNewlyCreatedPost } = useFeed();
  const {
    user,
    userCheck,
    showGlobalLoader: setLoading,
    showToast,
  } = useAuth();

  const onChange = (event) => {
    const paths = [];
    const files = event?.target?.files || [];
    setFiles(files);
    for (let i = 0; i < files?.length; i++) {
      paths.push(URL.createObjectURL(files[i]));
    }
    setImages(paths);
  };

  const createPost = (event) => {
    setError("");
    event.preventDefault();
    userCheck(() => {
      if (content || selectedFiles.length > 0) {
        setLoading(true);
        var data = new FormData();
        data.append("content", content);
        if (selectedFiles?.length) {
          for (let i = 0; i < selectedFiles?.length; i++) {
            data.append("images[]", selectedFiles[i]);
          }
        }

        API.post({ route: endpoints.FEED, data })
          .then((data) => {
            if (data?.status) {
              showToast({ text: strings.newPost });
              addNewlyCreatedPost(data.data);
              updateContent("");
              setImages([]);
              setFiles([]);
              document.getElementById("createForm").reset();
            }
          })
          .catch((data) => {
            setError(errorToString(data?.response));
          })
          .finally(() => setLoading(false));
      } else {
        setError("Please enter content (or photos ) to create a post");
      }
    });
  };

  useEffect(() => {
    let dropArea = document.getElementById('createForm');

    ;['dragenter', 'dragover', 'dragleave', 'drop', 'dragstart', 'dragend'].forEach(eventName => {
      dropArea.addEventListener(eventName, preventDefaults, false)
    })

    function preventDefaults(e) {
      e.preventDefault()
      e.stopPropagation()
    }

    ;['dragover', 'dragstart', 'dragenter'].forEach(eventName => {

      dropArea.addEventListener(eventName, highlight, false)
    })

      ;['dragleave', 'dragend', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
      })

    function highlight(e) {
      console.log("eventName highlight => ", e)
      dropArea.classList.add('highlight')
    }

    function unhighlight(e) {
      console.log("eventName unhighlight => ", e)
      dropArea.classList.remove('highlight')
    }

    dropArea.addEventListener('drop', handleDrop, false)

    function handleDrop(e) {
      let dt = e.dataTransfer
      let files = dt.files

      handleFiles(files)
    }

    function handleFiles(files) {

      console.log("selectedFiles => ", selectedFiles)
      console.log("files = ", files)

      const newFiles = [...selectedFiles, ...files]
      if (newFiles?.length > 10) {
        showToast({ text: 'Max 10 photos are allowed.', type: toastType.ERROR });
        return
      }
      setFiles([...newFiles]);

      console.log("newFiles => ", newFiles)

      const path = []
      for (let i = 0; i < newFiles?.length; i++) {
        path.push(URL.createObjectURL(newFiles[i]));
      }
      setImages([...path]);

      console.log("path => ", path)
      // ([...files]).forEach(uploadFile)
    }

    return () => {
      dropArea.removeEventListener('drop', handleDrop, false)
        ;['dragenter', 'dragover', 'dragleave', 'drop', 'dragstart', 'dragend'].forEach(eventName => {
          dropArea.removeEventListener(eventName, preventDefaults, false)
        })
        ;['dragover', 'dragstart', 'dragenter'].forEach(eventName => {
          dropArea.removeEventListener(eventName, highlight, false)
        })

        ;['dragleave', 'dragend', 'dragend'].forEach(eventName => {
          dropArea.removeEventListener(eventName, unhighlight, false)
        })
    }


  }, [selectedFiles])

  const users = []
  const renderUserSuggestion = (val) => {
    console.log("renderUserSuggestion val=>", val)
  }


  const getSearchData = async (str, callback) => {
    if (!str) {
      callback([])
    }
    try {
      const users = await API.get({ route: `${endpoints.SEARCH}?query=${str}` })
      callback(users?.data?.map(({ name, id, user_name }) => ({ id: user_name, display: name })))
    } catch (e) {
      callback([])
    }
  };
  const delayedHandleChange = debounce(getSearchData, 50);



  return (
    <>
      <form
        id="createForm"
        onSubmit={createPost}
        class="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3"
      >
        <div class="card-body p-0">
          {/* <a class=" font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center">
            <i class="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
            Create Post
          </a> */}
          <div style={{ maxWidth: "80%", marginLeft: "10%" }}>
            <Error error={error} clear={() => setError("")} />
          </div>
        </div>
        <div class="card-body p-0 mt-3 position-relative h100 mb-2">
          <figure class="avatar position-absolute ms-2 mt-1 top-5">
            <Avatar user={user?.user} />
          </figure>
          <MentionsInput value={content}
            onChange={(event) => {
              console.log("event.target.value =>", event.target.value)
              updateContent(event.target.value)
            }}
            classNames={{
              m__input: "h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-900 fw-500 border-light-md theme-dark-bg notranslate",
            }}

            placeholder="What's on your mind?"
            customSuggestionsContainer={(props) => <div className="mention-suggestions rounded-xxl p-2 ps-5 font-xssss text-grey-900">{props}</div>}
          >
            <Mention
              markup="@[__display__](user:__id__)"
              trigger="@"
              data={delayedHandleChange}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => (
                <div className={`font-xssss text-grey-900 fw-700 render-suggestion-text  ${focused ? 'focused' : ''}`}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>

          {/* <textarea
            value={content}
            onChange={(event) => updateContent(event.target.value)}
            name="message"
            class="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-900 fw-500 border-light-md theme-dark-bg"
            cols="30"
            rows="10"
            placeholder="What's on your mind?"
          ></textarea> */}
        </div>
        <div class="card-body d-flex p-0 mt-0">
          {/* <a
            href="#"
            class="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
          >
            <i class="font-md text-danger feather-video me-2"></i>
            <span class="d-none-xs">Live Video</span>
          </a> */}
          <div className="position-relative cursor-pointer overflow-hidden">
            <a class="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 cursor-pointer">
              <i class="font-md text-success feather-image me-2"></i>
              <span class="d-none-xs">
                Add Photos <small>(max. 10)</small>
              </span>
            </a>
            <input
              onChange={onChange}
              // onChange={(event) => setFiles(event.target.value)}
              className="image-input"
              type="file"
              multiple
              max={10}
              accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
            />
          </div>
          {/* <a
            href="#"
            class="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
          >
            <i class="font-md text-warning feather-camera me-2"></i>
            <span class="d-none-xs">Feeling/Activity</span>
          </a> */}
          <button
            type="submit"
            style={{ maxHeight: 35 }}
            class="create-post-btn ms-auto border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
            Create
            Post
          >
            Post
          </button>
        </div>
        <div className="create-post-image-main">
          {images?.length
            ? images.map((img, index) => (
              <img
                key={`selected-img-${index}`}
                src={img}
                alt="image"
                class="shadow-sm w100 mt-2 mr-1 create-post-image"
              />
            ))
            : null}
        </div>
      </form>
    </>
  );
};
