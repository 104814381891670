const TermsAndConditions = () => {
  return (
    <div className="content-page">
      <div className="mb-4">
        <i class="fa-sharp fa-solid fa-file font-xl mr-11" />
        <span className="page-title ng-scope">Terms and conditions</span>
      </div>
      <p>
        This site is part of Natural Icons (The Company). This site is intended
        for members to communicate and interact with each other online.
      </p>
      <p>
        These are the terms and conditions of agreement between you and The
        Company. These terms and conditions cover; your use of this website; the
        entire contents of this website, located at domain
        http://www.icompetenatural.com and other websites provided by Natural
        Icons Pty. Ltd. within INBA Network; and to any correspondence between
        us and you. Please read these terms and conditions carefully before
        using this website and before becoming a member. Your use of this
        website indicates your acceptance of the terms and conditions. If you do
        not accept these terms and conditions, please do not use this website.
      </p>
      <p>
        By ticking the box next to the "I verify that I am 15 years of age or
        over and that I have read the terms and conditions and accept them."
        text on the Member Join Page, and clicking the 'Submit' button, you
        further accept and agree to the Terms and Conditions. Every time you
        access the site you confirm your agreement with the Terms and
        Conditions.
      </p>
      <p>
        Your enjoyment and experience of The Company websites is very important
        to us. Please read the following policies and guidelines that also form
        a part of these Terms and Conditions.
      </p>
      <ul>
        <li>Privacy</li> <li>Safety</li>
      </ul>
      <p>
        To register for membership or use the site, you must be aged 18 years or
        over.
      </p>
      <h3>Content:</h3>
      <ul>
        <li>
          You alone are responsible for the content you publish, display or
          distribute on this site, on your profile and in any communications
          with other Members.
        </li>
        <li>
          No responsibility is assumed by The Company for any content, including
          photos, published or transmitted by Members. The Company reserves the
          right to edit profile and photo content.
        </li>
        <li>
          No responsibility is assumed by The Company for any content published
          or transmitted.
        </li>
        <li>
          The Company does not guarantee the accuracy of any information
          published on the site and does not endorse nor is responsible for the
          accuracy or reliability of any opinion, advice, or statements made by
          any subscriber to its services. Opinions, advice, statements, offers,
          or other information or content made available through the site are
          those of their respective authors and should not be relied upon.
        </li>
        <li>
          You acknowledge and agree that by becoming a member that you have not
          relied upon nor been induced by any representation or warranty
          whatsoever of iCompete Network.
        </li>
        <li>
          You may not publish or transmit offensive content; this includes
          content that contains offensive language, sexually explicit content,
          and any content that is considered unlawful, threatening, abusive,
          racist, derogatory, harmful, obscene, harassing, discriminatory, and
          libellous. Further you may not publish any content that may violate or
          encourage violation of any applicable local, state, national or
          international law or regulation.
        </li>
        <li>
          While The Company reserves the right to edit, delete or remove content
          deemed offensive at our sole discretion within these Terms and
          Conditions, The Company does not guarantee that offensive material
          will be removed or deleted.
        </li>
        <li>
          The Company reserves the right to read, censor and edit profiles,
          monitor chat room conversations and remove and edit posts on website.
        </li>
        <li>
          The Company reserves the right to automatically filter content of
          members. This content includes but is not limited to email addresses,
          telephone numbers and weblinks.
        </li>
        <li>
          You may not publish or transmit content that contains advertising or
          commercial messages, including on your profile and in communications
          with other members.
        </li>
        <li>
          You may not impersonate any other person or entity. You may not use
          the content from another member's profile on this site or any other,
          or use another person's photo as your own.
        </li>
        <li>
          You may not publish any personal contact information in your profile,
          your say posts and via photos. This includes but is not restricted to,
          telephone numbers, email addresses, instant messaging contact details,
          website URL 's, place of work etc. Personal contact information will
          be removed.
        </li>
        <li>You may not solicit other members contact details.</li>
        <li>
          You may not create or use more than one profile on this site or on
          iCompete Network.
        </li>
        <li>
          The company may from time to time send marketing material to members
          by email. You will have the opportunity to opt out of receiving this
          material.
        </li>
        <li>
          Your use of these sites provides you no rights in regard to The
          Company intellectual property rights including trademarks, designs and
          copyright or the intellectual property rights of Third Parties.
        </li>
        <li>
          Without limitation, the content on or provided through the site may
          only be used for your own personal, non-commercial use. You may not
          exploit commercially, copy, republish, record, download, post,
          transmit, broadcast, communicate to others, or use the content in any
          other way, other than for your own personal, non-commercial use.
        </li>
        <li>Content on The Company websites is in the English language.</li>
      </ul>
      <h3>Account and Password Details:</h3>
      <p>
        You are solely responsible for ensuring that your account and password
        details are kept confidential and safe from unauthorized access. Do not
        give out your password to anyone.
      </p>
      <h3>IP Logging</h3>
      <p>
        The Company reserves the right to log IP addresses upon both successful
        and unsuccessful attempts to log-in to the members area. This data will
        not be used for personal identification, except in instances of threat
        to integrity, safety or security, in which cases the information will be
        made available only to the requisite authorities.
      </p>
      <h3>Harassment and Disputes</h3>
      <ul>
        <li>
          The Company will not become involved in any disputes between Members
          and does not provide any arbitration or settlement services should a
          dispute arise between members.
        </li>
        <li>
          You alone are responsible for ensuring that your interaction with
          other Members is lawful. The Company advises you that there may be
          risks of dealing with Members acting under false pretences or with
          criminal intent.
        </li>
        <li>
          The Company cannot control what happens between members once they
          decide to meet in person, however if you encounter behaviour that may
          be deemed harmful to other Members you are requested to contact The
          Company with full details and we may, if deemed appropriate at the
          sole discretion of The Company, warn, suspend or delete the offending
          member's profile.
        </li>
        <li>
          The Company has no intent or interest in getting involved in domestic
          or private disputes between Members.
        </li>
      </ul>
      <h3>Member Communications</h3>
      <ul>
        <li>
          You alone are responsible for the message content you transmit to
          other members. You will ensure that it is appropriate and is not in
          breach of the Terms and Conditions.
        </li>
        <li>
          The Company does not in ordinary circumstances review or censor member
          content but reserves the right to delete messages which contain
          advertising or which The Company regards as spam, or to review content
          if there is a reported breach of conduct, conditions or guidelines, or
          if required by the relevant authorities.
        </li>
        <li>
          You are responsible for keeping messages you wish to save as The
          Company cannot retrieve messages which have been deleted.
        </li>
      </ul>
      <h3>Memberships</h3>
      <ul>
        <li>You may register for one account only</li>
        <li>
          Members with a Free account who have not logged in for a period of 6
          months will have their profile hidden from the website.
        </li>
        <li>
          Premium subscribing members who do not renew their subscription will
          revert to Free membership.
        </li>
        <li>
          Free members who log back into iCompete after having their profile
          hidden, will automatically have their profile unhidden.
        </li>
      </ul>
      <h3>Subscriptions</h3>
      <ul>
        <li>
          The Company may change the subscription pricing, payment options,
          renewal terms or Member services at any time.
        </li>
        <li>
          You may not request your membership or subscription be put on hold for
          you and reinstated at a later date.The Company may suspend or
          terminate the subscription of any Member who violates these Terms and
          Conditions.
        </li>
        <li>
          The Company will not refund subscriptions if there has been a
          violation of these Terms and Conditions. Refunds will be given at the
          discretion of The Company management.
        </li>
        <li>You are solely responsible for any charges you incur.</li>
        <li>You may cancel your subscription at any time.</li>
      </ul>
      <h3>Service Provision</h3>
      <p>
        The Company does not warrant that any of its services will always be
        secure, uninterrupted, and always available or error free, or will meet
        your requirements, or that any defects in the service will be corrected.
      </p>
      <p>
        Site access may be suspended periodically and without notice in the case
        of repair, maintenance, system failure or for reasons outside of our
        control.
      </p>
      <h3>Modifications to Terms and Conditions</h3>
      <p>
        The Terms and Conditions of this site and iCompete Network are subject
        to change at any time without notice.
      </p>
      <h3>Suspension and Termination of Membership</h3>
      <p>
        The Company may suspend or terminate the membership of any Member who
        violates these Terms and Conditions.
      </p>
      <p>
        The Company reserves the right to suspend and/or terminate your
        membership at any time at its absolute discretion.
      </p>
      <p>You may terminate your Membership at any time.</p>
      <h3>Limitation of Liability</h3>
      <p>
        You agree to indemnify and hold harmless The Company, its directors,
        officers, employees, agents, affiliates, partner sites and third
        parties, from any loss, claim, expenses or damages (including but not
        limited to any, direct, indirect, incidental and/or consequential loss
        or damages, or any loss of income, profits, goodwill, data and
        contracts), relating to or arising out of your use of this site and the
        services provided. This includes any violation of The Terms and
        Conditions of this agreement or any action arising from the content that
        you publish on this site that infringes the intellectual property rights
        of any third party or content or communication that denigrates libels or
        invades the privacy of any third party or any action taken by you (or
        others associated with you) as a result of your use of this site.
      </p>
      <p>
        Natural Icons Pty. Ltd. and all related entities exclude all liability
        to the maximum extent permitted by law. To the extent that liability
        cannot be excluded, Natural Icons Pty. Ltd. and all related entity's
        liability to you for any cause whatsoever will at all times be limited
        to the amount paid, if any, by you to us for the service during the term
        of membership.
      </p>
      <h3>Governing Law</h3>
      <p>
        The Company and the Terms and Conditions for this site and iCompete
        Network shall be governed by and construed in accordance with the laws
        of the State of South Australia. The parties submit to the non-exclusive
        jurisdiction of the courts and tribunals of the governing law
        jurisdiction. Nothing in this clause is intended to oust the laws of the
        Commonwealth of Australia and the jurisdiction of the Federal Court of
        Australia.
      </p>
    </div>
  );
};

export default TermsAndConditions;
