import { CommunicatorProvider } from "../../context/Communicator";
import Filter from "./Filter";
import Header from "./Header";
import CommunicatorPanel from "./CommunicatorPanel";

const Communicator = () => {
  return (
    <CommunicatorProvider>
      <div class="main-wrapper communicator">
        {/* <Header /> */}

        <div className="communicator__content">
          <Filter />

          <div className="communicator__body">
            <CommunicatorPanel />
          </div>
        </div>
      </div>
    </CommunicatorProvider>
  );
};

export default Communicator;
