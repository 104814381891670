import React from "react";
import { FeedProvider } from "../../context/Feed";
import { FeedContent } from "./feed-content";

export const Feed = ({ showFilter }) => {
  return (
    <FeedProvider>
      <FeedContent showFilter={showFilter} />
    </FeedProvider>
  );
};
