import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../context/Auth";

const EventPaymentCancelPaypal = () => {
  const { eventName } = useParams();
  const { user } = useAuth();

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 ">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 align-item-center d-flex">
            <i class="fa-sharp fa-solid fa-circle-xmark font-xxl mr-11 text-red" />
            PAYPAL PAYMENT CANCELED
          </h2>
        </div>
      </div>

      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 membership-payment-success">
        <div class="card-body align-items-center p-0">
          <p>
            Dear <strong>{user?.user?.first_name}</strong>,
          </p>
          <p>
            Entry Fee payment canceled{" "}
            {eventName ? `for ${eventName} contest` : ""}. You can view/pay your
            Entry details any time in <strong>Events</strong> under{" "}
            <strong>
              <Link to="/pay-events">Pay Events</Link>
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default EventPaymentCancelPaypal;
