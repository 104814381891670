import React, { useMemo, useState } from "react";
import { useFeed } from "../../context/Feed";
import { nFormatter } from "../../utils/utils";
import { useComments } from "../../context/Comments";

export const Likes = ({ id, feedId, likes = {}, isComment = false }) => {
  const { likePost } = useFeed();
  const { likeComment } = useComments();
  const [loadingLike, setLoadingLike] = useState(false);
  const [loadingHeart, setLoadingHeart] = useState(false);

  //self can be => fan or heart
  const { fan, heart, self } = likes;

  // const count = fan + heart || 0;

  const isHeart = useMemo(() => self === "heart", [self]);
  const isFan = useMemo(() => self === "fan", [self]);

  const handleClick = (type, isDelete) => {
    if (type === 1) {
      setLoadingLike(true);
    } else {
      setLoadingHeart(true);
    }
    if (isComment) {
      likeComment({
        id,
        feedId,
        type,
        isDelete,
      })
        .then(() => {
          setLoadingLike(false);
          setLoadingHeart(false);
        })
        .catch(() => {
          setLoadingLike(false);
          setLoadingHeart(false);
        });
    } else {
      likePost({
        id,
        type,
        isDelete,
      })
        .then(() => {
          setLoadingLike(false);
          setLoadingHeart(false);
        })
        .catch(() => {
          setLoadingLike(false);
          setLoadingHeart(false);
        });
    }
  };

  return (
    <a class="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-3">
      {loadingLike ? (
        <img
          style={{ height: 26, width: 26 }}
          className="me-1"
          src="./images/preloader.svg"
        />
      ) : (
        <i
          onClick={() => handleClick(1, isFan)}
          className={`feather-thumbs-up text-white me-1 btn-round-xs font-xss cursor-pointer hover-effect
                ${isFan ? "bg-primary-gradiant" : "bg-dark1"}`}
        ></i>
      )}{" "}
      {loadingHeart ? (
        <img
          style={{ height: 26, width: 26 }}
          className="me-1"
          src="./images/preloader.svg"
        />
      ) : (
        <i
          onClick={() => handleClick(2, isHeart)}
          className={`feather-heart text-white me-2 btn-round-xs font-xss cursor-pointer hover-effect ${isHeart ? "bg-red-gradiant" : "bg-dark1"
            }`}
        ></i>
      )}
      {fan ? (
        <>
          <span className="underline">{nFormatter(fan)} Like{fan > 1 ? "s" : ""}</span>
        </>
      ) : null}

      {heart ? (
        <>
          {fan ? <span className="text-grey-500">&nbsp; | &nbsp; </span> : null} <span className="underline">{nFormatter(heart)} Heart{heart > 1 ? "s" : ""}</span>
        </>
      ) : null}
    </a>
  );
};
