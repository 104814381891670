export const storeData = async (key, data, isJson) => {
  try {
    await localStorage.setItem(key, isJson ? JSON.stringify(data) : data);
  } catch (error) {
    // Error saving data
  }
};

export const retrieveData = async (key, isJson) => {
  try {
    const data = await localStorage.getItem(key);
    if (isJson) {
      return JSON.parse(data);
    }
    return data;
  } catch (error) {
    return null;
  }
};

export const clearData = async (key) => {
  try {
    await localStorage.removeItem(key);
  } catch (error) {
    // Error clearing data
  }
};

const prefix = "icn_member";
export const ASYNC_STORAGE_KEYS = {
  LOGIN_DATA: prefix + "login_data",
  ACCESS_TOKEN: prefix + "access_token",
};
