import { useEffect, useRef, useState } from "react";
import { API, endpoints } from "../api";
import { Error } from "../components";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";
import Content from "../components/content";
import { useNavigate } from "react-router-dom";
const google = window.google;

const Business = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [businessData, setData] = useState({});
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [error, setError] = useState("");
  const canLoadLocation = useRef();
  const locationLoaded = useRef();

  const [termsError, setTermsError] = useState(false);
  const [termsChecked, setTermChecked] = useState(false);
  const handleTermsCheck = (e) => {
    setTermChecked(e.target.checked);
    if (e.target.checked) {
      setTermsError(false);
    }
  };

  useEffect(() => {
    if (canLoadLocation?.current && !locationLoaded?.current) {
      setTimeout(() => loadAutoComplete(), 100);
      locationLoaded.current = true
    }

  }, [canLoadLocation?.current, locationLoaded?.current, businessData])

  const { showToast, showGlobalLoader: setLoading, user, subscriptionData } = useAuth();

  const getData = () => {
    API.get({ route: endpoints.BUSINESS_TYPE })
      .then((data) => {
        const {
          business_map = [],
          updated_at,
          user_id,
          status,
          created_at,
          ...reset
        } = data?.data?.business || {};
        setData({ ...reset });
        console.log("business_map =>", business_map)
        setSelectedTypes(business_map)
        setBusinessTypes(data?.data?.business_type);
        setTimeout(() => { canLoadLocation.current = true }, 100);
      })
      .finally(() => setPageLoading(false));
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !(user?.user?.first_name &&
        user?.user?.date_of_birth &&
        user?.user?.occupation &&
        user?.user?.country_code &&
        user?.user?.country
      )
    ) {

      navigate("/edit-profile/continue");
      showToast({
        text: strings.completeProfile1,
        type: toastType.ERROR,
      });

      return
    }

    if (!subscriptionData?.userSubscribed) {
      navigate("/subscription");
      showToast({
        text: strings.purchaseSubscription,
        type: toastType.ERROR,
      });
      return
    }

    if (!termsChecked) return setTermsError(true);
    businessData.business_name = e?.target?.elements?.business_name?.value;
    businessData.phone_number = e?.target?.elements?.phone_number?.value;
    businessData.website = e?.target?.elements?.website?.value;
    businessData.email = e?.target?.elements?.email?.value;
    businessData.description = e?.target?.elements?.description?.value;


    setError("");
    setLoading(true);
    var data = new FormData();
    Object.keys(businessData).forEach((key) => {
      data.append(key, businessData[key]);
    });

    [...new Set(selectedTypes)]?.forEach((val) => {
      data.append("business_type[]", val);
    });


    API.post({ route: endpoints.BUSINESS, data })
      .then((success) => {
        if (success.status)
          showToast({ text: strings.updatedBusiness })
        else
          showToast({ text: success.message, type: toastType.ERROR })
      })
      .catch((data) => {
        setError(errorToString(data?.response));
      })
      .finally(() => setLoading(false));
  };

  const handleCheckBoxChange = (e) => {
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setSelectedTypes([...selectedTypes, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      setSelectedTypes([...selectedTypes.filter((e) => e != value)]);
    }
  };

  let autocomplete = "";
  useEffect(() => {
    getData();
  }, []);

  const loadAutoComplete = () => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete-business"),
      {
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    let place = autocomplete?.getPlace();
    const address = {
      latitude: place?.geometry?.location?.lat(),
      longitude: place?.geometry?.location?.lng(),
    };

    address.street_address_line1 = place.formatted_address;

    let street_address = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
        case "sublocality_level_1":
        case "sublocality": {
          street_address = `${component.long_name}`;
          break;
        }
        case "route": {
          if (street_address) street_address += " ";
          street_address += component.long_name;
          break;
        }
        case "locality": {
          address.street_address_city = component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          // address.state = component.long_name;
          address.street_address_state_code = component.short_name;
          break;
        }
        case "country": {
          // address.country = component.long_name;
          address.street_address_country_code = component.short_name;
          break;
        }
        case "postal_code": {
          address.street_address_zipcode = `${component.long_name}`;
          break;
        }
      }
    }
    address.street_address_line2 = street_address;
    setData({ ...businessData, ...address });
  };

  return <Content loading={pageLoading}
    title="My Business"
    icon="fa-solid fa-chart-simple"
  >
    <Error error={error} />
    <form name="businessForm" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              I would like a free listing and advertising <span className="text-red">#</span> <br />
              In the Fitness Directory for the following services:
            </label>
            {businessTypes?.map(({ id, description }) => (
              <div
                key={id}
                class="card bg-transparent-card border-0 d-block mt-3"
              >
                <label class="toggle toggle-menu-color row d-flex left-line-toggle">
                  <span className="col">
                    <input
                      defaultValue={id}
                      defaultChecked={selectedTypes?.includes(
                        id
                      )}
                      name="business_type[]"
                      type="checkbox"
                      onChange={handleCheckBoxChange}
                    />
                    <span class="toggle-icon"></span>
                  </span>
                  <span class="col font-xssss mont-font fw-700">
                    {description}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-2">
            <label className="mont-font fw-600 font-xsss">
              Business Name
            </label>
            <input
              required
              name="business_name"
              defaultValue={businessData?.business_name}
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>

          <div className="form-group icon-input mb-2">
            <label className="mont-font fw-600 font-xsss">
              Location
            </label>
            <i
              style={{ top: 42 }}
              className="font-sm fa fa-map-marker text-grey-500 pe-0"
            />
            <input
              id="autocomplete-business"
              defaultValue={businessData?.street_address_line1}
              required
              type="text"
              className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
              placeholder="Find your address here ..."
            />
            {/* <input
                      id="autocomplete"
                      // defaultValue={value}
                      required
                      type="text"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Find your address here ..."
                    /> */}
          </div>
          <div className="form-group mb-2">
            <label className="mont-font fw-600 font-xsss">
              Phone Number
            </label>
            <input
              name="phone_number"
              defaultValue={businessData?.phone_number}
              type="tel"
              placeholder="Optional"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
          <div className="form-group mb-2">
            <label className="mont-font fw-600 font-xsss">
              Website
            </label>
            <input
              defaultValue={businessData?.website}
              name="website"
              type="text"
              placeholder="Optional"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
          <div className="form-group mb-2">
            <label className="mont-font fw-600 font-xsss">Email</label>
            <input
              defaultValue={businessData?.email}
              name="email"
              type="text"
              placeholder="Optional"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <label className="mont-font fw-600 font-xsss">
            Describe Your Business
          </label>
          <textarea
            name="description"
            className="form-control mb-0 p-3 h100 lh-16"
            rows={5}
            defaultValue={businessData?.description}
            placeholder="Optional - opportunity to tell your customers more about you!..."
          />
        </div>
      </div>

      <div
        className={`agreement-checkbox ${termsError ? "terms-error" : ""
          }`}
      >
        <input
          type="checkbox"
          className="mr-1"
          id="conditions"
          onChange={handleTermsCheck}
        />
        <label
          htmlFor="conditions"
          translate="ENTER_EVENT_DRUG_AGREE_LABEL"
          className="mont-font fw-600 font-xsss"
        >
          {" "}
          I am authorised to advertise business above.
        </label>
      </div>
      <div className="mont-font fw-600 font-xsss" style={{ marginLeft: 42 }}>
        I accept icompetenatural.com reserves the right to remove any
        listing, at its sole discretion, it considers objectionable for
        any reason and without notice.
        <div>
          <i>
            <span className="text-red">#</span> You must be a Premium Member to be listed. Please renew
            your membership when due to remain listed.
          </i>
        </div>
      </div>

      <div className="form-group mt-5 mb-1 btn-right">
        <button
          type="submit"
          className="w200 form-control text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0"
        >
          Save
        </button>
      </div>
    </form>
  </Content>
};

export default Business;
