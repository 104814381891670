import { Link } from "react-router-dom";
import EventSelect from "./event-select/event-select";
import { useEnterEvent } from "../../context/EnterEvent";
import strings from "../../utils/strings";

const Events = () => {
  const { editId } = useEnterEvent();
  return (
    <div class="card shadow-xss w-100 border-0 p-4 mb-3">
      <div class="card-body align-items-center p-0">
        <p className="text-current m-0">
          <strong>Step 2 of 6</strong>{" "}
          {/* {editId ? (
            <strong className="text-red">{strings.event.readonly}</strong>
          ) : null} */}
        </p>
        <p className="m-0 bold">
          {strings.event.select} <Link to="/events">Event Information</Link>)
        </p>

        {editId ? <EventSelect title="Select Domestic Event" /> : <>
          <EventSelect title="Select Domestic Event" type="isDomestic" />
          <EventSelect
            title="Select International Event"
            type="isInternational"
          />
          <EventSelect
            title="Select Transformation Event"
            type="isTransformation"
          />
        </>}


      </div>
    </div>
  );
};

export default Events;
