import { useEffect } from "react";
import { FeedLoader } from "../../components/feed/loader";
import { Post } from "../../components/feed/post";
import { useAuth } from "../../context/Auth";
import { useFeed } from "../../context/Feed";
import { Helmet } from 'react-helmet';

const SingePostMain = () => {
  const { feedData, loading } = useFeed();
  const { loginRedirectUrl } = useAuth();

  const post = feedData?.[0] || {}


  useEffect(() => {
    const { content: description, images } = post
    document.querySelector('meta[property="og:title"]').setAttribute("content", description);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description);
    if (images?.[0]?.image)
      document.querySelector('meta[property="og:image"]').setAttribute("content", images?.[0]?.image);
  }, [post]);


  return (
    <>
      {loading ? <FeedLoader /> : null}

      {feedData?.map((item) => {
        return (
          <>
            <Helmet>
              <title>{item?.content}</title>
              <meta name="description" content={item?.content} />
              {/* <meta name="keywords" content="react, meta tags, seo" />
              <meta name="author" content="Your Name" /> */}
              <meta property="og:title" content={item?.content} />
              <meta property="og:description" content={item?.content} />
              <meta property="og:image" content={item?.images?.[0]?.image} />
              <meta property="og:url" content={`${loginRedirectUrl}/post/${item.feed_id}`} />
              <meta name="twitter:title" content={item?.content} />
              <meta name="twitter:description" content={item?.content} />
              <meta name="twitter:image" content={item?.images?.[0]?.image} />
              {/* <meta name="twitter:card" content="summary_large_image" /> */}
            </Helmet>
            <Post key={`feed-item-${item.feed_id}`} {...item} />
          </>
        )
      })}
    </>
  );
};

export default SingePostMain;
