import moment from "moment";
import { useEnterEvent } from "../../../context/EnterEvent";
import { DivisionsTable } from "../divisions/divisions-table";
import PaymentOptions from "./payment-options";
import strings from "../../../utils/strings";
import { LoaderSmall } from "../../../components";

const ConfirmDetails = ({ enrolId, isViewEntry }) => {
  const {
    enrollData,
    enrollEvent,
    selectedEvent,
    agreeRef,
    termsChecked,
    setTermsError,
    currency,
    eventVideoFee,
    editId,
  } = useEnterEvent();

  const handleRegister = () => {
    if (termsChecked || enrolId) {
      enrollEvent();
    } else {
      agreeRef?.current?.scrollIntoView();
      setTermsError(true);
    }
  };

  if (isViewEntry && !enrollData?.amount) {
    // if (!selectedEvent?.eventTitle) {
    //   return <strong>No Data to show</strong>
    // }
    return <LoaderSmall />
  }

  const due = (parseFloat(enrollData?.amount).toFixed(2) - parseFloat(selectedEvent?.actions?.paid).toFixed(2)).toFixed(2);

  return (
    <div class={isViewEntry ? null : "card shadow-xss w-100 border-0 p-4 mb-3"}>
      <div class={isViewEntry ? null : "card-body align-items-center p-0"}>
        {enrolId || isViewEntry ? null : <p className="text-current m-0">
          <strong>Step 6 of 6</strong>
        </p>
        }
        <p className="mt-2 text-cyan">
          <strong>
            {editId ? strings.event.confirm.edit : strings.event.confirm.add}
          </strong>
        </p>

        {selectedEvent ? (
          <h5 className="mt-3 mb-3">
            <strong>
              <u>
                {selectedEvent?.eventTitle}, {selectedEvent?.venue}, {selectedEvent?.location},{" "}
                {moment(selectedEvent?.startTime).format("DD MMM yyyy")}
              </u>
            </strong>
          </h5>
        ) : null}
        <DivisionsTable showRules />

        <div className="row">
          <div className="col-lg-6 hidden-sm">
            {enrolId ? null : <PaymentOptions />}
          </div>
          {enrollData?.amount ? (
            <div className="col-lg-6 text-right pt-2 sm-m-0">
              {selectedEvent?.videoAvailable === "yes" && eventVideoFee(enrollData?.doWantDvd) ? (
                <div className="p-1">
                  Event Video :{" "}
                  <span className="w75 d-inline-block">
                    {selectedEvent?.videoInclude === "yes"
                      ? "Included"
                      : `${currency}${eventVideoFee(enrollData?.doWantDvd)}`}
                  </span>
                </div>
              ) : null}
              {enrollData?.additionalServiceId?.map(
                ({ serviceFeeYes, serviceFeeNo }, index) => {
                  // eventsAdditionalServices;
                  if (!serviceFeeNo && !serviceFeeYes) return
                  return (
                    <div key={`confirm-details-${index}`} className="p-1">
                      {
                        selectedEvent?.eventsAdditionalServices[index]
                          ?.description
                      }{" "}
                      :
                      <span className="w75 d-inline-block">
                        {currency}{serviceFeeYes || serviceFeeNo}
                      </span>
                    </div>
                  );
                }
              )}
              <span className="total-price d-inline-block mt-3">
                Total: {selectedEvent?.eventCurrency?.currency} {currency}
                {parseFloat(parseFloat(enrollData?.amount).toFixed(2)).toLocaleString("en-US")}
              </span>
              {
                selectedEvent?.actions?.paid && due ? <div>
                  <div className="price-paid">Paid :{currency}{parseFloat(selectedEvent?.actions?.paid).toLocaleString("en-US")}</div>
                  <div className="price-due">Total Due : {currency}{parseFloat(due).toLocaleString("en-US")}</div>
                </div> : null
              }
            </div>
          ) : null}
        </div>

        <div className="show-sm mt-2">
          {enrolId ? null : <PaymentOptions />}
        </div>

        {isViewEntry ? null : <div className="form-group mt-5 mb-1 btn-right">
          <button
            type="submit"
            className="w200 form-control text-center style2-input text-white fw-600 bg-cyan border-0 p-0"
            disabled={!enrollData?.amount || !enrollData?.division?.length}
            onClick={handleRegister}
          >
            {editId ? "Update Event Entry" : "Register Now"}
          </button>
        </div>}
      </div>
    </div>
  );
};

export default ConfirmDetails;
