import React, { useState } from "react";
import { API, endpoints } from "../api";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";
import { Modal } from "./";
import LocationPicker from "./location-picker";

export const LocationModal = () => {
  const {
    showLocationModal,
    setLocationModal,
    user: mainUserData,
    updateUser,
    showToast,
    showGlobalLoader: setLoading,
  } = useAuth(false);
  const [address, setAddress] = useState();

  const updateLocation = () => {
    if (address) {
      setLoading(true);

      var data = new FormData();

      Object.keys(address).forEach((key) => {
        data.append(key, address[key]);
      });

      API.post({ route: endpoints.UPDATE_LOCATION, data })
        .then((data) => {
          updateUser({ ...mainUserData, user: data?.data });
          setLocationModal(false);
        })
        .catch((data) => {
          showToast({
            text: errorToString(data?.response),
            type: toastType.ERROR,
          });
        })
        .finally(() => setLoading(false));
    } else {
      showToast({
        text: strings.fill,
        type: toastType.ERROR,
      });
    }
  };

  return (
    <>
      <Modal
        show={showLocationModal}
        canCloseOnBackdrop={false}
        close={() => setLocationModal(false)}
      >
        <LocationPicker setAddress={setAddress} />
        {address ? (
          <div className="form-group mt-4 mb-2">
            <button
              onClick={() => updateLocation(false)}
              className="form-control text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0 "
            >
              Update Address
            </button>
          </div>
        ) : null}
      </Modal>
    </>
  );
};
