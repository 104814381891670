import { useMemo } from "react";

const ErrorPage = () => {
  const error = useMemo(() =>
    decodeURI(window.location.href.split("error=")[1])
  );

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 ">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 align-item-center d-flex">
            <i class="fa-sharp fa-solid fa-triangle-exclamation font-xl mr-11 text-red" />
            Error
          </h2>
        </div>
      </div>

      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 membership-payment-success">
        <div class="card-body align-items-center p-0">{error}</div>
      </div>
    </>
  );
};

export default ErrorPage;
