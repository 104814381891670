import { useState } from "react";
import { API, endpoints } from "../api";
import { Password } from "../components/authorization/password";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { showToast, showGlobalLoader: setLoading } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = new FormData();
    data.append("current_password", password);
    data.append("new_password", newPassword);
    data.append("new_password_confirmation", confirmPassword);

    API.post({ route: endpoints.CHANGE_PASSWORD, data })
      .then((data) => {
        if (data?.status)
          showToast({ text: data?.message || strings.changePass });
        else
          showToast({ text: data?.message || strings.changePass, type: toastType.ERROR, });
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => {
        setLoading(false);
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      });
  };

  return (
    <div className="small-content">
      <div>
        <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
          <div class="card-body d-flex align-items-center p-0">
            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">
              <i class="text-white font-md me-2 btn-round-sm bg-blue-gradiant fa-solid fa-key" />
              Change Password
            </h2>
          </div>
        </div>
        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
          <form
            onSubmit={handleSubmit}
            className="card-body p-lg-5 p-4 w-100 border-0 "
          >
            <Password
              value={password}
              update={setPassword}
              placeholder="Current Password *"
            />
            <Password
              value={newPassword}
              update={setNewPassword}
              placeholder="New Password *"
            />
            <Password
              value={confirmPassword}
              update={setConfirmPassword}
              placeholder="Confirm Password *"
            />
            <div className="h-center form-group mt-5 mb-1 btn-right1">
              <button
                type="submit"
                className="w200 form-control text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0"
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
