import React from "react";
// import Ad from "./ad";
import RightEvents from "./right-events";

export const SidebarRight = () => {
  return (
    <div className="home-right" style={{ maxWidth: 264 }}>
      <a
        title="Become a Promoter"
        target="_blank"
        class="card w-100 shadow-xss rounded-xxl border-0 mb-3 bg-none"
      >
        <img className="rounded-xxl" src="./images/advert.jpg" />
      </a>
      {/* <a
        href="https://onfit.edu.au/courses/personal-professional-development/bodybuilding-contest-prep-diet/"
        target="_blank"
        class="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 bg-none"
      >
        <img src="./images/advert1.jpg" className="rounded-xxl" />
      </a> */}
      {/* <RightEvents /> */}
      {/* <Ad path="/37678685/IC_Sidebar_300X250" width={300} height={250} /> */}
    </div>
  );
};
