import React, { createContext, useContext, useEffect, useState } from "react";
import {
    ASYNC_STORAGE_KEYS,
    retrieveData,
    storeData
} from "../../utils/storage";
import Login from "./login";

export const AuthContext = createContext({
    loading: true,
    user: "",

    setUser: () => { },
    updateUser: () => { },
    removeUser: () => { },

    userCheck: (_fun) => { },

});

export const AuthProvider = (props) => {
    const [user, setAppUser] = useState(null);
    const [loading, setLoading] = useState(true);


    const setUser = (user) => {
        setLoading(false);
        setAppUser(user);
    };


    // useEffect(() => {
    //     if (user?.user) {
    //         getUserData();
    //     }
    // }, []);

    // const getUserData = () => {
    //     API.get({ route: endpoints.PROFILE }).then((data) => {
    //         updateUser({ ...user, user: data?.data });
    //     });
    // };

    const updateUser = (user) => {
        storeData(ASYNC_STORAGE_KEYS.LOGIN_DATA1, user, true);
        storeData(ASYNC_STORAGE_KEYS.LOGIN_DATA, user, true);
        setAppUser(user);
    };

    const removeUser = () => {
        localStorage.clear();
        setAppUser(null);
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const getUserOnInit = async () => {
        const u = await retrieveData(ASYNC_STORAGE_KEYS.LOGIN_DATA1, true)
        // console.log("getUserOnInit => ", u)

        setUser(u);
    };

    const userCheck = (fun, errFun) => {
        if (user) {
            fun();
        } else {
            errFun?.();
        }
    };

    useEffect(() => {
        getUserOnInit();
    }, []);


    return (
        <AuthContext.Provider
            value={{
                loading,
                user,
                setUser,
                updateUser,
                removeUser,

                userCheck,
            }}
        >
            {loading ? <></> : user ? props?.children : <Login />}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
