import { memo } from "react";
import { useMessages } from "../../context/Messages";
import { Link } from "react-router-dom";

const ChatListing = () => {
  const { chatListing, listLoading: loading, setChat, chat } = useMessages();

  return (
    <div className="middle-sidebar-right-content bg-white shadow-lg rounded-3">
      {loading ? (
        <div className="p-3">
          <div className="box shimmer">
            <div className="lines">
              <div className="line s_shimmer" />
              <div className="line s_shimmer" />
              <div className="line s_shimmer" />
              <div className="line s_shimmer" />
            </div>
          </div>
        </div>
      ) : (
        <div className="section full pe-3 ps-4 pt-4 pb-3 position-relative chat-listing">
          <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">
            Messages
          </h4>

          {chatListing?.length ? (
            <div className="list-group list-group-flush">
              {chatListing?.map(({ member, unseen, groupId }) => (
                <li
                  key={`chat-user-${member?.id}`}
                // onClick={() => {
                //   setChat({ groupId, member });
                // }}
                >
                  <Link
                    className={`cursor-pointer list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center ${member?.id === chat?.member?.id ? " bg-gray-gradiant" : ""}`}
                    to={`/messages/${member?.id}`}
                  >
                    <figure className="avatar float-left mb-0 me-2">
                      <img
                        src={member?.image}
                        alt="image"
                        className="w35 h35 r-35"
                      />
                    </figure>
                    <h3 className="fw-700 mb-0 mt-0">
                      <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat">
                        {member?.name}
                      </span>
                    </h3>
                    {unseen ? (
                      <span className="badge badge-primary text-white badge-pill fw-500 mt-0">
                        {unseen}
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
            </div>
          ) : (
            <div>No messages are there</div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(ChatListing);
