import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { Error } from "../error";
import { Success } from "../success";

export const Toast = () => {
  const { toast } = useAuth();

  return (
    <div className="my-toast-main">
      {toast?.map((data, index) => (
        <ShowToast key={`index-${index}`} {...data} />
      ))}
    </div>
  );
};

const ShowToast = ({ text, type, time }) => {
  const { toast, setToast } = useAuth();
  const [counter, setCounter] = useState();

  useEffect(() => {
    const int = setInterval(() => {
      setCounter(new Date().getTime());
    });

    return () => clearInterval(int);
  }, []);

  useEffect(() => {
    var a = new Date(time);
    var b = new Date();
    var difference = (b - a) / 1000;

    if (difference > 5) {
      close();
    }
  }, [counter, time]);

  const close = () => {
    // const fndIndex = toast.findIndex(({ text: t }) => t === text);
    setToast([...toast.filter(({ text: t }) => t !== text)]);
  };
  return type === "error" ? (
    <Error error={text} clear={close} />
  ) : (
    <Success msg={text} clear={close} />
  );
};
