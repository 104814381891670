import { memo, useEffect, useRef, useState } from "react";
import { API, endpoints } from "../../api";
import { useAuth } from "../../context/Auth";
import { errorToString } from "../../utils/utils";
import { toastType } from "../../utils/strings";
import moment from "moment";
import { useMessages } from "../../context/Messages";
import { Avatar } from "../avatar";

const ChatSingle = () => {
  const { chat, setChat, setReadCount, getUserProfile } = useMessages();

  const { showToast, user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [chatData, setChatData] = useState();
  const [sending, setSending] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const ref = useRef();

  const getUserDetails = () => {
    API.get({ route: `${endpoints.MESSAGE_LIST}?userId=${chat?.userId}` })
      .then((data) => {
        if (data?.data?.[0]) {
          setChat({
            ...chat,
            ...data?.data?.[0],
          });
          getChat({ groupId: data?.data?.[0]?.groupId });
          setLoading(false);
        } else {
          getUserProfile().finally(() => setLoading(false));
        }
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      });
  };

  const getChat = ({ groupId, lastMessage } = {}) => {
    if (!groupId && !chat?.groupId) {
      setLoading(false);
      setCanLoadMore(false);
      return;
    }
    let url = `${endpoints.MESSAGE_DETAIL}?groupId=${groupId || chat?.groupId}`;

    if (lastMessage) {
      setLoadingMore(true);

      url += `&lastMessageId=${lastMessage}`;
    }

    API.get({
      route: url,
    })
      .then((data) => {
        setReadCount(chat);
        if (data?.data?.length < 10) {
          setCanLoadMore(false);
        }
        if (lastMessage) {
          setChatData([...data?.data?.reverse(), ...chatData]);
        } else {
          setChatData(data?.data?.reverse());
          setTimeout(() => bottom());
        }
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => {
        if (lastMessage) setLoadingMore(false);
        else setLoading(false);
      });
  };

  const getData = () => {
    if (chat?.userId && !chat?.groupId) {
      getUserDetails();
    } else if (chat?.groupId) {
      getChat();
    }
  };

  useEffect(() => {
    setLoading(true);
    setChatData([]);
    setSending(false);
    setCanLoadMore(true);
    setLoadingMore(false);

    getData();
  }, [chat?.userId, chat?.groupId]);

  const send = (e) => {
    e.preventDefault();
    setSending(true);
    setTimeout(() => bottom());

    if (!chat?.groupId) setReadCount(chat); //add user in listing

    const msg = e?.target?.elements?.message?.value;

    API.post({
      route: endpoints.MESSAGE_SEND,
      params: {
        message: msg,
        recipient: chat?.userId || chat?.member?.id,
        groupId: "",
      },
    })
      .then((data) => {
        if (data?.data?.status || data?.data?.length) {
          setChatData(data?.data?.reverse());
          setTimeout(() => bottom());
          document.getElementById("sendForm").reset();

          if (data?.data?.length >= 10) {
            setCanLoadMore(true);
          }
        } else {
          showToast({
            text: data?.data?.message,
            type: toastType.ERROR,
          });
        }
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setSending(false));
  };

  const bottom = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const loadMore = () => {
    getChat({ lastMessage: chatData[0]?.id });
  };

  if (!chat || !(chat?.userId || chat?.groupId)) return;
  return (
    <div className="modal-popup-chat d-block">
      <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
        <div className="modal-popup-header w-100 border-bottom">
          <div className="card p-3 d-block border-0 d-block">
            {loading ? (
              <div className="box shimmer h50 m-0">
                <div className="lines p-0">
                  <div className="line s_shimmer h50" />
                </div>
              </div>
            ) : (
              <>
                {chat?.member?.image ? (
                  <figure className="avatar mb-0 float-left me-2">
                    <Avatar user={chat?.member} />
                  </figure>
                ) : null}
                <h5 className="fw-700 text-primary font-xssss mt-1 mb-1">
                  {chat?.member?.name}
                </h5>
              </>
            )}

            {/* <h4 className="text-grey-500 font-xsssss mt-0 mb-0">
              <span className="d-inline-block bg-success btn-round-xss m-0" />{" "}
              Available
            </h4> */}
          </div>
        </div>
        <div className="modal-popup-body w-100 p-3 h-auto" id="chatBody">
          {loading ? (
            <div className="box shimmer">
              <div className="lines">
                <div className="line s_shimmer" />
                <div className="line s_shimmer" />
                <div className="line s_shimmer" />
                <div className="line s_shimmer" />
              </div>
            </div>
          ) : (
            <>
              {canLoadMore && !loadingMore && chatData?.length > 9 ? (
                <a className="load-old-messages" onClick={loadMore}>
                  Load old messages
                </a>
              ) : null}

              {loadingMore ? (
                <>
                  <div
                    className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 mb-4 bg-grey rounded-xl float-right"
                    data-title=".dot-typing"
                  >
                    <div className="stage">
                      <div className="dot-typing" />
                    </div>
                  </div>
                  <div className="clearfix" />
                </>
              ) : null}

              {chatData?.length ? (
                chatData?.map(({ id, sender, content, createdAt }, index) => {
                  const chatUser =
                    sender === user?.user?.id
                      ? {
                        image: user?.user?.image,
                        name: `${user?.user?.first_name} ${user?.user?.last_name}`,
                        id: sender,
                      }
                      : { ...chat?.member };
                  return (
                    <div
                      className={
                        index + 1 < chatData?.length ? "border-bottom-xs" : ""
                      }
                      key={`message-${id}`}
                    >
                      <div className="row ">
                        <div className="font-xssss lh-24 fw-500 col-sm-10">
                          <div className="bg-transparent cursor-pointer list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">
                            <figure style={{ minWidth: 32 }} className="avatar float-left mb-0 me-2">
                              <Avatar user={chatUser} />
                            </figure>
                            <h3 className="fw-700 mb-0 mt-0">
                              <a className="font-xsssss text-current d-block">
                                {chatUser?.name}
                              </a>
                              <a className="font-xssss text-black d-block mt-1" dangerouslySetInnerHTML={{ __html: content }}>
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="date-break font-xsssss lh-24 fw-500 text-grey-500 mt-0 mb-2 col-sm-2">
                          {moment(createdAt).fromNow()}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>No messages are there.</div>
              )}

              {sending ? (
                <div
                  className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 mb-4 bg-grey rounded-xl float-right"
                  data-title=".dot-typing"
                >
                  <div className="stage">
                    <div className="dot-typing" />
                  </div>
                </div>
              ) : null}

              <div ref={ref} className="clearfix" />
            </>
          )}
        </div>
        <form
          onSubmit={send}
          id="sendForm"
          className="modal-popup-footer w-100 border-top"
        >
          <div className="card p-3 d-block border-0 d-block">
            <div className="form-group icon-right-input style1-input mb-0">
              <input
                style={{ paddingRight: 45 }}
                type="text"
                placeholder="Start typing.."
                className="form-control rounded-xl bg-greylight border-0 font-xssss fw-500 ps-3"
                required
                name="message"
                readOnly={sending}
                autoCapitalize="off"
                autoComplete="off"
                spellCheck="off"
              />
              <button
                className="feather-send text-grey-500 font-md send-chat-btn"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(ChatSingle);
