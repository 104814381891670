import React from "react";
import OwlCarousel from "react-owl-carousel";
import { ImageModal } from "../image-modal";
import "./slider.scss";

export const FeedSlider = ({ feed_id, images }) => {
  if (!images?.length) {
    return null;
  }

  if (images?.length === 1) {
    return (
      <div class="card-body p-0 mb-3 rounded-3 overflow-hidden">
        <ImageModal
          img={images[0]?.image || "https://via.placeholder.com/800x450.png"}
          btn={
            <img
              src={images[0]?.image || "https://via.placeholder.com/800x450.png"}
              alt="video"
              class="w-100 desktop-image"
            />
          }
        />

      </div>
    );
  }

  return <div style={{ marginBottom: 10, marginTop: 10 }}>
    <ImageFeed images={images.map(({ image }) => image)} />
  </div>
  return (
    <div style={{ marginBottom: 50 }}>
      <OwlCarousel
        // items={1}
        dots
        dotsEach
        // touchDrag
        // mouseDrag
        className="owl-carousel owl-theme"
        loop={false}
        margin={10}
        nav
      >
        {images.map(({ image }, index) => {
          return (
            <div key={`${feed_id}-image-${index}`} class="item">
              <ImageModal
                img={image}
                btn={
                  <img src={image} alt="img" />
                }
              />
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
};


const ImageFeed = ({ images }) => {
  const maxVisibleImages = 4;

  return (
    <ImageModal
      img={images}
      btn={
        <div className="image-feed">
          {images.slice(0, maxVisibleImages).map((image, index) => (
            <div key={index} className={`image-container image-${index + 1}`}>
              <img src={image} alt={`Image ${index + 1}`} />
              {index === maxVisibleImages - 1 && images.length > maxVisibleImages && (
                <div className="image-overlay">+{images.length - maxVisibleImages}</div>
              )}
            </div>
          ))}
        </div>
      }
    />

  );
};