import { useEffect, useState } from "react";
import { API, endpoints } from "../api";
import Content from "../components/content";

const Calendar = () => {
  const [loading, setLoading] = useState(false);
  const [calendarData, setData] = useState();

  const getData = () => {
    setLoading(true);
    API.get({ route: endpoints.CALENDER })
      .then((data) => {
        const result = data?.result;
        const newData = [];
        console.log("result => ", result);
        result?.forEach(function (element) {
          const newObj = {};
          Object.keys(element).forEach(function (key) {
            if (key === "CategoryName") {
              newObj.Name = element[key];
            } else if (typeof element[key] === "string") {
              const newKey = key.includes("Is")
                ? key.substring(2, key.length)
                : key;
              if (!newObj.keys) {
                newObj.keys = [];
              }
              if (!newKey.includes("id") && !newKey.includes("CategoryID"))
                newObj.keys.push(newKey);
            } else if (key === "events") {
              newObj[key] = element[key];

              if (!newObj.values) {
                newObj.values = [];
              }

              element.events.forEach(function (event) {
                const newVal = [];
                newObj.keys.forEach(function (keyName) {
                  newVal.push(event[keyName]);
                });
                newObj.values.push(newVal);
              });
            }
          });

          newData.push(newObj);
        });
        console.log("newData => ", newData);
        setData(newData);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return <Content
    title="Full Calendar"
    icon="fa-solid fa-calendar-days"
    loading={loading}
  >
    <div class="full-calendar fw-600 font-xsss">
      <div class="row">
        <div class="col-xs-12 my-history">
          {calendarData?.map((calendar, index) => {
            return (
              <div
                key={`Calendar-${index}`}
                class="table-responsive calendar-table"
              >
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th colspan="20">
                        <h4 className="bold-red">{calendar?.Name}</h4>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      {calendar?.keys?.map((col, index1) => {
                        return (
                          <th
                            key={`Calendar-${index}-th-${index1}`}
                            className="bold-red"
                          >
                            {col}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {calendar?.values?.map((cols, index2) => (
                      <tr key={`Calendar-${index}-tr-${index2}`}>
                        {cols?.map((col, index3) => (
                          <td
                            key={`Calendar-${index}-tr-${index2}-tr-${index3}`}
                            className={
                              index3 === 0 &&
                              calendar?.events[index2]?.StrikeOff === "1" &&
                              "line-through-td"
                            }
                          >
                            {col}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </Content>
};

export default Calendar;
