import { createContext, useContext } from "react";
import { API, endpoints } from "../api";
import { useFeed } from "./Feed";
import { useAuth } from "./Auth";
import strings from "../utils/strings";

export const CommentsContext = createContext({
  likeComment: (_id, _type, _delete) => { },
  deleteComment: (_data) => { },
  updateComment: (_data) => { },
});

export const CommentsProvider = (props) => {
  const { feedData, setFeedData } = useFeed();
  const { userCheck, showToast } = useAuth();

  const updateCommentObjInFeed = (feedId, newData) => {
    const index = feedData?.findIndex((feed) => feed.feed_id === feedId);
    if (index >= 0) {
      feedData[index] = { ...feedData[index], ...newData };
      setFeedData([...feedData]);
    }
  };

  const likeComment = ({ id, feedId, type = 1, isDelete = false }) => {
    return new Promise((resolve, reject) => {
      userCheck(
        () => {
          var data = new FormData();
          data.append("comment_id", id);
          if (type) data.append("like_type", type);

          let route = endpoints.LIKE_COMMENT;
          if (isDelete) {
            route = endpoints.LIKE_COMMENT_DELETE;
          }
          API.post({ route, data })
            .then((data) => {
              if (isDelete) showToast({ text: strings.dislikeComment });
              if (type === 1) showToast({ text: strings.likeComment });
              else showToast({ text: strings.loveComment });

              const index = feedData?.findIndex(
                (feed) => feed.feed_id === (feedId || id)
              );

              if (index >= 0) {
                const comments = feedData[index].comments;

                const commentIndex = comments?.findIndex(
                  (comment) => comment.id === id
                );

                if (commentIndex >= 0) {
                  comments[commentIndex] = {
                    ...comments[commentIndex],
                    ...data?.data,
                  };
                }

                feedData[index] = { ...feedData[index], comments };
                setFeedData([...feedData]);
              }
              resolve();
            })
            .catch(() => reject());
        },
        () => reject()
      );
    });
  };

  const deleteComment = ({ id, feedId }) => {
    userCheck(() => {
      var data = new FormData();
      data.append("comment_id", id);
      data.append("feed_id", feedId);
      showToast({ text: strings.deleteComment });
      API.post({ route: endpoints.COMMENT_DELETE, data }).then((data) => {
        updateCommentObjInFeed(feedId, data?.data);
      });
    });
  };

  const updateComment = ({ id, feedId, comment }) => {
    return new Promise((resolve, reject) => {
      userCheck(() => {
        var data = new FormData();
        data.append("comment_id", id);
        data.append("feed_id", feedId);
        data.append("comment", comment);
        showToast({ text: strings.updatedComment });
        API.post({ route: endpoints.COMMENT_UPDATE, data }).then((data) => {
          updateCommentObjInFeed(feedId, data?.data);
          setTimeout(() => resolve(data?.data))
        }).catch(reject)
      }, reject)
    })
  };

  return (
    <CommentsContext.Provider
      value={{
        likeComment,
        deleteComment,
        updateComment
      }}
    >
      {props.children}
    </CommentsContext.Provider>
  );
};

export const useComments = () => useContext(CommentsContext);
