import { useEffect, useState } from "react";
import Moment from "react-moment";
import { API, endpoints } from "../api";
import { Error } from "../components";
import { useAuth } from "../context/Auth";
import { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";
import Content from "../components/content";

const Events = () => {
  const {
    showToast,
    showGlobalLoader: setLoading,
    globalLoader: loading,
  } = useAuth();
  const [events, setEvents] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getData = () => {
    setLoading(true);
    API.get({ route: `${endpoints.EVENT}?all=1` })
      .then((data) => {
        setEvents(data?.result);
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return <Content
    title="Event Information"
    icon="fa-solid fa-info"
    loading={loading}
  >
    {events?.length ? <table className="custom-table">
      <tr>
        <th>Event</th>
        <th className="hidden-sm">Start Date</th>
        <th>
          <span className="hidden-sm">Information</span>
          <span className="show-sm">Info</span>
        </th>
      </tr>
      {events?.map(
        ({ eventTitle, startTime, posterFileSize, posterFile }) => (
          <tr>
            <td>{eventTitle}
              <br />
              <div className="show-sm">
                (<Moment format="DD MMM YYYY">{startTime}</Moment>)
              </div>
            </td>
            <td className="hidden-sm">
              <Moment format="DD MMM YYYY">{startTime}</Moment>
            </td>
            <td>
              {posterFile ? (
                <a
                  href={posterFile}
                  target="_blank"
                  download
                >
                  <i className="fa fa-download" />
                </a>
              ) : "Pending"}
            </td>
          </tr>
        )
      )}
    </table> : null}
    {!events?.length && !loading && loaded ? <p className="font-xs">No Data to show</p> : null}
  </Content>
};

export default Events;
