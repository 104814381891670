import { startCase } from "lodash";

export function createTagDropdown(editorElement) {
  if (editorElement) {
    const tagsPickerItems = Array.prototype.slice.call(
      document.querySelectorAll(".ql-tags .ql-picker-item")
    );

    tagsPickerItems.forEach(
      (item) =>
        (item.textContent = startCase(item.dataset.value.match(/\{(.*?)\}/)[1]))
    );

    document.querySelector(".ql-tags .ql-picker-label").innerHTML = "Tags";
  }
}
