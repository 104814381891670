import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { API, endpoints } from "../../api";
import { useAuth } from "../../context/Auth";
import { toastType } from "../../utils/strings";
import { errorToString } from "../../utils/utils";
import BusinessTypes from "./business-types";
import MapListing from "./listing";
import MyMap from "./map";
import Content from "../../components/content";
const google = window.google;

const FitnessDirectory = () => {
  let autocomplete = "";

  const [address, setAddress] = useState({});
  const [businessTypes, setBusinessTypes] = useState([]);
  const { showToast, showGlobalLoader: setLoading } = useAuth();
  const [selected, setSelected] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  const delayedHandleChange = debounce(
    (adds, arr) => getSearchData(adds, arr),
    500
  );

  useEffect(() => {
    delayedHandleChange(address, selected);
  }, [address, selected]);

  const getSearchData = (adds, arr) => {
    let url = `${endpoints.BUSINESS}?`;

    Object.keys(adds).forEach((key) => {
      const val = adds[key];
      if (val) url += `${key}=${val}&`;
    });

    arr.forEach((val) => {
      url += `business_type[]=${val}&`;
    });

    console.log("url => ", url);

    API.get({ route: url })
      .then((data) => {
        setBusinessTypes(data?.data?.business_type);
        setBusinesses(data?.data?.business);
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadAutoComplete();
  }, []);

  const loadAutoComplete = () => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete-business"),
      {
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    let place = autocomplete?.getPlace();
    const address = {
      latitude: place?.geometry?.location?.lat(),
      longitude: place?.geometry?.location?.lng(),
    };

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "administrative_area_level_1": {
          address.state_code = component.short_name;
          break;
        }
        case "country": {
          address.country_code = component.short_name;
          break;
        }
      }
    }

    setAddress({ ...address });
  };

  return (
    <Content title="Fitness Directory" icon="fa-solid fa-location-dot">
      <div className="map">

        <form className="map-autocomplete d-flex" name="businessForm">
          <i className="font-sm fa fa-search text-grey-500 pe-0 mr-1" />
          <div className="form-group" style={{ flexGrow: 1 }}>
            <input
              id="autocomplete-business"
              style={{ borderRadius: 0 }}
              required
              type="text"
              className="style2-input form-control text-grey-900 font-xsss fw-600"
              placeholder="Find your address here ..."
            />
          </div>
        </form>

        <BusinessTypes
          businessTypes={businessTypes}
          selected={selected}
          setSelected={setSelected}
        />

        <div className="map-main">
          <div style={{ width: "100%", height: "calc(100vh - 350px)" }} id="map">
            <MyMap businesses={businesses} address={address} />
          </div>
          {/* <MapListing businesses={businesses} /> */}
        </div>
      </div>
      <div className="mt-4">
        {
          businesses?.map(
            ({
              user_id,
              business_name,
              description,
              email,
              street_address_line1: address,
              website,
              phone_number,
              distance
            }) => (<div key={user_id} className="custom-box row p-4">
              <div className="col-sm-3 col-md-2">
                <img className="w-75 rounded-full" src="./images/default-avatar.png" />
              </div>
              <div className="col-sm-4 col-md-5 font-xsss text-black">
                <div>
                  <strong>{business_name}</strong>
                </div>
                <div>{description}</div>
                <div>{address}</div>
                <div>Distance: {parseFloat(distance || 0).toFixed(1)} miles</div>
              </div>
            </div>
            ))
        }
      </div>
    </Content>
  );
};

export default FitnessDirectory;
