import React, { useState } from "react";
import { API, endpoints } from "../api";
import { useAuth } from "../context/Auth";
import Modal from "./modal";

export const HeaderCounts = () => {
  const [showModal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState();

  const { counts } = useAuth();

  const countryClick = () => {
    setModal(true);
    if (!countries) {
      setLoading(true);
      API.get({ route: endpoints.COUNTRIES })
        .then((data) => {
          setCountries(data?.data);
        })
        .finally(() => setLoading(false));
    }
  };

  if (!counts) return;
  return (
    <>
      <div className="counters-wrapper text-center  menu-icon center-menu-icon">
        <div className="members-counter-wrapper">
          <p className="counter-desc">Members</p>
          <div className="members-count ng-binding">
            {counts?.members?.toLocaleString("en-US")}
          </div>
        </div>
        <div
          className="countries-counter-wrapper"
          ng-if="countries"
          onClick={countryClick}
        >
          <p className="counter-desc">Countries</p>
          <div className="country-count ng-binding">{counts?.countries}</div>
        </div>
      </div>
      <Modal show={showModal} close={() => setModal(false)}>
        <div className="card shadow-none border-0 ms-auto me-auto">
          <div className="card-body rounded-0 text-left">
            <h3 className="fw-700 display1-size display2-md-size mb-4 text-center">
              Countries List
            </h3>

            {loading ? (
              <div className="loader-div">
                <img src="./images/preloader.svg" />
              </div>
            ) : (
              <ul>
                {countries?.map(({ Description, CodeCountryId }) => (
                  <li key={CodeCountryId}>{Description}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
