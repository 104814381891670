import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { shareLink } from "../../utils/utils";
import { baseURL } from "../../api";

const MembershipPaymentSuccess = () => {
  const { membershipNumber, expiryDate, feedId } = useParams();
  const { loginRedirectUrl } = useAuth()
  console.log("membershipNumber, expiryDate  =>", membershipNumber, expiryDate);

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 ">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 align-item-center d-flex">
            <i class="fa-sharp fa-solid fa-circle-check font-xxl mr-11 text-share" />
            Membership Payment Success
          </h2>
        </div>
      </div>

      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 membership-payment-success">
        <div class="card-body align-items-center p-0">
          <p>
            Congratulations! Your upgrade to Premium Membership was successfully
            completed.
          </p>
          <p>
            Your Membership Number is {membershipNumber} and expires on{" "}
            <Moment format="DD MMM YYYY">{expiryDate}</Moment>
          </p>
          <div class="card-body mt-3 mb-3 rounded-3 overflow-hidden">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <img
                  src={"../images/premium-membership-success.jpg"}
                  alt="video"
                  class="w-100"
                />
              </div>
            </div>
            <div className="row mt-3 justify-content-center">
              <div className="col-lg-8 d-flex justify-content-center">
                <a
                  className="btn-share"
                  // href={`https://www.facebook.com/sharer/sharer.php?u=${loginRedirectUrl}/post/${feedId}`}
                  onClick={() => shareLink({ id: feedId })}
                  href={`https://www.facebook.com/sharer/sharer.php?u=${baseURL}/api/v1/feed/share/${feedId}/2`}
                  target="_blank"
                >
                  <div class="share-logos">
                    <div class="logo-wrapper">
                      <img
                        src="./images/icon-icompete-white.png"
                        alt="iCompete logo"
                      />
                    </div>
                    <div class="logo-wrapper">
                      <img
                        src="./images/icon-fb-white.png"
                        alt="Facebook logo"
                      />
                    </div>
                  </div>
                  <span className="hidden-sm">Share to Facebook from your iCompete Wall</span>
                  <span className="show-sm">Share to Facebook</span>
                </a>
              </div>
            </div>
          </div>

          <p>
            As a valued member of the iCompete Natural you will receive a
            Welcome email which includes:
          </p>
          <p>(HINT: Look in your junk or spam folder if not received)</p>
          <ul className="text-red" style={{ margin: "20px 50px" }}>
            <li style={{ listStyle: "disc inside" }}>
              Confirmation of the information you supplied us
            </li>
            <li style={{ listStyle: "disc inside" }}>
              Exclusive eBook The Competitor Encyclopedia
            </li>
            <li style={{ listStyle: "disc inside" }}>Tax Invoice</li>
          </ul>
          <p>
            To enter a contest go to{" "}
            <Link to="/enter-new-event">Enter New Event</Link>
          </p>
          <p>
            If you require any assistance please{" "}
            <Link to="/contact">contact us</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default MembershipPaymentSuccess;
