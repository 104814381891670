import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { API, endpoints } from "../api";
import Content from "../components/content";
import LocationPicker from "../components/location-picker";
import { useAuth } from "../context/Auth";
import strings, { toastType } from "../utils/strings";
import moment from "moment";
import { isAfter } from "date-fns";

const EditProfile = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    height_unit: "Metric",
  });

  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [address, setAddress] = useState({});
  const [isSubmitted, setSubmitted] = useState(false);

  const {
    userCheck,
    updateUser,
    user: mainUserData,
    subscriptionData,
    showToast,
    showGlobalLoader,
  } = useAuth();

  const getUserData = () => {
    API.get({ route: endpoints.PROFILE })
      .then((data) => {
        updateUser({ ...mainUserData, user: data?.data });
        setUser(data?.data);
        setProfileData({ ...profileData, ...data?.data });

        setTimeout(() => errorClass(), 1000)

        if (data?.data?.image != "0") setImage(data?.data?.image);
      })
      .finally(() => setLoading(false));
  };

  const [minDate, setMinDate] = useState();
  const calculateMinDate = () => {
    // Get today's date
    const today = new Date();

    // Calculate the date for 15 years ago
    const minAgeDate = new Date(today.getFullYear() - 15, today.getMonth(), today.getDate());

    // Set the 'min' attribute to disable older dates (minimum age should be 15 years)
    setMinDate(minAgeDate.toISOString().split('T')[0]);
  }

  useEffect(() => {
    calculateMinDate()
    userCheck(
      () => getUserData(),
      () => setLoading(false)
    );
  }, []);

  const onChange = (event) => {
    const files = event?.target?.files || [];
    if (files?.length) {
      setFile(files[0]);
      setImage(URL.createObjectURL(files[0]));
    }
  };

  const updateProfile = (event) => {
    event.preventDefault();

    if (!(address?.country || profileData?.country) || !(address?.country_code || profileData?.country_code)) {
      showToast({ text: strings.noCountry, type: toastType.ERROR });
      return
    }

    // Check if the date_of_birth is not greater than minDate (15 years ago)
    if (profileData?.date_of_birth && isAfter(profileData?.date_of_birth, minDate)) {
      showToast({ text: 'Date of birth must be 15 years ago or earlier.', type: toastType.ERROR });
      return
    }

    showGlobalLoader(true);

    var data = new FormData();

    const finalData = {
      ...profileData,
      ...address,
    };

    Object.keys(finalData).forEach((key) => {
      if (key !== "image" && key !== "profile_pic")
        data.append(key, finalData[key] ? finalData[key] : "");
    });

    if (file) data.append("image", file);

    setLoading(true);
    API.post({ route: endpoints.UPDATE_PROFILE, data })
      .then((data) => {
        updateUser({ ...mainUserData, user: data?.data });
        setUser(data?.data);

        showToast({ text: strings.updatedProfile });
        if (params?.isContinue) navigate("/subscription");
      }).catch(e => {
        console.log('error => ', e?.response?.data?.message)

        showToast({ text: e?.response?.data?.message || 'There is some error while updating profile.', type: toastType.ERROR })
      })
      .finally(() => {
        setLoading(false);
        showGlobalLoader(false);
      });
  };

  const resetEmail = () => {
    showGlobalLoader(true);
    API.get({ route: endpoints.RESEND_EMAIL })
      .then((data) => {
        showToast({ text: data.message, type: data.status ? '' : toastType.ERROR });
      })
      .finally(() => {
        showGlobalLoader(false);
      });
  }

  const handleKeyUp = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const regex = /[^a-zA-Z\s-_]/g;

    // Replace invalid characters
    const sanitizedValue = value.replace(regex, '');

    if (sanitizedValue !== value) {
      setProfileData({
        ...profileData,
        [name]: sanitizedValue,
      });
    }
  };

  return <Content title="My Profile"
    icon="fa-solid fa-user"
  >

    <form onSubmit={updateProfile}>
      {!loading && !profileData?.email_verified ? <div class="col-md-12 verify-email text-danger">
        <p> Your email address has not been verified, please check your inbox for a verification email or
          {" "}
          <a href="javascript:;" class="text-danger underline cursor-pointer" onClick={resetEmail}>click here</a>
          {" "}
          to receive one. </p>
      </div> : null}

      <div className="row justify-content-center mb-5 mt-0">
        <div className="col-lg-4 text-center">
          <input
            onChange={onChange}
            type="file"
            name="file"
            id="file"
            className="input-file"
            accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
          />
          <label htmlFor="file" className="avatar ms-auto me-auto mb-0 mt-2 w100 position-relative">
            <img
              src={image || "https://via.placeholder.com/100x100.png"}
              alt="image"
              className="shadow-sm rounded-circle btn-round-100"
            />
            <i class="fa-regular fa-pen-to-square edit-profile-image"></i>
          </label>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-6 mb-2 mont-font fw-600 font-xsss">Membership Number</div>
        <div className="col-lg-6 mb-2">
          <input
            required
            value={user?.MembershipNumber}
            readOnly
            className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-6 mb-2 mont-font fw-600 font-xsss">Joined</div>
        <div className="col-lg-6 mb-2">
          <input
            required
            value={moment(user.created_at).format('DD-MM-YYYY')}
            readOnly
            type="email"
            className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
          />
        </div>
      </div>

      {subscriptionData?.expiryDate ?
        <div className="form-group row mb-3">
          <div className="col-lg-6 mb-2 mont-font fw-600 font-xsss">Premium Membership Expiry</div>
          <div className="col-lg-6 mb-2">
            <input
              required
              value={moment(subscriptionData?.expiryDate).format('DD-MM-YYYY')}
              readOnly
              type="email"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div> : null}


      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              First Name
            </label>
            <input
              name="first_name"
              onKeyUp={handleKeyUp}
              required
              value={profileData?.first_name}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  first_name: event.target.value,
                })
              }
              type="text"
              className={`style2-input ps-3 form-control text-grey-900 font-xsss fw-600
                  ${isSubmitted && !profileData?.first_name ? 'red-input' : ''}`}

            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Last Name</label>
            <input
              name="last_name"
              onKeyUp={handleKeyUp}
              required
              value={profileData?.last_name}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  last_name: event.target.value,
                })
              }
              type="text"
              className={`style2-input ps-3 form-control text-grey-900 font-xsss fw-600
                  ${isSubmitted && !profileData?.last_name ? 'red-input' : ''}`}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Preferred First Name {" "}
              <Tooltip

                className="my-tooltip"
                content="Your Preferred name will be used instead of your First Name (at events, in results and emails to you)."
              >
                <i
                  className={`btn-round-xs1 mt--1 bg-current me-2 fa-solid fa-info text-white font-xsssss`}
                />
              </Tooltip>
            </label>
            <input
              name="pref_first_name"
              onKeyUp={handleKeyUp}
              placeholder="Optional"
              value={profileData?.pref_first_name}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  pref_first_name: event.target.value,
                })
              }
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Mobile Number
            </label>
            <input
              required
              value={profileData?.mobile_number}
              onChange={(event) => {
                const limit = 15;
                setProfileData({
                  ...profileData,
                  mobile_number: event.target?.value?.slice(0, limit),
                })
              }
              }
              type="tel"
              min={1000000}
              className={`style2-input ps-3 form-control text-grey-900 font-xsss fw-600 
  ${isSubmitted && !profileData?.mobile_number ? 'red-input' : ''}
  ${isSubmitted && profileData?.mobile_number && profileData.mobile_number < 1000000 ? 'red-input' : ''}`}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Email{" "}
              <Tooltip
                className="my-tooltip"
                content="Near a contest avoid using workplace emails and use an email you will receive 24/7 as vital information may be sent anytime!"
              >
                <i
                  className={`btn-round-xs1 mt--1 bg-current me-2 fa-solid fa-info text-white font-xsssss`}
                />
              </Tooltip>
            </label>
            <input
              required
              value={profileData?.email}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  email: event.target.value,
                })
              }
              // readOnly
              type="email"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          {/**TODO: needs to work here */}
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Email Buddy{" "}
              <Tooltip
                className="my-tooltip"
                content="Nominate your Trainer etc., to receive a copy of your contest emails! Privacy laws require they provide their Membership number."
              >
                <i
                  className={`btn-round-xs1 mt--1 bg-current me-2 fa-solid fa-info text-white font-xsssss`}
                />
              </Tooltip>
            </label>
            <input
              placeholder="Optional"
              value={profileData?.buddy_email}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  buddy_email: event.target.value,
                })
              }
              type="number"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Gender</label>
            <select
              required
              value={profileData?.gender}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  gender: event.target.value,
                })
              }
              className={`style2-select ps-3 form-control text-grey-900 font-xsss fw-600
                  ${isSubmitted && !profileData?.gender ? 'red-input' : ''}`}
            >
              <option value="">-- Select Gender --</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss position-relative">
              Height
              {" "}
              <Tooltip
                className="my-tooltip"
                content="Height must be between 150 - 210 cm"
              >
                <i
                  className={`btn-round-xs1 mt--1 bg-current me-2 fa-solid fa-info text-white font-xsssss`}
                />
              </Tooltip>
              {/* <div style={{
                position: 'absolute', zIndex: 1111, top: -43, left: 55
              }}>
                <Tooltip
                  distance={-20}
                  className="my-tooltip"
                  content="Height must be between 150 - 210 cm"
                >
                  <i
                    className={`btn-round-xs1 mt--1 bg-current me-2 fa-solid fa-info text-white font-xsssss`}
                  />
                </Tooltip>
              </div> */}
            </label>
            <div className="position-relative with-content icon-input">
              <input
                min={150}
                max={210}
                value={profileData?.height}
                onChange={(event) =>
                  setProfileData({
                    ...profileData,
                    height: event.target.value,
                  })
                }
                required
                type="number"
                className={`height-input style2-input ps-3 form-control text-grey-900 font-xsss fw-500
                    ${isSubmitted && !profileData?.height ? 'red-input' : ''}`}
              />
              <i className="font-sm text-grey-500 pe-2 right-icon position-absolute"
                style={{ right: 1, marginTop: 1, bottom: 2 }}
              >
                cm
              </i>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Occupation</label>
            <select
              required
              value={profileData?.occupation}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  occupation: event.target.value,
                })
              }
              className={`style2-select ps-3 form-control text-grey-900 font-xsss fw-600
                  ${isSubmitted && !profileData?.occupation ? 'red-input' : ''}`}
            >
              <option value="">-- Select Occupation --</option>
              <option value="Within Health & Fitness">
                Within Health & Fitness
              </option>
              <option value="Outside Health & Fitness">
                Outside Health & Fitness
              </option>
            </select>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Date of birth{" "}
              <Tooltip
                className="my-tooltip"
                content={`You must be at least 15 years old. Please enter a birth date on or before ${moment(minDate).format('DD-MM-yyyy')}.`}
              >
                <i
                  className={`btn-round-xs1 mt--1 bg-current me-2 fa-solid fa-info text-white font-xsssss`}
                />
              </Tooltip>
            </label>
            <input
              max={minDate}
              required
              value={profileData?.date_of_birth}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  date_of_birth: event.target.value,
                })
              }
              type="date"
              className={`style2-input ps-3 form-control text-grey-900 font-xsss fw-600
                  ${isSubmitted && !profileData?.date_of_birth ? 'red-input' : ''}`}
            />
          </div>
        </div>
      </div>

      {loading ? null : (
        <LocationPicker
          showLabel
          data={{
            latitude: user?.latitude,
            longitude: user?.longitude,
            postal_address1: user?.postal_address1,
            postal_address2: user?.postal_address2,
            suburb_town: user?.suburb_town,
            state: user?.state,
            state_code: user?.state_code,
            country: user?.country,
            country_code: user?.country_code,
            postcode: user?.postcode,
          }}
          setAddress={setAddress}
          isSubmitted={isSubmitted}
          customIconStyle={{ fontSize: 12, marginTop: 8 }}
        />
      )}

      <div className="row">
        <div className="col-lg-12 mb-3">
          <label className="mont-font fw-600 font-xsss">About</label>
          <textarea
            className="form-control mb-0 p-3 h100 lh-16"
            rows={5}
            value={profileData?.about || ""}
            onChange={(event) =>
              setProfileData({
                ...profileData,
                about: event.target.value,
              })
            }
            placeholder="Optional. You can share information about yourself  that will appear on your individual wall."
            spellCheck="false"
          />
        </div>

        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Gym Representing
            </label>
            <input
              placeholder="Optional"
              value={profileData?.gym_representing}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  gym_representing: event.target.value,
                })
              }
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Trainer Representing
            </label>
            <input
              placeholder="Optional"
              value={profileData?.trainer_representing}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  trainer_representing: event.target.value,
                })
              }
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Sponsor Representing
            </label>
            <input
              placeholder="Optional"
              value={profileData?.sponsor_representing}
              onChange={(event) =>
                setProfileData({
                  ...profileData,
                  sponsor_representing: event.target.value,
                })
              }
              type="text"
              className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
            />
          </div>
        </div>


        {/* <div className="col-lg-12">
          <button
            type="submit"
            onClick={() => setSubmitted(true)}
            className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block border-0"
          >
            {params?.isContinue ? "Continue" : "Update Profile"}
          </button>
        </div> */}
      </div>
      <div className="form-group mt-5 mb-1 btn-right">
        <button
          type="submit"
          onClick={() => setSubmitted(true)}
          className="w200 form-control text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0"
        >
          {params?.isContinue ? "Continue" : "Update Profile"}
        </button>
      </div>
    </form>
  </Content>
};

export default EditProfile;

function errorClass() {
  const form = document.getElementById('profileForm');
  const inputs = [...document.querySelectorAll('input[required]'), ...document.querySelectorAll('select[required]')];

  const validateInputs = () => {
    inputs.forEach(input => {
      if (input.required && !input.value.trim()) {
        input.classList.add('red-input');
      } else {
        if (input.type === 'tel' && parseInt(input.value.trim()) < 1000000) {
          input.classList.add('red-input');
        } else if (input.classList.contains('height-input') && (parseInt(input.value.trim()) < 150 || parseInt(input.value.trim()) > 210)) {
          input.classList.add('red-input');
        } else {
          input.classList.remove('red-input');
        }
      }
    });
  };

  // Initial validation on page load
  validateInputs();

  // Validate on input change
  inputs.forEach(input => {
    input.addEventListener('input', () => {
      if (input.required && !input.value.trim()) {
        input.classList.add('red-input');
      } else {
        if (input.type === 'tel' && parseInt(input.value.trim()) < 1000000) {
          input.classList.add('red-input');
        } else if (input.classList.contains('height-input') && (parseInt(input.value.trim()) < 150 || parseInt(input.value.trim()) > 210)) {
          input.classList.add('red-input');
        } else {
          input.classList.remove('red-input');
        }
      }
    });
  });

  form?.addEventListener('submit', (event) => {
    event.preventDefault();
    validateInputs();
    if (!document.querySelector('.red-input')) {
      alert('Form is valid and ready for submission!');
      form.submit();
    }
  });
}
