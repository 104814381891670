export default {
  changePass: "Your Password updated successfully.",
  updatedProfile: "Profile updated successfully.",
  updatedBusiness: "Your business details updated successfully.",
  contactFormSubmitted:
    "Thank you, Your request submitted. We will respond as soon as possible.",
  newPost: "New post created successfully.",
  deletePost: "Post deleted successfully.",
  hidePost: "Post hidden successfully.",
  newComment: "New comment posted successfully.",
  deleteComment: "Comment deleted successfully.",
  updatedComment: "Comment updated successfully.",
  updatedPost: "Post updated successfully.",
  updatedCommentError: "Comment field is required.",
  updatedPosError: "Post field is required.",
  likeComment: "Comment Liked successfully.",
  loveComment: "Comment Loved successfully.",
  dislikeComment: "Comment disliked successfully.",
  fill: "Please fill all fields",
  reportDoping:
    "Thank you, your record was submitted successfully, we will take appropriate action.",

  completeProfile:
    "In order to purchase premium membership, please complete your profile first.",
  completeProfile1:
    "In order to list My Business, please complete your profile first.",

  purchaseSubscription:
    "In order to list My Business, upgrade to premium membership.",
  noNotifications: "There are no Notifications",

  copyPost: "Click to copy the link",
  copiedPost: "Link copied to clipboard",

  eventChange:
    "You are about to leave currently edited event.<br/>Your changes will be lost!",
  eventChangeSubTitle: "Are you sure you want to proceed?",

  eventDivisionWithdraw: "You are about to withdraw from this division.",

  eventEnrolled: "You successfully enrolled",

  payments: {
    inPerson: "In Person payments can only be made",
    rozar: "Credit/Debit Card, UPI, and netbanking payment is available from",
    stripe: "Credit/Debit Card, and netbanking payment is available from",
    paypal: "Paypal or Credit Card payments available from",
  },

  event: {
    select: "Select Event (View full event details in",
    confirm: {
      edit: "",
      add: "",
    },
    readonly: "- (As you are editing event so event select is readonly)",
    division: {
      max: "Max limit reached (withdraw a division to add new)",
    },
  },

  emailPreferences: "Email preferences updated.",

  subscription: {
    autoRenew: "Your subscription will auto renew",
    validTill: "Your subscription is valid till",
    expired: "Your subscription was expired on",
  },
  noCountry: "Please select an address from the dropdown to ensure accuracy.",

};

export const toastType = {
  ERROR: "error",
};
