import React, { useEffect, useState } from "react";
import { API, endpoints } from "../../../api";

import { Modal } from "../../../components";
import { useAuth as useAuthMain } from "../../../context/Auth";
import { toastType } from "../../../utils/strings";
import { errorToString } from "../../../utils/utils";

export default ({ event, updateData }) => {

    const { showToast, showGlobalLoader } = useAuthMain()
    const [open, setOpen] = useState(false);
    const [paymentData, setPaymentData] = useState({ eventId: event?.eventId, userId: event?.users?.id })

    useEffect(() => {
        setPaymentData({
            ...paymentData,
            eventId: event?.eventId,
            userId: event?.users?.id
        })
    }, [event?.eventId, event?.users?.id])

    const [image, setImage] = useState("");
    const [file, setFile] = useState(null);

    const onChange = (event) => {
        const files = event?.target?.files || [];
        if (files?.length) {
            setFile(files[0]);
            setImage(URL.createObjectURL(files[0]));
        }
    };

    const updatePaymentStatus = (e) => {
        e.preventDefault();

        // if (!file) {
        //     showToast({
        //         text: 'Please upload payment screenshot',
        //         type: toastType.ERROR,
        //     });
        //     return
        // }

        showGlobalLoader(true)
        var data = new FormData();

        Object.keys(paymentData).forEach((key) => {
            data.append(key, paymentData[key] ?? "");
        });

        if (file) data.append("attachment", file);

        API.post({ route: endpoints.OFFLINE_PAYMENT, data })
            .then((success) => {
                if (success?.status) {
                    setOpen(false)
                    showToast({
                        text: "Payment Status Updated",
                    });
                    updateData?.()
                    clear()
                } else {
                    showToast({
                        text: success?.message,
                        type: toastType.ERROR,
                    });
                }
            })
            .catch((error) => {
                showToast({
                    text: errorToString(error?.response),
                    type: toastType.ERROR,
                });
            }).finally(() => showGlobalLoader(false))
    }

    const clear = () => {
        setPaymentData({
            transactionId: ""
        })
        document.getElementById("payment-file").value = ''
        setFile(null)
        setImage('')
    }

    return <>
        <button
            onClick={() => setOpen(true)}
            className="bg-current text-center text-white font-xsss fw-600 rounded-3 border-0 btn-sm"
        >
            Update
        </button>
        <Modal
            style={{ minWidth: 500 }}
            show={open}
            close={(val) => { setOpen(val); clear() }}
            customClass="add-division-modal"
        >
            <div>
                <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-4 rounded-md">
                    <div class="card-body d-flex align-items-center p-0">
                        <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">Update Payment Status</h2>
                    </div>
                </div>
                <form onSubmit={updatePaymentStatus}>
                    <input
                        value={event?.id}
                        required
                        type="hidden"
                        readOnly
                        disabled
                        className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                    />
                    <input
                        value={event?.users?.id}
                        required
                        type="hidden"
                        readOnly
                        disabled
                        className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                    />
                    <div className="form-group mb-2">
                        <label className="mont-font fw-600 font-xsss">
                            Transaction Id
                        </label>
                        <input
                            value={paymentData?.transactionId}
                            // required
                            onChange={(event) =>
                                setPaymentData({
                                    ...paymentData,
                                    transactionId: event.target.value,
                                })
                            }
                            type="text"
                            className="style2-input ps-3 form-control text-grey-900 font-xsss fw-600"
                        />
                    </div>
                    <div className="form-group mb-2">
                        <label className="mont-font fw-600 font-xsss">
                            Payment Type
                        </label>
                        <select
                            value={paymentData?.paymentType}
                            required
                            onChange={(event) => setPaymentData({
                                ...paymentData,
                                paymentType: event.target.value,
                            })}
                            className="style2-select ps-3 form-control text-grey-900 font-xsss fw-600">
                            <option value="">-- Select --</option>
                            <option value="card">Card</option>
                            <option value="cash">Cash</option>
                            <option value="upi">UPI</option>
                        </select>
                    </div>

                    <div className="form-group mb-2 position-relative">
                        <label className="mont-font fw-600 font-xsss mt-2">
                            Upload screen shot
                        </label>
                        <input
                            // required
                            onChange={onChange}
                            type="file"
                            name="file"
                            id="payment-file"
                            className="input-file1"
                            accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                        />
                        {/* <label htmlFor="file" className="avatar ms-auto me-auto mb-0 mt-2 w100 position-relative">
                            <img
                                src={image || "https://via.placeholder.com/100x100.png"}
                                alt="image"
                                className="shadow-sm"
                            />
                            <i class="fa-regular fa-pen-to-square edit-profile-image"></i>
                        </label> */}
                    </div>
                    <div className="col-lg-12 mt-5">
                        <button
                            type="submit"
                            className="bg-current text-center text-white font-xsss fw-600 p-2 w175 rounded-3 d-inline-block border-0 btn-md"
                        >
                            Mark as paid
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    </>
}

